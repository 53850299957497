import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class RouteGuardService implements CanActivate {
  constructor(private router: Router) {}

  rol: any;

  RouteGuardService(role) {
    this.rol = role;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (localStorage.getItem("role") != null) {
      return this.authCheck(state);
    }
    return false;
  }

  authCheck(state) {
    if (localStorage.getItem("role") == "ADMIN") {
      if (state.url.includes("admin")) {
        return true;
      }
    } else if (localStorage.getItem("role") == "CRM" || localStorage.getItem("role") == "SALES") {
      if (state.url.includes("crm")) {
        return true;
      }
    } else if (localStorage.getItem("role") == "LEAD_HEAD") {
      if (state.url.includes("lead-head")) {
        return true;
      }
    } else if (localStorage.getItem("role") == "HR") {
      if (state.url.includes("hr")) {
        return true;
      }
    } else {
      localStorage.clear();
      this.router.navigate([""]);
    }
    this.router.navigate([""]);
    return false;
  }

  // authenticateUser(role){
  //   if(role == "ADMIN"){
  //     this.router.navigate(['admin']);
  //     return true;
  //   } else if(role == "HR"){
  //     this.router.navigate(['hr']);
  //     return true;
  //   } else if(role == "CRM"){
  //     this.router.navigate(['crm'])
  //     return true;
  //   } else if(role == "SALES"){
  //     this.router.navigate(['sales']);
  //     return true;
  //   } else if(role == "LEAD_HEAD"){
  //     this.router.navigate(['lead-head'])
  //     return true;
  //   }
  //   return false;
  // }

  authenticateUser(role) {
    if (role == "ADMIN") {
      return true;
    }
    if (role == "HR") {
      return true;
    }
    if (role == "CRM" || role == "SALES") {
      return true;
    }
    // else if (role == "LEAD_HEAD") {
    //   this.router.navigate(["leadHead"]);
    //   return true;
    // }
    // else if (role == "EMPLOYEE") {
    //   alert("Login not allowed for this role");
    //   return false;
    // }
    else {
      alert(
        "Login not allowed for this role \n Login with application, download app from play store"
      );
      localStorage.clear();
      this.router.navigate([""]);
    }
    return false;
  }
}
