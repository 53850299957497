<div class="content-wrapper" style="background-color: #fff !important;">
  <section class="Leads_manage leads_redesign">

    <div class="bord">
      <div class="col-xs-12 col-sm-3 col-lg-12 "style="margin-bottom: 40px;">
        <div class="form-group">
          <div class="date_filter_tab toggle__btn">
            <p class="" for="usr" value="assigned">Filter :</p>
            <button class="  fib_system_btn">
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </button>
          </div>
          <div class="collapse" id="collapsefilters">
            <form #filterForm="ngForm" (submit)="onsubmitFilter(filterForm)">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-xs-12 col-sm-3 col-lg-12 ">
                    <div class="form-group">
                      <div class="date_filter_tab date_filter_btn">
                        <p class="" for="usr" value="assigned">Date Filter :</p>
                        <button class="fib_system_btn" type="button" data-toggle="collapse"
                          data-target="#collapseassigned" aria-expanded="false" aria-controls="collapseExample">
                          <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </button>
                      </div>

                      <div class="form-group collapse label-group " id="collapseassigned">
                        <div class="row">
                          <div class="col-xs-12 col-sm-3 col-lg-6">
                            <div class="form-group">
                              <label class="label-text" for="usr" value="receivedon">Recieved Date:</label>

                              <div>
                                <label for="usr">From:</label>
                                <input ngModel name="rdFrom" #rdFrom="ngModel" type="date" class="form-control"
                                  id="rFrom" />
                                <label for="usr">To:</label>
                                <input ngModel name="rdTo" #rdTo="ngModel" type="date" class="form-control" id="rTo" />
                              </div>
                            </div>
                          </div>

                          <div class="col-xs-12 col-sm-3 col-lg-6">
                            <div class="form-group">
                              <label class="label-text" for="usr" value="assigned">Assigned Date:</label>

                              <div>
                                <label for="usr">From:</label>
                                <input ngModel name="adFrom" #adFrom="ngModel" type="date" class="form-control"
                                  id="aFrom" />
                                <label for="usr">To:</label>
                                <input ngModel name="adTo" #adTo="ngModel" type="date" class="form-control" id="aTo" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-2">
                    <div class="form-group">
                      <label for="usr">Leads Status</label>
                      <ng-multiselect-dropdown ngModel name="lStatus" #lStatus="ngModel" class="im_multi"
                        [placeholder]="'Leads Status'" [data]="leadsStatus" [settings]="dropdownSettings">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-2">
                    <div class="form-group">
                      <label for="usr">Leads Assigned To</label>
                      <ng-multiselect-dropdown ngModel name="lAssignedTo" #lAssignedTo="ngModel" class="im_multi"
                        [placeholder]="'Leads Assigned To'" [data]="userList" [settings]="dropdownSettings">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-2">
                    <div class="form-group">
                      <label for="sel1">Source:</label>
                      <ng-multiselect-dropdown ngModel name="lSource" #lSource="ngModel" class="im_multi"
                        [placeholder]="'Source Name'" [data]="sourceNameList" [settings]="dropdownSettings">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-2">
                    <div class="form-group">
                      <label for="usr">Project Name:</label>
                      <ng-multiselect-dropdown ngModel name="lProject" #lProject="ngModel" class="im_multi"
                        [placeholder]="'Projects Name'" [data]="projectNameList" [settings]="dropdownSettings">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-2">
                    <div class="form-group">
                      <label for="sel1">Total Item founds :</label>
                      <input class="form-control" type="text" value=" {{itemCount}}" disabled />
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-lg-2 d-flex ">
                    <div class="form-group ">
                      <label> &nbsp;</label>
                      <button type="submit" class="fib_system_btn  mr-3">
                        <i class="fa fa-filter" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- partial:index.partial.html -->
  <section class="Leads_manage leads_redesign">
    <div class="col-xs-12">
      <!-- table-responsive Add class in below for responsive table -->
      <div class=" table-fixed leads_table_redesign table-fixed table-responsive">
        <table summary="" class="table table-bordered table-hover table-striped text-center borderStyle" style="background-color: #ecf7fc;">
          <thead class="borderStyle">
            <tr>
              <th></th>
              <th>SN</th>
              <th>#</th>
              <th>Leads Id</th>
              <th style="background-color: #ffd75f21">Client Name</th>
              <th>Email</th>
              <th>Source</th>
              <th style="background-color: #0ce2e21f">Project Name</th>
              <th>Query Info.</th>
              <th>Status</th>
              <th>Query Received</th>
              <th>Query Stored</th>
              <th>Assigned Project</th>
            </tr>
          </thead>
          <tbody class="serialNumber">
            <tr class="content" *ngFor="let leads of leadsArray | paginate: config">
              <th></th>
              <td></td>
              <td>
                <button class="btn btn-primary" (click)="deleteLeads(leads.id)">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
              <td>{{ leads.id }}</td>
              <td style="background-color: #ffd75f21">{{ leads.name }}</td>
              <td>{{ leads.email }}</td>
              <td>{{ leads.source }}</td>
              <td style="background-color: #0ce2e21f">
                {{ leads.projectName }}
              </td>
              <td>{{ leads.queryInfo }}</td>
              <td>{{ leads.status }}</td>
              <td>{{ leads.receivedOn | date: "dd-MM-yyyy hh:mm a" }}</td>
              <td>{{ leads.storingDate | date: "dd-MM-yyyy hh:mm a" }}</td>
              <td *ngIf="leads.user === null">Not Assigned</td>
              <td *ngIf="leads.user != null">
                {{ leads.user.firstName + " " + leads.user.lastName }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--end of .table-responsive-->
      <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
    </div>

  </section>
  <!-- partial -->
</div>