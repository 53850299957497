import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxPaginateModule } from "ngx-paginate";
import {
  NgxUiLoaderConfig,
  NgxUiLoaderHttpModule,
  NgxUiLoaderModule,
  PB_DIRECTION,
  POSITION,
  SPINNER,
} from "ngx-ui-loader";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { AdministrationModule } from "./administration/administration.module";
import { AngularFireModule } from "@angular/fire";
import { AngularFireDatabaseModule } from "angularfire2/database";
import { AgmCoreModule } from "@agm/core";
import { environment } from "../environments/environment";
import { UiSwitchModule } from "ngx-toggle-switch";
import { HRModule } from "./hr/hr.module";
import { LeadHeadModule } from "./lead-head/lead-head.module";
import { CRMModule } from "./crm/crm.module";
import { BrowserModule } from "@angular/platform-browser";
import * as $ from "jquery";
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { ProfileComponent } from './crm/profile/profile.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: "red",
  fgsPosition: POSITION.centerCenter,
  fgsSize: 40,
  fgsType: SPINNER.foldingCube, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
};

@NgModule({
  declarations: [AppComponent, LoginComponent, PasswordResetComponent, ProfileComponent],
  imports: [
    BrowserModule,
    AdministrationModule,
    HRModule,
    LeadHeadModule,
    CRMModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    UiSwitchModule,
    NgxPaginateModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgMultiSelectDropDownModule.forRoot(),
    NgxUiLoaderHttpModule.forRoot({showForeground:true}),

    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCX_2By8eOjZ-NlHfPfKnid4BmZJaoFDyY",
      // libraries: ["places", "geometry"]
      /* apiKey is required, unless you are a 
      premium customer, in which case you can 
      use clientId 
      */
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
