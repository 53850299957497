<div class="content-wrapper" style="min-height: 906px;">
  <section class="Leads_manage">
    <div class="container-fluid">
      <div class="row">
        <div class="filter-leads">
          <form (submit)=userAttendancde($event)>
            <div class="col-xs-12 col-sm-12 col-lg-2
            " *ngIf='ROLE'>
              <div class="form-group">
                <label for="sel1">Employee</label>
                <!-- <select class="form-control" (change)=onchangeAgent($event.target.value)> -->
                <select class="form-control" id="agent">
                  <option value="">Select Employee</option>
                  <option *ngFor="let user of users" value="{{user.id}}">{{user.firstName+" "+user.lastName}}</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-lg-2">
              <div class="form-group">
                <label for="usr">Date From</label>
                <input type="date" class="form-control" id="datefrom">
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-lg-2">
              <div class="form-group">
                <label for="usr">Date To:</label>
                <input type="date" class="form-control" id="dateto">
              </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-lg-2">
              <div class="button_ss">
                <button type="submit" class="btn btn-primary btn-block">Attendance</button>
              </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-lg-2" *ngIf='ROLE'>
              <div class="form-group">
                <label for="sel1">&nbsp;</label>
                <button class="btn btn-primary btn-block" (click)="userLiveLocation()">Current Location</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-lg-12">
          <div class="in_time_location">
            <div class="table-responsive table-fixed table-bg">
              <table class="table table-bordered table-hover table-striped text-center">
                <thead>
                  <tr>
                    <th rowspan="2" style="width: 2%;">Sn.</th>
                    <th rowspan="2" style="width: 5%;">Date</th>
                    <th colspan="2" *ngIf='ROLE'>In Time</th>
                    <th colspan="2" *ngIf='ROLE'>Out Time</th>
                    <th colspan="1" *ngIf='!ROLE'>In Time</th>
                    <th colspan="1" *ngIf='!ROLE'>Out Time</th>
                  </tr>
                  <tr *ngIf='ROLE'>
                    <th style="width: 5%;">In Time</th>
                    <th>Loaction</th>
                    <th style="width: 5%;">Out Time</th>
                    <th>Loaction</th>
                  </tr>
                </thead>
                <tbody class="serialNumber">
                  <tr *ngFor="let user of userAttendanceList">
                    <td></td>
                    <td>{{user.date}}</td>
                    <td>{{user.inTime}}</td>
                    <td style="width: 250px; height: 200px;" *ngIf='ROLE'>
                      <agm-map style="width: 100%; height: 100%;" [latitude]="user.inLat" [longitude]="user.inLong"
                        [zoom]="15" [mapTypeControl]="true">
                        <agm-marker [latitude]="user.inLat" [longitude]="user.inLong"></agm-marker>
                      </agm-map>
                    </td>
                    <td>{{user.outTime}}</td>
                    <td style="width: 250px; height: 200px;" *ngIf='ROLE'>
                      <agm-map style="width: 100%; height: 100%;" [latitude]="user.outLat" [longitude]="user.outLong"
                        [zoom]="15" [mapTypeControl]="true">
                        <agm-marker [latitude]="user.outLat" [longitude]="user.outLong"></agm-marker>
                      </agm-map>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
  <!-- partial -->
</div>
