<div class="addleads">
  <button type="button" class="fib_system_btn mr-3" data-toggle="modal" data-target="#myModal">
    <i class="fa fa-plus" aria-hidden="true"></i>
  </button>
  <!-- Modal -->
  <div class="modal fade" id="myModal" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">×</button>
          <h4 class="modal-title">Add Leads Form</h4>
        </div>
        <div class="modal-body">
          <form #addLead="ngForm" (ngSubmit)="saveLeads(addLead)">
            <div class="form-group">
              <label for="cName">Client Name</label>
              <input type="text" class="form-control" required ngModel name="cName" id="cName"
                placeholder="Enter Client Name" />
            </div>
            <div class="form-group">
              <label for="query">Query</label>
              <input type="text" class="form-control" ngModel name="query" id="query"
                placeholder="Enter Query" />
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" class="form-control" ngModel name="email" id="email"
                placeholder="Enter Email" />
            </div>
            <div class="form-group">
              <label for="mobile">Phone</label>
              <input type="text" class="form-control" required ngModel name="mobile" id="mobile"
                placeholder="Enter Phone" />
            </div>
            <div class="form-group">
              <label for="projectName">Project</label>
              <ng-multiselect-dropdown [placeholder]="'Projects Name'" [data]="projectNameList"
                [settings]="dropdownSettings" (onSelect)="onProjectSelect($event)"
                (onDeSelect)="onProjectDeSelect($event)" (onSelectAll)="onSelectAllProject($event)"
                (onDeSelectAll)="onSelectAllProject($event)">
              </ng-multiselect-dropdown>
            </div>
            <div class="form-group">
              <label for="source">Source</label>
              <ng-multiselect-dropdown [placeholder]="'Source Name'" [data]="sourceNameList"
                [settings]="dropdownSettings" (onSelect)="onSourceSelect($event)"
                (onDeSelect)="onSourceDeSelect($event)" (onSelectAll)="onSelectAllSource($event)"
                (onDeSelectAll)="onDeSelectAllSource($event)">
              </ng-multiselect-dropdown>
            </div>
            <div class="box-footer">
              <button type="submit" [disabled]="!addLead.valid" class="btn btn-primary">Add Lead</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>