import { Component, OnInit, NgModule, Pipe } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterTestingModule } from '@angular/router/testing';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Users } from 'src/app/model/users.model';
import { AuthService } from 'src/app/services/auth.service';

declare function closeLeadsAssignModel(): any;

@Component({
  selector: 'app-lead-report',
  templateUrl: './lead-report.component.html',
  styleUrls: ['./lead-report.component.css']
})

@NgModule({
  imports: [
    HttpClientModule,
    RouterTestingModule,
    HttpClient
  ],
  providers: [
    DatePipe
  ]
})

@Pipe({ name: 'safeResourceUrl' })

export class LeadReportComponent implements OnInit {

  leadsReport: any[];
  exportLeadsReport: any[];
  users: Users[];
  feedbackList: any[];
  selectedId: Array<any> = [];

  onchangeStatusString: any;
  onchangeCallStatusString: any;
  onchangeAgentString: any;
  onchangeSourceString: any;
  onchangeTransfered: any;
  onchangeVisitStatus: any;

  selectedIds: Array<any> = [];

  rFromString: any;
  rToString: any;

  aFromString: any;
  aToString: any;

  lmFromString: any;
  lmToString: any;

  csFromString: any;
  csToString: any;

  inputProjectNameString: any;
  activeUsers: any[];
  sourceString: any[];
  projectNameString: any[];
  private pageSize: number = 20;
  public config = {
    itemsPerPage: 20,
    currentPage: 0,
    totalItems: 0
  };

  ROLE: boolean;
  MOBILE: boolean;
  mobile: string = localStorage.getItem('mobile');
  userRole: string;
  recordings: any[] = null;

  fileUrl: any;

  leadsStatus: any[] = [
    { 'id': 1, 'name': 'Open' },
    { 'id': 2, 'name': 'Decline' },
    { 'id': 3, 'name': 'Close' },
  ];

  openCallStatus: any[] = [
    { 'id': 1, 'name': 'Followup' },
    { 'id': 2, 'name': 'Callback' },
    { 'id': 3, 'name': 'Not Picked' },
    { 'id': 4, 'name': 'Visit Planned' },
    { 'id': 5, 'name': 'Meeting Planned' },
    { 'id': 6, 'name': 'Virtual Meeting Planned' },
    { 'id': 7, 'name': 'Visit Done' },
    { 'id': 8, 'name': 'Meeting Done' },
    { 'id': 9, 'name': 'Virtual Meeting Done' },
  ];

  declineCallStatus: any[] = [
    { 'id': 1, 'name': 'Already Purchased' },
    { 'id': 2, 'name': 'Budget Issue' },
    { 'id': 3, 'name': 'Location Issue' },
    { 'id': 4, 'name': 'Loan Issue' },
    { 'id': 5, 'name': 'Followup Done, Not Responding' },
    { 'id': 6, 'name': 'Plan Postponed' },
  ];

  private page: number = 1;
  private filterStr: any;
  private json_obj = {
    'rFromDate': null,
    'rToDate': null,
    'aFromDate': null,
    'aToDate': null,
    'lmFromDate': null,
    'lmToDate': null,
    'csFromDate': null,
    'csToDate': null,
    'statusList': null,
    'callStatusList': null,
    'assignedByIds': null,
    'assignedToIds': null,
    'projectsString': null,
    'sourceString': null,
    'leadsAssignedFrequency': null,
  };

  leadsAssignedFrequencyString: any;
  typeOfDateString: any;

  projectNameList: Array<any> = [];
  sourceNameList: Array<any> = [];
  userList: Array<any> = [];
  statusList: Array<any> = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};
  selectedProjects: Array<any> = [];
  selectedSource: Array<any> = [];
  selectedEmp: Array<any> = [];
  selectedAll: any;
  lable: any;
  inp: any;
  userId: any;
  isValid: boolean = false;
  callStatus: Array<any> = [];
  openString: any;
  declineString: any;
  closeString: any;
  selectedLeadStatus: Array<any> = [];
  selectedCallStatus: Array<any> = [];

  constructor(private auth: AuthService, private router: Router, private sanitizer: DomSanitizer) { }

  public transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit() {

    this.callStatus = this.openCallStatus.concat(this.declineCallStatus);

    this.inp = document.getElementById('parent');
    this.lable = document.getElementById('lable');
    this.isValid = localStorage.getItem('isValid') == 'YES';

    this.dropdownSettings = {
      singleSelection: false,
      allowSearchFilter: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
    };

    this.userRole = localStorage.getItem("role");
    if (this.userRole === 'ADMIN') {
      this.ROLE = true;
    } else {
      this.ROLE = false;
    }

    if (this.mobile === "8076042671") {
      this.MOBILE = true;
    } else {
      this.MOBILE = false;
    }

    this.getProjects();

    this.getAgents();

    this.getSources();

    this.auth.startLoader();

    this.getFilteredLeadsReportByPage(this.json_obj);

    this.auth.getSalesAndActiveUsers().subscribe(
      data => {
        this.activeUsers = data;
      }
    )
  }

  getRecordingOfLeads(leadsId, userId) {
    this.recordings = new Array();
    this.auth.callRecordOfLeads(leadsId, userId).subscribe(
      data => {
        this.recordings = data;
      }
    )
  }

  onCheck(leadId, event) {
    var arrObj = {};
    arrObj["leadsId"] = leadId;
    if (event.target.checked) {
      this.selectedIds.push(arrObj);
    } else {
      this.inp.checked = false;
      this.lable.innerHTML = "Select All";
      let index = this.selectedIds.findIndex(x => x['leadsId'] === leadId)
      this.selectedIds.splice(index, 1);
    }
  }

  selectAll(isChecked: boolean) {
    var input = document.getElementsByTagName('input');
    if (isChecked) {
      this.selectedIds = [];
      for (var i = 0; i < input.length; i++) {
        if (input[i].type === 'checkbox' && input[i].id === 'child_checkbox' && input[i].checked === false) {
          input[i].checked = true;
        }
      }
      for (var i = 0; i < this.leadsReport.length; i++) {
        var obj = {};
        obj['leadsId'] = this.leadsReport[i].leads.id;
        this.selectedIds.push(obj);
      }
      this.lable.innerHTML = 'Selected All'
    }
    if (!isChecked) {
      this.selectedIds = [];
      for (var i = 0; i < input.length; i++) {
        var obj = {};
        if (input[i].type === 'checkbox' && input[i].id === 'child_checkbox' && input[i].checked === true) {
          input[i].checked = false;
        }
      }
      this.lable.innerHTML = 'Select All'

    }
  }

  onChangeUserDropBoxOfMultipleAssign(user_id) {
    this.userId = user_id;
  }

  assignMultipleLead() {
    this.auth.startLoader();
    this.auth.assignLeads(this.userId, this.selectedIds).subscribe(
      data => {
        this.auth.stopLoader();
        this.inp.checked = false;
        this.selectAll(false);
        alert(data['message']);
        closeLeadsAssignModel();
      },
      error => {
        this.auth.stopLoader();
        alert(error);
      }
    );
  }

  onChangeSize(event) {
    this.pageSize = event;
  }

  getLeadsReportByPage() {
    this.filterStr = "normal";
    this.auth.getLeadsReportByPageNumber(this.page).subscribe(
      data => {
        this.auth.stopLoader();
        this.leadsReport = data['content'];
        this.config.totalItems = data['totalElements'];
      },

      (error) => {
        this.auth.stopLoader();
      }
    );
  }

  getFilteredLeadsReportByPage(jsonObj) {
    this.filterStr = "filter";
    this.auth.filterReport(jsonObj, this.page, this.pageSize).subscribe(
      data => {
        this.leadsReport = data['content'];
        this.config.totalItems = data['totalElements'];
        this.config.itemsPerPage = this.pageSize
        this.auth.stopLoader();
      },
      (error) => {
        this.auth.stopLoader();
      }
    );
  }

  pageChanged(event) {
    this.auth.startLoader();
    this.page = event;
    this.config.currentPage = event;
    if (this.filterStr === "normal") {
      this.getLeadsReportByPage();
    } else if (this.filterStr === "filter") {
      this.getFilteredLeadsReportByPage(this.json_obj)
    }
  }

  leadFeedback(leadsId, userId) {

    this.auth.startLoader();
    this.auth.getFeedbackOfLeads1(leadsId).subscribe(
      data => {
        this.feedbackList = data;
        this.auth.stopLoader();
      },
      error => {
        this.auth.stopLoader();
      }
    )
    this.getRecordingOfLeads(leadsId, userId);
  }

  activeUser(userList: any[]) {
    for (let user of userList) {
      if (user.deleted) {
        let index = userList.findIndex(x => x.id === user.id)
        userList.splice(index, 1);
      }
    }
    return userList;
  }

  onChange(userId, leadsId) {
    this.auth.startLoader();
    this.selectedId = [];
    var arrObj = {};
    arrObj["leadsId"] = leadsId;
    this.selectedId.push(arrObj);
    if (userId != 0) {
      this.auth.assignLeads(userId, this.selectedId).subscribe(
        data => {
          this.auth.stopLoader();
          alert(data['message'])
        },
        error => {
          this.auth.stopLoader();
        }
      )
    }
  }

  onsubmitFilter(event) {
    this.inp.checked = false;
    this.selectAll(false)
    this.page = 1;
    this.filterFieldsData(event);
    this.getFilteredLeadsReportByPage(this.json_obj)
  }

  private filterFieldsData(event: any) {
    this.auth.startLoader();
    event.preventDefault();
    let target = event.target;
    if (target.querySelector('#rFrom').value != null && target.querySelector('#rFrom').value != '') {
      this.rFromString = target.querySelector('#rFrom').value;
      var datePipe = new DatePipe("en-US");
      this.rFromString = datePipe.transform(this.rFromString, 'dd-MM-yyyy');
    }
    else {
      this.rFromString = null;
    }
    if (target.querySelector('#rTo').value != null && target.querySelector('#rTo').value != '') {
      this.rToString = target.querySelector('#rTo').value;
      var datePipe = new DatePipe("en-US");
      this.rToString = datePipe.transform(this.rToString, 'dd-MM-yyyy');
    }
    else {
      this.rToString = null;
    }

    if (target.querySelector('#aFrom').value != null && target.querySelector('#aFrom').value != '') {
      this.aFromString = target.querySelector('#aFrom').value;
      var datePipe = new DatePipe("en-US");
      this.aFromString = datePipe.transform(this.aFromString, 'dd-MM-yyyy');
    }
    else {
      this.aFromString = null;
    }
    if (target.querySelector('#aTo').value != null && target.querySelector('#aTo').value != '') {
      this.aToString = target.querySelector('#aTo').value;
      var datePipe = new DatePipe("en-US");
      this.aToString = datePipe.transform(this.aToString, 'dd-MM-yyyy');
    }
    else {
      this.aToString = null;
    }

    if (target.querySelector('#lmFrom').value != null && target.querySelector('#lmFrom').value != '') {
      this.lmFromString = target.querySelector('#lmFrom').value;
      var datePipe = new DatePipe("en-US");
      this.lmFromString = datePipe.transform(this.lmFromString, 'dd-MM-yyyy');
    }
    else {
      this.lmFromString = null;
    }
    if (target.querySelector('#lmTo').value != null && target.querySelector('#lmTo').value != '') {
      this.lmToString = target.querySelector('#lmTo').value;
      var datePipe = new DatePipe("en-US");
      this.lmToString = datePipe.transform(this.lmToString, 'dd-MM-yyyy');
    }
    else {
      this.lmToString = null;
    }

    if (target.querySelector('#csFrom').value != null && target.querySelector('#csFrom').value != '') {
      this.csFromString = target.querySelector('#csFrom').value;
      var datePipe = new DatePipe("en-US");
      this.csFromString = datePipe.transform(this.csFromString, 'dd-MM-yyyy');
    }
    else {
      this.csFromString = null;
    }
    if (target.querySelector('#csTo').value != null && target.querySelector('#csTo').value != '') {
      this.csToString = target.querySelector('#csTo').value;
      var datePipe = new DatePipe("en-US");
      this.csToString = datePipe.transform(this.csToString, 'dd-MM-yyyy');
    }
    else {
      this.csToString = null;
    }
    var projectStrList: Array<any> = [];
    var sourceStrList: Array<any> = [];
    var assignedTo: Array<any> = [];
    var assignedBy: Array<any> = [];
    var status: Array<any> = [];
    var callStatusList: Array<any> = [];

    if (this.selectedLeadStatus != null) {
      for (var pp of this.selectedLeadStatus) {
        status.push(pp.name);
      }
    } else {
      status = [];
    }
    if (this.selectedCallStatus != null) {
      for (var pp of this.selectedCallStatus) {
        callStatusList.push(pp["name"]);
      }
    } else {
      callStatusList = [];
    }

    if (this.selectedProjects != null) {
      for (var pp of this.selectedProjects) {
        projectStrList.push(pp.name);
      }
    } else {
      projectStrList = [];
    }
    if (this.selectedSource != null) {
      for (var pp of this.selectedSource) {
        sourceStrList.push(pp["name"]);
      }
    } else {
      sourceStrList = [];
    }
    if (this.selectedAssignedToList != null) {
      for (var pp of this.selectedAssignedToList) {
        assignedTo.push(pp["id"]);
      }
    } else {
      assignedTo = [];
    }

    if (this.selectedAssignedByList != null) {
      for (var pp of this.selectedAssignedByList) {
        assignedBy.push(pp["id"]);
      }
    } else {
      assignedBy = [];
    }
    this.json_obj = {
      'rFromDate': this.rFromString,
      'rToDate': this.rToString,
      'aFromDate': this.aFromString,
      'aToDate': this.aToString,
      'lmFromDate': this.lmFromString,
      'lmToDate': this.lmToString,
      'csFromDate': this.csFromString,
      'csToDate': this.csToString,
      'statusList': status,
      'callStatusList': callStatusList,
      'assignedByIds': assignedBy,
      'assignedToIds': assignedTo,
      'projectsString': projectStrList,
      'sourceString': sourceStrList,
      'leadsAssignedFrequency': this.leadsAssignedFrequencyString,
    };
  }

  exportAsXLSX() {
    this.auth.startLoader();
    this.auth.filterLeadsReportForExcelExport(this.json_obj).subscribe(
      data => {
        this.auth.stopLoader();
        if (this.ROLE) {
          this.exportLeadsReportInExcel(data)
        } else {
          this.exportLeadsReportInExcelOfUser(data)
        }
      },
      error => {
        this.auth.stopLoader();
      }
    )

  }

  private exportLeadsReportInExcel(leadsReport) {
    var exportList = [];
    for (let report of leadsReport) {
      let phone: string = ('******' + report.phone.substr(report.phone.length - 4));
      var json_obj = {
        'Leads Id': report.id,
        'Client Name': report.name,
        'Client Email': report.email,
        'Client Phone': phone,
        'Source': report.source,
        'Project Name': report.projectName,
        'Status': report.status,
        'Call Status': report.callStatus,
        'Remark1': report.remark1,
        'Remark2': report.remark2,
        'Remark3': report.remark3,
        'Recieved Date': report.receivedOn,
        'Assigned Date': report.assignedDate,
        'Open Date': report.openDate,
        'Visit Date': report.visitDate,
        'Meeting Date': report.meetingDate,
        'Callback Date': report.callbackDate,
        'Closer Date': report.closerDate,
        'Virtual Meeting Date': report.virtualMeetingDate,
        'Assigned Agent': report.agentName,
      };
      exportList.push(json_obj);
    }
    this.auth.exportAsExcelFile(exportList, "LeadsReportExcelSheet");
  }

  private exportLeadsReportInExcelOfUser(leadsReport) {

    var exportList = [];
    var agentName: any;
    for (let report of leadsReport) {
      if (report.user != null) {
        if (report.user.firstName != null) {
          agentName = report.user.firstName;
        }
        if (report.user.lastName != null) {
          agentName = agentName + " " + report.user.lastName;
        }
      } else {
        agentName = null;
      }
      let phone: string = report.phone;
      var json_obj = {
        'Leads Id': report.leads.id,
        'Client Name': report.leads.name,
        'Client Email': report.leads.email,
        'Client Phone': '******' + phone.substr(phone.length - 4),
        'Project Name': report.leads.cmpctLabl,
        'Status': report.status,
        'Call Status': report.callStatus,
        'Recieved Date': report.leads.assignedLeadsDate,
        'Visit Date': report.visitDate,
        'Meeting Date': report.meetingDate,
        'Callback Date': report.callbackDate,
        'Assigned Agent': agentName,
        'Remarks': report.message
      };
      exportList.push(json_obj);
    }
    this.auth.exportAsExcelFile(exportList, "LeadsReportExcelSheet");
  }

  // Multiselect features

  //Poject

  onProjectSelect(item: any) {
    var projObj = {};
    projObj['id'] = item.id;
    projObj['name'] = item.name;
    this.selectedProjects.push(projObj);
  }

  onProjectDeSelect(item: any) {
    let index = this.selectedProjects.findIndex(x => x['id'] === item.id)
    this.selectedProjects.splice(index, 1);
  }

  onSelectAllProject(items: any) {
    for (var item of items) {
      var projObj = {};
      projObj['id'] = item.id;
      projObj['name'] = item.name;
      this.selectedProjects.push(projObj);
    }
  }

  onDeSelectAllProject(items: any) {
    this.selectedProjects = [];
  }

  //Source

  onSourceSelect(item: any) {
    var projObj = {};
    projObj['id'] = item.id;
    projObj['name'] = item.name;
    this.selectedSource.push(projObj);
  }

  onSourceDeSelect(item: any) {
    let index = this.selectedProjects.findIndex(x => x['id'] === item.id)
    this.selectedSource.splice(index, 1);
  }

  onSelectAllSource(items: any) {
    for (var item of items) {
      var projObj = {};
      projObj['id'] = item.id;
      projObj['name'] = item.name;
      this.selectedSource.push(projObj);
    }
  }

  onDeSelectAllSource(items: any) {
    this.selectedSource = [];
  }

  //Agents

  onEmpSelect(item: any) {
    var empObj = {};
    empObj['id'] = item.id;
    this.selectedEmp.push(empObj);
  }

  onEmpDeSelect(item: any) {
    let index = this.selectedEmp.findIndex(x => x['id'] === item.id)
    this.selectedEmp.splice(index, 1);
  }

  onSelectAllEmp(items: any) {
    for (var item of items) {
      var empObj = {};
      empObj['id'] = item.id;
      this.selectedEmp.push(empObj);
    }
  }

  onDeSelectAllEmp(items: any) {
    this.selectedEmp = [];
  }

  // Status

  onLeadsStatusSelect(item: any) {
    var projObj = {};
    this.callStatus = [];
    this.openString = null;
    this.declineString = null;
    this.closeString = null;
    projObj['id'] = item.id;
    projObj['name'] = item.name;
    this.selectedLeadStatus.push(projObj);
    for (var status of this.selectedLeadStatus) {
      if (status.name == 'Open') {
        this.openString = 'open';
      } else if (status.name == 'Decline') {
        this.declineString = 'decline';
      } else if (status.name == 'Close') {
        this.callStatus = [];
      }
    }
    if (this.openString != null && this.declineString == null) {
      this.callStatus = this.openCallStatus;
    } else if (this.openString == null && this.declineString != null) {
      this.callStatus = this.declineCallStatus;
    } else if (this.openString != null && this.declineString != null) {
      this.callStatus = this.openCallStatus.concat(this.declineCallStatus)
    }
    console.log(this.selectedLeadStatus);
  }

  onLeadsStatusDeSelect(item: any) {
    this.callStatus = [];
    this.openString = null;
    this.declineString = null;
    this.closeString = null;
    let index = this.selectedLeadStatus.findIndex(x => x['id'] === item.id)
    this.selectedLeadStatus.splice(index, 1);
    for (var status of this.selectedLeadStatus) {
      if (status.name == 'Open') {
        this.openString = 'open';
      } else if (status.name == 'Decline') {
        this.declineString = 'decline';
      } else if (status.name == 'Close') {
        this.closeString = 'close';
      }
    }
    if (this.openString != null && this.declineString == null) {
      this.callStatus = this.openCallStatus;
    } else if (this.openString == null && this.declineString != null) {
      this.callStatus = this.declineCallStatus;
    } else if (this.openString != null && this.declineString != null) {
      this.callStatus = this.openCallStatus.concat(this.declineCallStatus)
    } else if (this.closeString != null) {
      this.callStatus = [];
    } else {
      this.callStatus = this.openCallStatus.concat(this.declineCallStatus);
    }
  }

  onLeadsStatusSelectAll(items: any) {
    this.callStatus = this.openCallStatus.concat(this.declineCallStatus);
    for (var item of items) {
      var projObj = {};
      projObj['id'] = item.id;
      projObj['name'] = item.name;
      this.selectedLeadStatus.push(projObj);
    }
  }

  onLeadsStatusDeSelectAll(items: any) {
    this.callStatus = this.openCallStatus.concat(this.declineCallStatus);
    this.selectedLeadStatus = [];
  }

  // Call status

  onCallStatusSelect(item: any) {
    var projObj = {};
    projObj['id'] = item.id;
    projObj['name'] = item.name;
    this.selectedCallStatus.push(projObj);
  }

  onCallStatusDeSelect(item: any) {
    let index = this.selectedCallStatus.findIndex(x => x['id'] === item.id)
    this.selectedCallStatus.splice(index, 1);
  }

  onCallStatusSelectAll(items: any) {
    for (var item of items) {
      var projObj = {};
      projObj['id'] = item.id;
      projObj['name'] = item.name;
      this.selectedCallStatus.push(projObj);
    }
  }

  onCallStatusDeSelectAll(items: any) {
    this.selectedCallStatus = [];
  }

  selectedCreatedByList: Array<any> = [];

  leadsCreatedBySelect(item: any) {
    var projObj = {};
    projObj['id'] = item.id;
    projObj['name'] = item.name;
    this.selectedCreatedByList.push(projObj);
    console.log(this.selectedCreatedByList);
  }


  leadsCreatedByDeSelect(item: any) {
    let index = this.selectedCreatedByList.findIndex(x => x['id'] === item.id)
    this.selectedCreatedByList.splice(index, 1);
  }

  leadsCreatedBySelectAll(items: any) {
    for (var item of items) {
      var projObj = {};
      projObj['id'] = item.id;
      projObj['name'] = item.name;
      this.selectedCreatedByList.push(projObj);
    }
  }

  leadsCreatedByDeSelectAll(items: any) {
    this.selectedCreatedByList = [];
  }

  // Assigned to Dropdown

  selectedAssignedToList: Array<any> = [];

  leadsAssignedToSelect(item: any) {
    var projObj = {};
    projObj['id'] = item.id;
    projObj['name'] = item.name;
    this.selectedAssignedToList.push(projObj);
    console.log(this.selectedAssignedToList);
  }


  leadsAssignedToDeSelect(item: any) {
    let index = this.selectedAssignedToList.findIndex(x => x['id'] === item.id)
    this.selectedAssignedToList.splice(index, 1);
  }

  leadsAssignedToSelectAll(items: any) {
    for (var item of items) {
      var projObj = {};
      projObj['id'] = item.id;
      projObj['name'] = item.name;
      this.selectedAssignedToList.push(projObj);
    }
  }

  leadsAssignedToDeSelectAll(items: any) {
    this.selectedAssignedToList = [];
  }

  // Assigned By Dropdown

  selectedAssignedByList: Array<any> = [];

  leadsAssignedBySelect(item: any) {
    var projObj = {};
    projObj['id'] = item.id;
    projObj['name'] = item.name;
    this.selectedAssignedByList.push(projObj);
    console.log(this.selectedAssignedByList);
  }


  leadsAssignedByDeSelect(item: any) {
    let index = this.selectedAssignedByList.findIndex(x => x['id'] === item.id)
    this.selectedAssignedByList.splice(index, 1);
  }

  leadsAssignedBySelectAll(items: any) {
    for (var item of items) {
      var projObj = {};
      projObj['id'] = item.id;
      projObj['name'] = item.name;
      this.selectedAssignedByList.push(projObj);
    }
  }

  leadsAssignedByDeSelectAll(items: any) {
    this.selectedAssignedByList = [];
  }


  //Multiselect get data

  getProjects() {
    this.auth.getProjects().subscribe(
      data => {
        var list: Array<any> = [];
        for (let project of data) {
          var obj: any = {};
          obj['id'] = project.id;
          obj['name'] = project.name;
          list.push(obj);
        }
        this.projectNameList = list;
      }
    )
  }

  getSources() {
    this.auth.getSourceString().subscribe(
      data => {
        var list: Array<any> = [];
        var id = 0;
        for (let source of data) {
          id = id + 1;
          var obj: any = {};
          obj['id'] = id;
          obj['name'] = source;
          list.push(obj);
        }
        this.sourceNameList = list;
      }
    )
  }

  getAgents() {
    this.auth.getUsers().subscribe(
      data => {
        var list: Array<any> = []
        for (let user of data) {
          var obj: any = {};
          obj['id'] = user.id;
          obj['name'] = user.firstName + " " + user.lastName;
          list.push(obj);
        }
        this.userList = list;
      }
    )
  }

  // Search leads by name, phone

  search(event) {
    this.auth.stopLoader();
    var text = event.target.querySelector('#search').value;
    if (text === '') {
      alert("Value is required.");
    } else {
      this.auth.searchClientNameOrPhone(text).subscribe(
        data => {
          this.leadsReport = data;
          this.config.totalItems = data.length;
          this.config.itemsPerPage = data.length;
          this.auth.stopLoader();
        },
        (error) => {
          console.log(error);
          this.auth.stopLoader();
        }
      );
    }
  }
}

