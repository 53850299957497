import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { DatePipe } from "@angular/common";
import { AuthService } from "src/app/services/auth.service";

declare function closeLeadAssignModel(): any;
declare function closeCallLeadFeedbackModel(): any;

@Component({
  selector: "app-calling-data",
  templateUrl: "./calling-data.component.html",
  styleUrls: ["./calling-data.component.css"],
})
export class CallingDataComponent implements OnInit {
  str: any = null;
  callLeads: any[];
  users: Array<any> = [];
  userList: any = [];

  selectedIds: Array<any> = [];
  selectedId: Array<any> = [];
  userId: any;

  ROLE: boolean;
  userRole: string;

  itemCount:any;

  dropdownSettings: IDropdownSettings = {};
  page: number = 1;
  private pageSize: number = 20;
  inputDateFromString: any;
  inputDateToString: any;
  inputProjectNameString: any;
  onchangeAgentString: any;

  status: any[] = [
    { id: 1, name: "All leads" },
    { id: 2, name: "Not Called" },
    { id: 3, name: "Accepted" },
    { id: 4, name: "Rejected" },
  ];

  public config = {
    itemsPerPage: 20,
    currentPage: 0,
    totalItems: 0,
  };

  inp: any;

  acceptCallStatus: string[] = new Array(
    "Followup",
    "Meeting Planned",
    "Visit Planned",
    "Not Picked",
    "Callback",
    "Visited"
  );
  // rejectCallStatus: string[] = new Array("Not Interested", "Invalid Number", "Already Purchased");
  leadsStatus: string = "";
  callStatus: string = "";
  statusCheck: boolean = false;
  callStatusArray: string[];

  callStatusBoolean: boolean = false;
  feedbackUser: any = {};

  lead: any = {};

  private json_obj = {
    startDate: null,
    endDate: null,
    userId: null,
    status: null,
  };

  onchangeStatusString: any;
  usersList: any[];

  constructor(private auth: AuthService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.auth.startLoader();

    this.inp = document.getElementById("parent");
   
    this.userRole = localStorage.getItem("role");
    if (this.userRole === "ADMIN") {
      this.ROLE = true;
    } else {
      this.ROLE = false;
    }
    this.dropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "name",
      itemsShowLimit: 5,
      allowSearchFilter: true
    };

    this.getFilteredCallLeadsByPage(this.json_obj);

    this.auth.getSalesAndActiveUsers().subscribe((data) => {
      this.userList = data;
      console.log(this.userList);
    });

    this.getAgents();
  }

  getCallLeads() {
    this.auth.getCallLeads().subscribe(
      (data) => {
        this.auth.stopLoader();
        this.callLeads = data;
      },
      (err) => {
        this.auth.stopLoader();
        alert(err);
      }
    );
  }

  onChangeUserDropBoxOfMultipleAssign(user_id) {
    this.userId = user_id;
  }

  assignMultipleLead() {
    this.auth.startLoader();
    if (this.selectedIds.length > 0) {
      this.auth.assignCallLeads(this.userId, this.selectedIds).subscribe(
        (data) => {
          this.selectAll(false);
          this.auth.stopLoader();
          this.getCallLeads();
          closeLeadAssignModel();
          alert(data["message"]);
        },
        (error) => {
          this.auth.stopLoader();
          this.getCallLeads();
          alert(error);
        }
      );
    } else {
      this.auth.stopLoader();
      alert("Please select leads.");
      closeLeadAssignModel();
    }
  }

  declineCallLeads() {
    this.auth.startLoader();
    if (this.selectedIds.length > 0) {
      this.auth.declineCallLeads(this.selectedIds).subscribe(
        (data) => {
          this.selectedIds = [];
          this.auth.stopLoader();
          this.getCallLeads();
          alert(data["message"]);
        },
        (error) => {
          this.auth.stopLoader();
          this.getCallLeads();
          alert(error);
        }
      );
    } else {
      this.auth.stopLoader();
      alert("Please select leads.");
    }
  }

  assignLead(userId, leadId) {
    let leadsArray = [{ leadsId: leadId }];
    this.auth.startLoader();
    this.auth.assignCallLeads(userId, leadsArray).subscribe(
      (data) => {
        this.auth.stopLoader();
        alert(data["message"]);
      },
      (error) => {
        this.auth.stopLoader();
        alert(error);
      }
    );
  }

  importCallingData() {
    window.open("/admin/importLeads/" + "calling");
  }

  onChangeSize(event) {
    if (event != "") {
      this.pageSize = event;
    } else {
      this.pageSize = 20;
    }
  }

  getFilteredCallLeadsByPage(jsonObj) {
    this.auth
      .filterCallLeadsByPage(jsonObj, this.page, this.pageSize)
      .subscribe(
        (data) => {
          this.callLeads = data["content"];
          this.config.totalItems = data["totalElements"];
          this.config.itemsPerPage = this.pageSize;
          this.itemCount = data["totalElements"]
          this.auth.stopLoader();
        },
        (error) => {
          console.log(error);
          this.auth.stopLoader();
        }
      );
  }

  pageChanged(event) {
    this.auth.startLoader();
    this.page = event;
    this.config.currentPage = event;
    this.getFilteredCallLeadsByPage(this.json_obj);
  }

  onsubmitFilter(event) {
    this.inp.checked = false;
    this.selectAll(false);
    this.page = 1;

    let formFilter = event.value;

    let statusStr: any;
    let agentID: any;

    if (formFilter.lStatus.length > 0) {
      for (var str of formFilter.lStatus) {
        statusStr = str.name;
      }
    } else {
      statusStr = null;
    }
    if (formFilter.lAgent.length > 0) {
      for (var str of formFilter.lAgent) {
        agentID = str.id;
      }
    } else {
      agentID = 0;
    }

    this.json_obj = {
      startDate: this.auth.toTimestamp(formFilter.rdFrom),
      endDate: this.auth.toTimestamp(formFilter.rdTo),
      status: statusStr,
      userId: agentID,
    };

    console.log(this.json_obj);

    this.getFilteredCallLeadsByPage(this.json_obj);
  }

  feedbackStatus(str) {
    this.leadsStatus = str;
    if (str === "accepted") {
      this.saveAndAssigneCallLeadsToLeads(this.lead, this.feedbackUser["id"]);
      this.callStatusArray = this.acceptCallStatus;
      this.callStatusBoolean = false;
    } else if (str === "rejected") {
      this.callStatusBoolean = true;
      this.statusCheck = false;
      // this.callStatusArray = this.rejectCallStatus;
      // enableStatus();
    } else {
      this.callStatusArray = [];
      this.leadsStatus = null;
      this.callStatusBoolean = false;
      this.statusCheck = false;
      alert("Please select status");
    }
  }

  leadStatusUpdate(obj, userObj) {
    this.lead = obj;
    this.feedbackUser = userObj;
    this.leadsStatus = "";
    this.callStatus = "";
    this.statusCheck = false;
    this.callStatusBoolean = false;
  }

  saveAndAssigneCallLeadsToLeads(leads, userId) {
    this.auth.startLoader();
    this.auth.saveAndAssignLeads(leads, userId).subscribe((data) => {
      this.auth.stopLoader();
      this.lead = data;
      this.statusCheck = true;
    });
  }

  feedbackCallStatus(str) {
    this.callStatus = str;
    if (this.callStatus != "") {
      this.callStatusBoolean = true;
    } else {
      this.callStatusBoolean = false;
      this.callStatus = null;
      alert("Please select call status");
    }
  }

  saveRemark(event) {
    this.auth.startLoader();
    var date: string = "";
    var remark: string = "";
    let target = event.target;

    var feedbackObj: any = {};

    if (target.querySelector("#remarkArea").value != null) {
      remark = target.querySelector("#remarkArea").value;
    } else {
      remark = "";
    }

    if (this.leadsStatus === "accepted") {
      feedbackObj["message"] = remark;
      feedbackObj["status"] = "OPEN";
      feedbackObj["callStatus"] = this.callStatus;
      feedbackObj["user"] = this.feedbackUser;

      if (this.leadsStatus == "accepted") {
        if (this.callStatus != "Visited") {
          if (target.querySelector("#chooseDate").value != null) {
            date = target.querySelector("#chooseDate").value;
            var datePipe = new DatePipe("en-US");
            date = datePipe.transform(date, "dd-MM-yyyy hh:mm a");
          } else {
            date = "";
          }

          if (this.callStatus == "Visit Planned") {
            feedbackObj["visitDate"] = date;
          } else if (this.callStatus == "Callback") {
            feedbackObj["callbackDate"] = date;
          } else if (this.callStatus == "Meeting Planned") {
            feedbackObj["meetingDate"] = date;
          } else if (this.callStatus == "Not Picked") {
            feedbackObj["callbackDate"] = date;
          } else if (this.callStatus == "Followup") {
            feedbackObj["followupDate"] = date;
          }
        } else {
          feedbackObj["visitStatus"] = true;
        }
      }

      if (remark.trim().length > 0) {
        this.auth.saveLeadsFeedback(this.lead["id"], feedbackObj).subscribe(
          (data) => {
            closeCallLeadFeedbackModel();
            this.updateCallLeadsAndReport(remark);
            this.auth.stopLoader();
          },
          (err) => {
            this.auth.stopLoader();
            alert("Something wrong try again.");
          }
        );
      } else {
        alert("Remark should not be empty.");
      }
    } else if (this.leadsStatus === "rejected") {
      this.updateCallLeadsAndReport(remark);
    }
  }

  updateCallLeadsAndReport(remark) {
    var callLeadsObj: any = {};
    callLeadsObj["feedback"] = remark;
    callLeadsObj["status"] = this.leadsStatus;
    callLeadsObj["updateDate"] = new Date().getTime();
    callLeadsObj["user"] = this.feedbackUser;
    callLeadsObj["rejected"] = false;
    callLeadsObj["phone"] = this.lead["phone"];
    this.auth.updateCallLeadsAndReport(callLeadsObj).subscribe(
      (data) => {
        this.auth.stopLoader();
        closeCallLeadFeedbackModel();
        alert(data["output"]);
      },
      (err) => {
        this.auth.stopLoader();
        closeCallLeadFeedbackModel();
        alert("Something wrong.");
      }
    );
  }

  onCheck(leadId, event) {
    var arrObj = {};
    arrObj["leadsId"] = leadId;
    if (event.target.checked) {
      this.selectedIds.push(arrObj);
    } else {
      this.inp.checked = false;
      let index = this.selectedIds.findIndex((x) => x["leadsId"] === leadId);
      this.selectedIds.splice(index, 1);
    }
  }

  selectAll(isChecked: boolean) {
    var input = document.getElementsByTagName("input");
    if (isChecked) {
      this.selectedIds = [];
      for (var i = 0; i < input.length; i++) {
        if (
          input[i].type === "checkbox" &&
          input[i].id === "child_checkbox" &&
          input[i].checked === false
        ) {
          input[i].checked = true;
        }
      }
      for (var i = 0; i < this.callLeads.length; i++) {
        var obj = {};
        obj["leadsId"] = this.callLeads[i].id;
        this.selectedIds.push(obj);
      }
      console.log("ids : ", this.selectedIds);
      console.log("idd : ", this.selectedId);
    }
    if (!isChecked) {
      this.selectedIds = [];
      for (var i = 0; i < input.length; i++) {
        var obj = {};
        if (
          input[i].type === "checkbox" &&
          input[i].id === "child_checkbox" &&
          input[i].checked === true
        ) {
          input[i].checked = false;
        }
      }
      console.log("ids : ", this.selectedIds);
      console.log("idd : ", this.selectedId);
    }
  }

  getAgents() {
    this.auth.getUsers().subscribe((data) => {
      var list: Array<any> = [];
      for (let user of data) {
        var obj: any = {};
        obj["id"] = user.id;
        obj["name"] = user.firstName + " " + user.lastName;
        list.push(obj);
      }
      this.usersList = list;
    });
  }
}
