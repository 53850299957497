import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LeadHeadRoutingModule } from './lead-head-routing.module';
import { AdminComponent } from './admin/admin.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { CallingDataComponent } from './calling-data/calling-data.component';
import { CommonComponent } from './common/common.component';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';
import { MeetingDetailsComponent } from './meeting-details/meeting-details.component';
import { OpenleadComponent } from './openlead/openlead.component';
import { LeadReportComponent } from './lead-report/lead-report.component';
import { LeadsComponent } from './leads/leads.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AgmCoreModule } from '@agm/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxPaginateModule } from 'ngx-paginate';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { HeaderComponent } from './shared/header/header.component';
import { AddLeadComponent } from './add-lead/add-lead.component';


@NgModule({
  declarations: [
    AddLeadComponent,
    AdminComponent,
    AttendanceComponent,
    CallingDataComponent,
    CommonComponent,
    EmployeeDetailsComponent,
    MeetingDetailsComponent,
    OpenleadComponent,
    LeadReportComponent,
    LeadsComponent,
    SidebarComponent,
    HeaderComponent
  ],
  imports: [
    CommonModule,
    LeadHeadRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCX_2By8eOjZ-NlHfPfKnid4BmZJaoFDyY',
      // libraries: ["places", "geometry"]
      /* apiKey is required, unless you are a 
      premium customer, in which case you can 
      use clientId 
      */
    }),
    NgxPaginationModule,
    NgxPaginateModule,
    UiSwitchModule,
    NgMultiSelectDropDownModule.forRoot(),
  ]
})
export class LeadHeadModule { }
