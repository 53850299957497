import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Chart } from 'node_modules/chart.js'

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit {

  constructor(private auth : AuthService) { }

  leadsReportCount: number = 0;

  ngOnInit() {

    var mychart = new  Chart("mychart", {
      type: 'line',
      data: {
        labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        datasets: [{
          label: 'Leads',
          data: [12, 19, 3, 5, 2, 3,5],
          backgroundColor : [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],

          borderColor : [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],

          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks :{
            beginAtZero: true
          }
        }]
      }
      }
    });

  }


  getLeadsReport() {
    this.auth.getLeadsReportsCount().subscribe(
      data => {
        this.auth.stopLoader();
        this.leadsReportCount = data['count'];
      },
      error => {
        this.auth.stopLoader();
      }
    );
  }
}
