import { Component, OnInit, Input } from "@angular/core";
import { AngularFireDatabase } from "angularfire2/database";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-user-google-map",
  templateUrl: "./user-google-map.component.html",
  styleUrls: ["./user-google-map.component.css"],
})
export class UserGoogleMapComponent implements OnInit {
  // @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  // map: google.maps.Map;
  lat: any;
  long: any;
  // currentLat: any;
  // currentLong: any;
  dateAndTime: any;
  userId: any;
  str: any;
  user: any;
  loc: any;
  name: any;
  phone: any;
  constructor(
    private db: AngularFireDatabase,
    private auth: AuthService,
    private route: ActivatedRoute
  ) {
    this.userId = auth.getUserId();
  }

  ngOnInit() {
    // this.auth.startLoader();
    this.userId = this.route.snapshot.paramMap.get("id");
    this.str = this.route.snapshot.paramMap.get("str");
    if(this.str === 'attendance'){
      this.loc = 'Attendance Location'
    }else{
      this.loc = 'Current Location'
    }
    this.userById(this.userId);
  }

  userTodayAttendace(userId) {
    console.log('today attendance');
    this.auth.getTodayAttendance(userId).subscribe(
      (data) => {
        this.dateAndTime = this.auth.toString(data.inTime);
        this.lat = data.inLat;
        this.long = data.inLong;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  currentLocation() {
    console.log('current location');
    this.db
      .object("/AttendanceLocationCoordinates/" + this.userId)
      .valueChanges()
      .subscribe((data) => {
        this.dateAndTime = data["dateAndTime"];
        this.lat = data["latitude"];
        this.long = data["longitude"];
      });
  }

  userById(userId) {
    this.auth.findUserById(userId).subscribe(
      (data) => {
        this.auth.stopLoader();
        this.user = data;
        this.name =
          (data.firstName ? data.firstName : "") +
          " " +
          (data.lastName ? data.lastName : "");
        this.phone = data.mobile;

        if(this.str != null && this.str === 'attendance'){
        this.userTodayAttendace(this.userId);
        }else{
          this.currentLocation();
        }
      },
      (err) => {
        console.log(err);
        this.auth.stopLoader();
      }
    );
  }
}
