<div class="content-wrapper" style="background-color: #fff !important;">
  <!-- partial:index.partial.html -->
  <section class="Leads_manage leads_redesign">
    <div class="col-xs-12">
      <div class="table-responsive table-bg">
        <table summary="" class="table table-bordered table-hover leads_table_redesign table-striped text-center">
          <thead>
            <tr>
              <th></th>
              <th>SN</th>
              <th>Leads Id</th>
              <th style="background-color: #ffd75f21">Client Name</th>
              <th style="background-color: #b17fad26">Mobile</th>
              <th>Source</th>
              <th style="background-color: #0ce2e21f">Project Name</th>
              <th>Query Info.</th>
              <th>Query Received</th>
            </tr>
          </thead>
          <tbody class="serialNumber">
            <tr class="content" *ngFor="let leads of leadsArray | paginate: config">
              <td></td>
              <td></td>
              <td>{{ leads.id }}</td>
              <td style="background-color: #ffd75f21">{{ leads.name }}</td>
              <td style="background-color: #b17fad26">{{ leads.phone }}</td>
              <td>{{ leads.source }}</td>
              <td style="background-color: #0ce2e21f">
                {{ leads.cmpctLabl }}
              </td>
              <td>{{ leads.queryInfo }}</td>
              <td>{{ leads.receivedOn }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--end of .table-responsive-->
      <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
    </div>

  </section>
  <!-- partial -->
</div>