<div class="content-wrapper">
  <section class="Leads_manage">
    <button
      class="btn btn-primary toggle__btn"
      type="button"
      data-toggle="collapse"
      data-target="#collapsefilters"
      aria-expanded="false"
      aria-controls="collapseExample"
    >
      &#x25BC;
    </button>
    <div class="container-fluid">
      <div class="row">
        <div class="collapse" id="collapsefilters">
          <div class="filter-leads">
            <form (submit)="userAttendanceFilter($event)">
              <div class="col-xs-12 col-sm-12 col-lg-2">
                <div class="form-group">
                  <label for="sel1">Employee</label>
                  <!-- <select class="form-control" (change)=onchangeAgent($event.target.value)> -->
                  <select
                    class="form-control"
                    id="agent"
                    (change)="agentIdChange($event.target.value)"
                  >
                    <option value="">Select Employee</option>
                    <option *ngFor="let user of users" value="{{ user.id }}">
                      {{ user.firstName + " " + user.lastName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-lg-2">
                <div class="form-group">
                  <label for="usr">Date From</label>
                  <input type="date" class="form-control" id="datefrom" />
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-lg-2">
                <div class="form-group">
                  <label for="usr">Date To:</label>
                  <input type="date" class="form-control" id="dateto" />
                </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-lg-2">
                <div class="button_ss">
                  <button type="submit" class="btn btn-primary btn-block">
                    Attendance
                  </button>
                </div>
              </div>
            </form>
            <div class="col-xs-12 col-sm-12 col-lg-2">
              <div class="form-group">
                <label for="sel1">&nbsp;</label>
                <button
                  class="btn btn-primary btn-block"
                  (click)="userLiveLocation()"
                >
                  Current Location
                </button>
              </div>
            </div>

            <div
              class="col-xs-12 col-sm-12 col-lg-2"
              *ngIf="isClickedOnAttendance"
            >
              <div class="form-group">
                <label for="sel1">&nbsp;</label>
                <button
                  class="btn btn-primary btn-block"
                  (click)="exportAttendance()"
                >
                  Export In Excel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-lg-12">
          <div class="in_time_location">
            <div class="table-responsive table-fixed table-bg">
              <table
                class="table table-bordered table-hover table-striped text-center"
                *ngIf="!ACTIVE"
              >
                <thead>
                  <tr>
                    <th colspan="6">{{ currDate }}</th>
                  </tr>
                  <tr></tr>
                  <tr>
                    <th style="width: 1%">Sn.</th>
                    <th style="width: 15%">Name</th>
                    <th style="width: 10%">In Time</th>
                    <th>Loaction</th>
                    <th style="width: 10%">Out Time</th>
                    <th>Loaction</th>
                  </tr>
                </thead>
                <tbody class="serialNumber">
                  <tr *ngFor="let user of userAttendanceList">
                    <td class="td_center"></td>
                    <td class="td_center">
                      {{ user.user.firstName + " " + user.user.lastName }}
                      <br /><br /><br /><a (click)="liveLocation(user.user.id)"
                        ><img
                          src="./../../assets/dist/img/curr-loc-icon.png"
                          style="width: 40px; height: 40px"
                      /></a>
                    </td>

                    <td class="td_center">{{ user.inTime }}</td>
                    <td style="width: 250px; height: 150px">
                      <agm-map
                        style="width: 100%; height: 100%"
                        [latitude]="user.inLat"
                        [longitude]="user.inLong"
                        [zoom]="15"
                        [mapTypeControl]="true"
                      >
                        <agm-marker
                          [latitude]="user.inLat"
                          [longitude]="user.inLong"
                        >
                        </agm-marker>
                      </agm-map>
                    </td>
                    <td class="td_center">{{ user.outTime }}</td>
                    <td style="width: 250px; height: 150px">
                      <agm-map
                        style="width: 100%; height: 100%"
                        [latitude]="user.outLat"
                        [longitude]="user.outLong"
                        [zoom]="15"
                        [mapTypeControl]="true"
                      >
                        <agm-marker
                          [latitude]="user.outLat"
                          [longitude]="user.outLong"
                        >
                        </agm-marker>
                      </agm-map>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                class="table table-bordered table-hover table-striped text-center"
                *ngIf="ACTIVE"
              >
                <thead>
                  <tr>
                    <th rowspan="2" style="width: 5%">Sn.</th>
                    <th rowspan="2" style="width: 10%">Date</th>
                    <th colspan="2">In Time</th>
                    <th colspan="2">Out Time</th>
                    <th colspan="1" >In Time</th>
                    <th colspan="1">Out Time</th>
                  </tr>
                  <tr>
                    <th style="width: 10%">In Time</th>
                    <th>Loaction</th>
                    <th style="width: 10%">Out Time</th>
                    <th>Loaction</th>
                  </tr>
                </thead>
                <tbody class="serialNumber">
                  <tr *ngFor="let user of userAttendanceList">
                    <td class="td_center"></td>
                    <td class="td_center">{{ user.date }}</td>
                    <td class="td_center">{{ user.inTime }}</td>
                    <td style="height: 150px">
                      <agm-map
                        style="width: 100%; height: 100%"
                        [latitude]="user.inLat"
                        [longitude]="user.inLong"
                        [zoom]="15"
                        [mapTypeControl]="true"
                      >
                        <agm-marker
                          [latitude]="user.inLat"
                          [longitude]="user.inLong"
                        >
                        </agm-marker>
                      </agm-map>
                    </td>
                    <td class="td_center">{{ user.outTime }}</td>
                    <td style="height: 150px">
                      <agm-map
                        style="width: 100%; height: 100%"
                        [latitude]="user.outLat"
                        [longitude]="user.outLong"
                        [zoom]="15"
                        [mapTypeControl]="true"
                      >
                        <agm-marker
                          [latitude]="user.outLat"
                          [longitude]="user.outLong"
                        >
                        </agm-marker>
                      </agm-map>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
