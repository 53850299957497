<div *ngIf="!isLinkValid" class="container" style="padding: 10%;">
    <div class="jumbotron" style="text-align-last: center;">
        <h4 style="color: deeppink;">{{msg}}</h4>
    </div>
</div>

<div *ngIf="isLinkValid" class="container" style="padding: 10%;">
    <div class="jumbotron" style="text-align-last: center;">
        <form name="addLeadForm" (submit)="resetPassword($event)">
            <div class="box-body">
                <div class="row">
                    <div class="col-xs-12 col-lg-12">
                        <div class="form-group">
                            <label for="">New Password</label>
                            <input type="password" class="form-control" value="" id="password"
                                placeholder="Enter Password">
                        </div>
                    </div>
                    <div class="col-xs-12 col-lg-12">
                        <div class="form-group">
                            <label for="">Confirm Password</label>
                            <input type="password" class="form-control" value="" id="confirmPassword"
                                placeholder="Confirm Password">
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-footer">
                <button type="submit" class="btn btn-primary btn-block">Submit</button>
            </div>
        </form>
    </div>
</div>