import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown'
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

declare function closeLeadUpdateModel(): any;
@Component({
  selector: 'app-lead-transfer',
  templateUrl: './lead-transfer.component.html',
  styleUrls: ['./lead-transfer.component.css']
})
export class LeadTransferComponent implements OnInit {
  projectNameList: Array<any> = [];
  userList: Array<any> = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};
  selectedProjects: Array<any> = [];
  selectedEmp: Array<any> = [];
  editClicked: boolean = false
  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {

    this.getAllAssignedProjects()

    this.getProjects();
    
    this.getAgents();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 5,
    };
  }

  onProjectSelect(item: any) {
    var projObj = {};
    projObj['id'] = item.id;
    projObj['name'] = item.name;
    this.selectedProjects.push(projObj);
  }

  onProjectDeSelect(item: any) {
    let index = this.selectedProjects.findIndex(x => x['id'] === item.id)
    this.selectedProjects.splice(index, 1);
  }

  onEmpSelect(item: any) {
    var projObj = {};
    projObj['id'] = item.id;
    this.selectedEmp.push(projObj);
  }

  onEmpDeSelect(item: any) {
    let index = this.selectedEmp.findIndex(x => x['id'] === item.id)
    this.selectedEmp.splice(index, 1);
  }

  projId: any;
  usrId: any;

  getProjectAndUserID(pId: any, uId: any) {
    this.projId = pId;
    this.usrId = uId;
  }

  updateLeadsCount(event: { target: any; }) {

    let target = event.target;
    var count = target.querySelector('#countNo').value;
    var priority = target.querySelector('#priority').value;
    var obj: any = {};
    obj['projectId'] = this.projId;
    obj['userId'] = this.usrId;
    obj['count'] = count;
    obj['priority'] = priority;

    this.auth.saveProjectUserCount(obj).subscribe(
      data => {
        alert(data['output']);
        this.getAllAssignedProjects();
      }
    )
  }

  onSelectAllProject(items: any) {
  }

  onDeSelectAllProject(items: any) {
  }

  onSelectAllEmp(items: any) {
  }

  onDeSelectAllEmp(items: any) {
  }

  selected: Array<any> = [];

  onsubmit() {
    var list: Array<any> = [];
    for (let proj of this.selectedProjects) {
      var users: Array<any> = [];
      var obj: any = {};
      obj['id'] = proj.id;
      obj['name'] = proj.name;
      for (var emp of this.selectedEmp) {
        var ob: any = {};
        ob['userId'] = emp.id;
        ob['userName'] = emp.name;
        users.push(ob);
      }
      obj['users'] = users;
      list.push(obj)
    }
    var projects: any = {};
    projects['projects'] = list;
    this.auth.updateProject(projects).subscribe(
      data => {
        alert(data['output']);
        // window.location.reload();
        this.getAllAssignedProjects();
      },
      err => {
        console.log(err);
      }
    )
  }

  getProjects() {
    this.auth.getProjects().subscribe(
      data => {
        var list: Array<any> = [];
        for (let project of data) {
          var obj: any = {};
          obj['id'] = project.id;
          obj['name'] = project.name;
          list.push(obj);
        }
        this.projectNameList = list;
      }
    )
  }

  getAgents() {
    this.auth.getSalesAndActiveUsers().subscribe(
      data => {
        var list: Array<any> = []
        for (let user of data) {
          if (!user.deleted) {
            var obj: any = {};
            obj['id'] = user.id;
            obj['name'] = user.firstName + " " + user.lastName;
            list.push(obj);
          }
        }
        this.userList = list;
      }
    )
  }

  getAllAssignedProjects() {
    this.auth.getAllAssignedProjects().subscribe(
      data => {
        this.selectedItems = data;
        console.log(this.selectedItems)
      }
    )
  }

  ok(event: any) {
    this.editClicked = false;
  }

  trash(project: any, userId: any) {
    var list: Array<any> = [];
    var users: Array<any> = [];
    var obj: any = {};
    obj['id'] = project.id;
    obj['name'] = project.name;
    for (var emp of project.users) {
      if (emp.userId == userId) {
        var ob: any = {};
        ob['userId'] = emp.userId;
        ob['userName'] = emp.userName;
        users.push(ob);
      }
      this.auth.deleteProjectUserCountEntry(project.id, userId).subscribe(
        data => {
        },
        err => {
        }
      )
    }
    obj['users'] = users;
    list.push(obj)
    var projects: any = {};
    projects['projects'] = list;
    this.auth.deleteProjectUser(projects).subscribe(
      data => {
        alert("Removed user successfully from the project");
        this.getAllAssignedProjects();
      }
    )
  }

  reset(event: any) {
    this.editClicked = false;
  }
  row: any;
  id: any;
  edit(projectId: any, userId: any) {
    this.editClicked = true;
    this.row = userId
    this.id = projectId;
  }

}

