<div class="content-wrapper" style="min-height: 906px">
  <button
    class="btn btn-primary toggle__btn"
    type="button"
    data-toggle="collapse"
    data-target="#collapsefilters"
    aria-expanded="false"
    aria-controls="collapseExample"
  >
    &#x25BC;
  </button>
  <div class="collapse" id="collapsefilters">
    <section class="topmm">
      <div class="container-fluid">
        <div class="row">
          <div class="filter-leads" *ngIf="ROLE">
            <form>
              <!-- <div class="col-xs-12 col-sm-12 col-lg-3">
              <div class="button_ss">
                <button type="submit" class="btn btn-primary btn-block">Add Lead</button>
              </div>
            </div> -->
              <div class="col-xs-12 col-sm-12 col-lg-4">
                <div class="button_ss">
                  <a
                    (click)="importCallingData()"
                    class="btn btn-primary btn-block"
                    >Import</a
                  >
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-lg-4">
                <div class="button_ss">
                  <button
                    type="button"
                    class="btn btn-primary btn-block btn-md"
                    data-toggle="modal"
                    data-target="#leadassigned"
                  >
                    Assign Multiple Leads
                  </button>
                  <!-- Modal -->
                  <div
                    class="modal fade"
                    id="leadassigned"
                    role="dialog"
                    data-keyboard="false"
                    data-backdrop="static"
                  >
                    <div class="modal-dialog">
                      <!-- Modal content-->
                      <div class="modal-content">
                        <div class="modal-header">
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                          >
                            ×
                          </button>
                          <h4 class="modal-title">Select Employee</h4>
                        </div>
                        <div class="modal-body">
                          <form role="form" (submit)="assignMultipleLead()">
                            <div class="box-body">
                              <div class="form-group">
                                <label>Select Employee</label>
                                <select
                                  class="form-control"
                                  (change)="
                                    onChangeUserDropBoxOfMultipleAssign(
                                      $event.target.value
                                    )
                                  "
                                >
                                  <option
                                    *ngFor="let user of userList"
                                    value="{{ user.id }}"
                                  >
                                    {{ user.firstName + " " + user.lastName }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <!-- /.box-body -->
                            <div class="box-footer">
                              <button type="submit" class="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-lg-4">
                <div class="button_ss">
                  <a
                    (click)="declineCallLeads()"
                    class="btn btn-primary btn-block"
                    >Decline</a
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <!-- partial:index.partial.html -->
    <section class="Leads_manage mt-30">
      <div class="container-fluid">
        <div class="row">
          <div class="filter-leads">
            <form (submit)="onsubmitFilter($event)">
              <div class="col-xs-12 col-sm-12 col-lg-2">
                <div class="form-group">
                  <label for="usr">Date From</label>
                  <input type="date" class="form-control" id="datefrom" />
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-lg-2">
                <div class="form-group">
                  <label for="usr">Date To:</label>
                  <input type="date" class="form-control" id="dateto" />
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-lg-2">
                <div class="form-group">
                  <label for="sel1">Agents</label>
                  <!-- <select class="form-control" (change)=onchangeAgent($event.target.value)> -->
                  <select class="form-control" id="agent">
                    <option value="">Select Agent</option>
                    <option *ngFor="let user of userList" value="{{ user.id }}">
                      {{ user.firstName + " " + user.lastName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-lg-2">
                <div class="form-group">
                  <label for="sel1">Leads Status</label>
                  <!-- <select class="form-control" (change)=onchangeStatus($event.target.value)> -->
                  <select class="form-control" id="status">
                    <option value="">All leads</option>
                    <option value="NO">Not Called</option>
                    <option value="accepted">Accepted</option>
                    <option value="rejected">Rejected</option>
                  </select>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-lg-2">
                <div class="button_ss">
                  <button type="submit" class="btn btn-primary btn-block">
                    Filter
                  </button>
                </div>
              </div>
            </form>
            <!-- <div class="col-xs-12 col-sm-12 col-lg-2" *ngIf='ROLE'>
            <div class="form-group">
              <label for="sel1">&nbsp;</label>
              <button class="btn btn-primary btn-block" (click)="exportAsXLSX()">Export</button>
            </div>
          </div> -->

            <div class="col-xs-12 col-sm-12 col-lg-2">
              <div class="form-group">
                <label for="sel1">Page Size.</label>
                <select
                  name="pageSize"
                  class="form-control"
                  (change)="onChangeSize($event.target.value)"
                >
                  <option value="20">Select Page Size</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="col-xs-12">
    <div class="table-responsive table-fixed">
      <table
        summary=""
        class="table table-bordered table-hover table-striped text-center"
      >
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th style="background-color: #31313021">#</th>
            <th>Leads Id</th>
            <th style="background-color: #ffd75f21">Name</th>
            <th>Email</th>
            <th style="background-color: #b17fad26">Mobile No</th>
            <th *ngIf="ROLE">Source</th>
            <th style="background-color: #0ce2e21f">Project Name</th>
            <th>Received Date</th>
            <th *ngIf="ROLE">Assigned Date</th>
            <th>Update Date</th>
            <th>Query</th>
            <th>Status</th>
            <th *ngIf="ROLE">Feedback</th>
            <th *ngIf="!ROLE">Update Feedback</th>
            <th>Assign Project</th>
          </tr>
        </thead>
        <tbody class="serialNumber">
          <tr
            class="content"
            *ngFor="let leads of callLeads | paginate: config"
          >
            <td></td>
            <td>
              <div>
                <input type="checkbox" (change)="onCheck(leads.id, $event)" />
              </div>
            </td>
            <td>{{ leads.id }}</td>
            <td style="background-color: #ffd75f21">{{ leads.name }}</td>
            <td>{{ leads.email }}</td>
            <td style="background-color: #b17fad26">{{ leads.phone }}</td>
            <td *ngIf="ROLE">{{ leads.source }}</td>
            <td style="background-color: #0ce2e21f">{{ leads.cmpctLabl }}</td>
            <td *ngIf="ROLE">{{ leads.receivedOn }}</td>
            <td>{{ leads.assignedDate }}</td>
            <td>{{ leads.updateDate }}</td>
            <td style="width: 10%">{{ leads.queryInfo }}</td>
            <td>{{ leads.status }}</td>
            <td *ngIf="ROLE">{{ leads.feedback }}</td>
            <td *ngIf="!ROLE">
              <button
                class="btn btn-primary"
                value="Feedback"
                title="Feedback"
                data-toggle="modal"
                (click)="leadStatusUpdate(leads, leads.user)"
                data-target="#feedbackModal"
              >
                Feedback
              </button>
            </td>
            <td id="tt" *ngIf="ROLE">
              <select
                name="users"
                (change)="assignLead($event.target.value, leads.id)"
              >
                <option *ngIf="leads.user != null">
                  {{ leads.user.firstName + " " + leads.user.lastName }}
                </option>
                >
                <option *ngIf="leads.user == null" value="0">
                  Select User
                </option>
                <option *ngFor="let user of userList" value="{{ user.id }}">
                  {{ user.firstName + " " + user.lastName }}
                </option>
              </select>
            </td>
            <td *ngIf="!ROLE">
              {{ leads.user.firstName + " " + leads.user.lastName }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--end of .table-responsive-->
    <pagination-controls
      (pageChange)="pageChanged($event)"
    ></pagination-controls>
  </div>
  <!-- partial -->
</div>

<div class="modal fade" id="feedbackModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal_heading text-center">
        <h3 class="text-primary">Update Feedback</h3>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-12">
            <div class="form-group">
              <label for="usr">Status</label>
              <select
                name="users"
                class="form-control"
                (change)="feedbackStatus($event.target.value)"
                style="width: 100%"
              >
                <option value="">Select Status</option>
                <option value="accepted">Accept</option>
                <option value="rejected">Reject</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-12" *ngIf="statusCheck">
            <div class="form-group">
              <label for="usr">Call Status</label>
              <select
                id="myCallSelect"
                name="users"
                class="form-control"
                (change)="feedbackCallStatus($event.target.value)"
                style="width: 100%"
              >
                <option value="">Select Call Status</option>
                <option *ngFor="let str of callStatusArray" value="{{ str }}">
                  {{ str }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="callStatusBoolean">
          <div class="col-xs-12 col-sm-12 col-lg-12">
            <form name="feedbackForm" (submit)="saveRemark($event)">
              <div class="form-group" style="width: 100%">
                <label for="">Remark</label>
                <textarea
                  placeholder="Remark please"
                  class="form-control"
                  id="remarkArea"
                  name="remark"
                ></textarea>
              </div>
              <div class="form-group" style="width: 100%" *ngIf="statusCheck">
                <label for="">Choose Date</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  id="chooseDate"
                  placeholder="choose date"
                  required
                />
              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-primary pull-right">
                  Save Feedback
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
