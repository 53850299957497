import { AuthService } from 'src/app/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule,HttpResponse } from '@angular/common/http';
import { Component, NgModule, OnInit } from '@angular/core';
import { Users } from 'src/app/model/users.model';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { RouterTestingModule } from '@angular/router/testing';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

@NgModule({
  imports: [
    NgxUiLoaderModule,
    HttpClientModule,
    RouterTestingModule
  ],
  providers: [
    DatePipe
  ]
})


export class ProfileComponent implements OnInit {

  user = new Users();
  data : any;

  constructor(private http : HttpClient,private auth : AuthService, private router: Router) { }
  name = localStorage.getItem('firstName') + " " + localStorage.getItem('lastName');
  mobile = localStorage.getItem('mobile');
  role = localStorage.getItem('role');


  ngOnInit(): void {
    this.auth.getProfile(this.mobile).subscribe(res=>{
      this.data=res;
      this.user = this.data;
      this.auth.stopLoader();
    })
  }

}
