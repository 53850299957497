
import { Component, OnInit, NgModule, Pipe } from "@angular/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { RouterTestingModule } from "@angular/router/testing";
import { DatePipe } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { AuthService } from "src/app/services/auth.service";
import { ActivatedRoute } from '@angular/router';
declare function closeLeadsAssignModel(): any;
declare function getRadioValue(): any;
@Component({
  selector: 'app-show-all-leads-responce',
  templateUrl: './show-all-leads-responce.component.html',
  styleUrls: ['./show-all-leads-responce.component.css']
})
@NgModule({
  declarations: [],
  imports: [HttpClientModule, RouterTestingModule, HttpClient],
  providers: [DatePipe],
})
@Pipe({ name: "safeResourceUrl" })
export class ShowAllLeadsResponceComponent implements OnInit {
 
  
  userId:any;
  leadsReport: any[];
  leadsReport1: any[];
  feedbackList: any[];
  selectedId: Array<any> = [];
  selectedIds: Array<any> = [];
  numberOfLeads: number;
  // userName:any[];
  leadsReports:any;
  activeUsers: any[];
 
  projectNameList: Array<any> = [];
  sourceNameList: Array<any> = [];
  userList: Array<any> = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};
  dropdownSettings1: IDropdownSettings = {};
  inp: any;
  isValid: boolean = false;
  callStatus: Array<any> = [];
  openString: any;
  declineString: any;
  closeString: any;
  selectedLeadStatus: Array<any> = [];
  selectedCallStatus: Array<any> = [];
  itemCount: any;
  expanded = false;
  lastcallstatus: Array<any> = [];
  selectedlastcallstatus : Array<any> = [];
  selectedFilter: any;
  filteredLeadsReport: any[];
  lstatus: any;
  
  constructor(
  private auth: AuthService,
  private route: ActivatedRoute,
  private sanitizer: DomSanitizer
) {}

public transform(url: string) {
  return this.sanitizer.bypassSecurityTrustResourceUrl(url);
}

ngOnInit() {
 
  this.userId = this.route.snapshot.queryParamMap.get('id');
  this.auth.opendeclinedata(this.userId).subscribe((data) => {
    this.leadsReport = data;
    const numberOfLeads = this.leadsReport.length;
    this.numberOfLeads = numberOfLeads;
 
  });
  this.auth.performance(this.userId).subscribe((data) => {
    this.leadsReports = data;
  });
  }

  leadFeedback(leadsId, userId) {
    this.auth.startLoader();
    this.auth.getFeedbackOfLeads1(leadsId).subscribe(
      (data) => {
        this.feedbackList = data;
        this.auth.stopLoader();
      },
      (error) => {
        this.auth.stopLoader();
      }
    );
  }
 
  
  filterOpenToDecline() {
    console.log("leadsReport", this.lstatus); // Move the log here
    console.log("userID", this.userId);
    this.auth.totalopendeclinedata(this.lstatus, this.userId).subscribe((data) => 
    {
      this.leadsReport = data;
      const numberOfLeads = this.leadsReport.length;
      this.numberOfLeads = numberOfLeads;
    });
  }
  
  onChange(userId, leadsId) {
    this.auth.startLoader();
    this.selectedId = [];
    var arrObj = {};
    arrObj["leadsId"] = leadsId;
    this.selectedId.push(arrObj);
    if (userId != 0) {
      this.auth.assignLeads(userId, this.selectedId).subscribe(
        (data) => {
          this.auth.stopLoader();
          alert(data["message"]);
        },
        (error) => {
          this.auth.stopLoader();
        }
      );
    }
  }
 

  onCheck(leadId, event) {
    var arrObj = {};
    arrObj["leadsId"] = leadId;
    if (event.target.checked) {
      this.selectedIds.push(arrObj);
    } else {
      this.inp.checked = false;
      let index = this.selectedIds.findIndex((x) => x["leadsId"] === leadId);
      this.selectedIds.splice(index, 1);
    }
  }

  selectAll(isChecked: boolean) {
    var input = document.getElementsByTagName("input");
    if (isChecked) {
      this.selectedIds = [];
      for (var i = 0; i < input.length; i++) {
        if (
          input[i].type === "checkbox" &&
          input[i].id === "child_checkbox" &&
          input[i].checked === false
        ) {
          input[i].checked = true;
        }
      }
      for (var i = 0; i < this.leadsReport.length; i++) {
        var obj = {};
        obj["leadsId"] = this.leadsReport[i].leads.id;
        this.selectedIds.push(obj);
      }
    }
    if (!isChecked) {
      this.selectedIds = [];
      for (var i = 0; i < input.length; i++) {
        var obj = {};
        if (
          input[i].type === "checkbox" &&
          input[i].id === "child_checkbox" &&
          input[i].checked === true
        ) {
          input[i].checked = false;
        }
      }
    }
  }

  onChangeUserDropBoxOfMultipleAssign(user_id) {
    this.userId = user_id;
  }

  assignMultipleLead(event) {
    if (this.selectedIds.length <= 0) {
      alert("Select lead(s) please");
      closeLeadsAssignModel();
      return;
    }
    if (this.userId <= 0) {
      alert("Select sales member");
      return;
    }
    var val = getRadioValue();
    if (val == "fresh") {
      this.auth.startLoader();
      this.auth.assignLeads(this.userId, this.selectedIds).subscribe(
        (data) => {
          this.auth.stopLoader();
          this.inp.checked = false;
          this.selectAll(false);
          alert(data["message"]);
          closeLeadsAssignModel();
        },
        (error) => {
          this.auth.stopLoader();
          alert(error);
        }
      );
    } else {
      this.auth.startLoader();
      this.auth
        .assignLeadsAsCallingData(this.userId, this.selectedIds)
        .subscribe(
          (data) => {
            this.auth.stopLoader();
            this.inp.checked = false;
            this.selectAll(false);
            alert(data["message"]);
            closeLeadsAssignModel();
          },
          (error) => {
            this.auth.stopLoader();
            alert(error);
          }
        );
    }
  }

 


 


}