import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { CallingDataComponent } from './calling-data/calling-data.component';
import { CommonComponent } from './common/common.component';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';
import { LeadReportComponent } from './lead-report/lead-report.component';
import { LeadsComponent } from './leads/leads.component';
import { MeetingDetailsComponent } from './meeting-details/meeting-details.component';
import { OpenleadComponent } from './openlead/openlead.component';

const routes: Routes = [
  {

    path: 'leadHead', component: CommonComponent,
    children: [
      { path: '', component: AdminComponent },
      { path: 'leadReport', component: LeadReportComponent },
      { path: 'attendance', component: AttendanceComponent },
      { path: 'leads', component: LeadsComponent },
      { path: 'openLead', component: OpenleadComponent },
      { path: 'employeeDetail', component: EmployeeDetailsComponent },
      { path: 'callingData', component: CallingDataComponent },
      { path: 'meetingDetails', component: MeetingDetailsComponent },
      { path: 'leadHead', redirectTo: 'leadHead', pathMatch: 'full' }
    ]

  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LeadHeadRoutingModule { }
