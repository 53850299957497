<div class="content-wrapper" style="background-color: #fff !important;">
  <section class="Leads_manage leads_redesign">

    <!--Start filter form section -->

    <div class="bord">
      <div class="col-xs-12 col-sm-3 col-lg-12 ">
        <div class="form-group">
          <div class="date_filter_tab toggle__btn">
            <p class="" for="usr" value="assigned">Filter :</p>
            <button class="fib_system_btn">
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </button>
          </div>
          <div class="collapse" id="collapsefilters">
            <form #filterForm="ngForm" (submit)="onsubmitFilter(filterForm)">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-xs-12 col-sm-3 col-lg-12 ">
                    <div class="form-group">
                      <div class="date_filter_tab date_filter_btn">
                        <p class="" for="usr" value="assigned">Date Filter :</p>
                        <button class="fib_system_btn" type="button" data-toggle="collapse"
                          data-target="#collapseassigned" aria-expanded="false" aria-controls="collapseExample">
                          <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </button>
                      </div>

                      <div class="form-group collapse label-group " id="collapseassigned">
                        <div class="row">
                          <div class="col-xs-12 col-sm-12 col-lg-12 ">
                            <div class="form-group">
                              <label class="label-text" for="usr" value="receivedon">Recieved Date:</label>

                              <div>
                                <label for="usr">From:</label>
                                <input ngModel name="rdFrom" #rdFrom="ngModel" type="date" class="form-control"
                                  id="rFrom" />
                                <label for="usr">To:</label>
                                <input ngModel name="rdTo" #rdTo="ngModel" type="date" class="form-control" id="rTo" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-4">
                    <div class="form-group">
                      <label for="usr">Project Name:</label>
                      <ng-multiselect-dropdown ngModel name="lProject" #lProject="ngModel" class="im_multi"
                        [placeholder]="'Projects Name'" [data]="projectNameList" [settings]="dropdownSettings">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-2 d-flex ">
                    <div class="form-group ">
                      <label> &nbsp;</label>
                      <button data-toggle="tooltip" data-placement="top" title="Filter" type="submit"
                        class="fib_system_btn  mr-3">
                        <i class="fa fa-filter" aria-hidden="true"></i>
                      </button>
                    </div>
                    <!-- <div class="form-group">
                      <label> &nbsp;</label>
                      <app-add-lead></app-add-lead>
                    </div> -->
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!--End filter form section -->

        <div class="col-xs-12">
          <div class="table-responsive leads_table_redesign table-fixed">
            <table summary="" class="table table-bordered table-hover table-striped text-center borderStyle" style="background-color: #ecf7fc;">
              <thead>
                <tr>
                  <th></th>
                  <th *ngIf="ROLE && !MOBILE">#</th>
                  <th>SN</th>
                  <th>Leads Id</th>
                  <th style="background-color: #ffd75f21">Client Name</th>
                  <th *ngIf="isValid">Clien Mobile</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th style="background-color: #0ce2e21f">Project Name</th>
                  <th>Query Info.</th>
                  <th>Leads Received</th>
                  <th *ngIf="!ROLE">Feedback</th>
                  <th>Assigned Project</th>
                </tr>
              </thead>
              <tbody class="serialNumber">
                <tr class="content" *ngFor="let leads of leadsArray">
                  <td></td>
                  <td *ngIf="ROLE && !MOBILE">
                    <div>
                      <input type="checkbox" (change)="onCheck(leads.id, $event)" />
                    </div>
                  </td>
                  <td></td>
                  <td>{{ leads.id }}</td>
                  <td style="background-color: #ffd75f21">{{ leads.name }}</td>
                  <td *ngIf="isValid">{{ leads.phone }}</td>
                  <td>{{ leads.phone }}</td>
                  <td>{{ leads.email }}</td>
                  <td style="background-color: #0ce2e21f">
                    {{ leads.projectName }}
                  </td>
                  <td style="width: 10%">{{ leads.queryInfo }}</td>
                  <td *ngIf="!ROLE">{{ leads.assignedDate > 0 ? (leads.assignedDate | date: "dd-MM-yyyy hh:mm a"):"" }}</td>
                  <td *ngIf="!ROLE">
                    <button class="fib_system_btn_auto_w" value="Feedback" title="Feedback" data-toggle="modal"
                      (click)="leadStatusUpdate(leads, leads.user)" data-target="#feedbackModal">
                      <i class="fa fa-comments-o" aria-hidden="true"></i>
                    </button>
                  </td>
                  <td id="tt">
                    <select name="users" (change)="assignLead($event.target.value, leads.id)">
                      <option *ngIf="leads.user != null">
                        {{ leads.user.firstName + " " + leads.user.lastName }}
                      </option>
                      >
                      <option *ngIf="leads.user == null" value="0">
                        Select User
                      </option>
                      <option *ngFor="let user of activeUsers" value="{{ user.id }}">
                        {{ user.firstName + " " + user.lastName }}
                      </option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--end of .table-responsive-->
        </div>
      
  </section>
  <!-- partial -->
</div>
<!-- Modal -->

<div class="modal fade" id="feedbackModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal_heading text-center">
        <h3 class="text-primary">Update Feedback</h3>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-6">
            <div class="form-group">
              <label for="usr">Status</label>
              <select name="users" class="form-control" (change)="feedbackStatus($event.target.value)"
                style="width: 100%" id="my_select">
                <option value="" selected="selected">Select Satus</option>
                <option value="open">Accept</option>
                <option value="decline">Reject</option>
              </select>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-lg-6">
            <div class="form-group">
              <label for="usr">Call Status</label>
              <select id="mySelect" disabled name="users" class="form-control"
                (change)="feedbackCallStatus($event.target.value)" style="width: 100%">
                <option value="">Select Call Satus</option>
                <option *ngFor="let str of callStatusArray" value="{{ str }}">
                  {{ str }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="callStatusBoolean">
          <div class="col-xs-12 col-sm-12 col-lg-12">
            <form name="feedbackForm" (submit)="saveRemark($event)">
              <div class="form-group" style="width: 100%">
                <label for="">Remark</label>
                <textarea placeholder="Remark please" class="form-control" id="remarkArea" name="remark"></textarea>
              </div>
              <div class="form-group" style="width: 100%" *ngIf="statusCheck">
                <label for="">Choose Date</label>
                <input type="datetime-local" class="form-control" id="chooseDate" placeholder="choose date" required />
              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-primary pull-right">
                  Save Feedback
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>