import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.css']
})
export class PerformanceComponent implements OnInit {
  userId: any;
data:any;
user_id:any;
  leadsReport: any;
  constructor(private route: ActivatedRoute,   private router: Router, private auth: AuthService) { }

  ngOnInit() {
    this.userId = this.route.snapshot.queryParamMap.get('id');
    this.auth.performance(this.userId).subscribe((data) => {
      this.leadsReport = data;
  });

}
handleDeclineLeadsClick(){
  if (this.userId) {
    this.router.navigate(['admin/show-all-leads-responce'], {
      queryParams: { id: this.userId }
      
    });
    alert("You clicked on Decline Leads card!");
  }
  
}
}
