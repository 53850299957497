import { Component, OnInit, NgModule } from '@angular/core';
import { HttpResponse, HttpClientModule, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormArray, FormGroup, FormBuilder, FormControl } from '@angular/forms'
import { from } from 'rxjs';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { RouterTestingModule } from '@angular/router/testing';
import { DatePipe } from '@angular/common';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Leads } from 'src/app/model/leads.model';
import { Users } from 'src/app/model/users.model';
import { AuthService } from 'src/app/services/auth.service';

declare function resetDropdown(): any;
declare function enableStatus(): any;
declare function disableStatus(): any;
declare function closeLeadFeedbackModel(): any;

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.css']
})

@NgModule({
  imports: [
    NgxUiLoaderModule,
    HttpClientModule,
    RouterTestingModule,
    HttpClient
  ],
  providers: [
    DatePipe
  ]
})
export class LeadsComponent implements OnInit {

  active = false;
  leadsArray: Leads[];
  users: Users[];
  activeUsers: Users[];
  selectedIds: Array<any> = [];
  selectedId: Array<any> = [];
  userId: any;
  count = 0;

  onchangeAgentString: any;
  onchangeSourceString: any;

  dropdownSettings: IDropdownSettings = {};
  

  inputDateFromString: any;
  inputDateToString: any;
  inputProjectNameString: any;

  sourceString: any[];
  projectNameString: any[];

  ROLE: boolean;
  userRole: string;

  lead: any = {};

  acceptCallStatus: string[] = new Array("Followup", "Meeting Planned", "Visit Planned", "Virtual Meeting", "Not Picked", "Callback", "Visited", "Virtual Meeting Done");
  rejectCallStatus: string[] = new Array("Not Interested", "Invalid Number", "Already Purchased");
  leadsStatus: string = "";
  callStatus: string = "";
  statusCheck: boolean = false;
  callStatusArray: string[];

  private json_arr = {
    'startDate': null,
    'endDate': null,
    'userId': null,
    'projectName': null,
    'source': null
  };

  callStatusBoolean: boolean = false;
  feedbackUser: any = {};

  MOBILE: boolean;
  mobile: string = localStorage.getItem('mobile');
  isValid: boolean = false;
  projectNameList: any[];
  sourceNameList: any[];
  selectedProjects: any;
  selectedSource: any;

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {

    this.dropdownSettings = {
      singleSelection: false,
      allowSearchFilter: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
    };

    this.getProjects();

    this.getSources();

    this.userRole = localStorage.getItem("role");
    if (this.userRole === 'ADMIN') {
      this.ROLE = true;
    } else {
      this.ROLE = false;
    }

    this.isValid = localStorage.getItem('isValid') == 'YES';

    if (this.mobile === "8076042671") {
      this.MOBILE = true;
    } else {
      this.MOBILE = false;
    }

    this.auth.getUsers().subscribe(
      data => {
        this.users = data;
      }
    )

    this.auth.getSalesAndActiveUsers().subscribe(
      data => {
        this.activeUsers = data;
      }
    )

    this.auth.getLeads().subscribe(
      data => {
        
        this.leadsArray = data;
      },
      error => {
        
        console.log(error);
      }
    );

    this.auth.getSourceString().subscribe(
      data => {
        this.sourceString = data;
      }
    )

    this.auth.getProjectNameString().subscribe(
      data => {
        this.projectNameString = data;
      }
    )

  }

  activeUser(userList: any[]) {
    for (let user of userList) {
      if (user.deleted) {
        let index = userList.findIndex(x => x.id === user.id)
        userList.splice(index, 1);
      }
    }
    return userList;
  }

  leadsBySource(sourceString) {
    
    if (sourceString == "all") {
      this.auth.getLeads().subscribe(
        data => {
          
          this.leadsArray = data;
          this.router.navigate['leads'];
        }
      );
    } else {
      this.auth.getLeadsBySource(sourceString).subscribe(
        data => {
          
          this.leadsArray = data;
          this.router.navigate['leads'];
        }
      );
    }
  }

  onChange(userId, leadsId) {
    
    this.selectedId = [];
    var arrObj = {};
    arrObj["leadsId"] = leadsId;
    this.selectedId.push(arrObj);
    if (userId != 0) {
      this.auth.assignLeads(userId, this.selectedId).subscribe(
        data => {
          
          alert(data['message'])
        }
      )
    }
  }
  onChangeUserDropBoxOfMultipleAssign(user_id) {
    this.userId = user_id;
  }

  onCheck(leadId, event) {
    var arrObj = {};
    arrObj["leadsId"] = leadId;
    if (event.target.checked) {
      this.selectedIds.push(arrObj);
    } else {
      let index = this.selectedIds.findIndex(x => x['leadsId'] === leadId)
      this.selectedIds.splice(index, 1);
    }
  }

  assignMultipleLead() {
    
    this.auth.assignLeads(this.userId, this.selectedIds).subscribe(
      data => {
        
        alert(data['message']);
      },
      error => {
        
        alert(error);
      }
    );
  }

  assignLead(userId, leadId) {
    let leadsArray = [
      { "leadsId": leadId },
    ];
    
    this.auth.assignLeads(userId, leadsArray).subscribe(
      data => {
        
        alert(data['message'])
      },
      error => {
        
        alert(error);
      }
    );
  }
  onsubmitFilter(event) {
    
    event.preventDefault();
    let target = event.target;
    if (target.querySelector('#agent').value != null) {
      this.onchangeAgentString = target.querySelector('#agent').value;
    } else {
      this.onchangeAgentString = null;
    }

    if (this.ROLE) {
      if (target.querySelector('#source').value != null) {
        this.onchangeSourceString = target.querySelector('#source').value;
      } else {
        this.onchangeSourceString = null;
      }
    }

    if (target.querySelector('#datefrom').value != null) {
      this.inputDateFromString = target.querySelector('#datefrom').value;
      var datePipe = new DatePipe("en-US");
      this.inputDateFromString = datePipe.transform(this.inputDateFromString, 'dd-MM-yyyy');
    } else {
      this.inputDateFromString = null;
    }
    if (target.querySelector('#dateto').value != null) {
      this.inputDateToString = target.querySelector('#dateto').value;
      var datePipe = new DatePipe("en-US");
      this.inputDateToString = datePipe.transform(this.inputDateToString, 'dd-MM-yyyy');
    } else {
      this.inputDateToString = null;
    }
    if (target.querySelector('#projectName').value != null) {
      this.inputProjectNameString = target.querySelector('#projectName').value;
    } else {
      this.inputProjectNameString = null;
    }

    this.json_arr = {
      'startDate': this.inputDateFromString,
      'endDate': this.inputDateToString,
      'userId': this.onchangeAgentString,
      'projectName': this.inputProjectNameString,
      'source': this.onchangeSourceString
    };

    this.auth.filterLeads(this.json_arr).subscribe(
      data => {
        
        this.leadsArray = data;
        this.router.navigate['leads'];
      }
    )
  }

  feedbackStatus(str) {
    this.leadsStatus = str;
    if (str === 'open') {
      this.callStatusBoolean = false;
      this.statusCheck = true;
      this.callStatusArray = this.acceptCallStatus;
      enableStatus();
    } else if (str === 'decline') {
      this.callStatusBoolean = false;
      this.statusCheck = false;
      this.callStatusArray = this.rejectCallStatus;
      enableStatus();
    } else {
      this.callStatusArray = [];
      this.leadsStatus = null;
      this.callStatusBoolean = false;
      disableStatus();
      alert('Please select status');
    }
  }

  leadStatusUpdate(obj, userObj) {
    this.lead = obj;
    this.feedbackUser = userObj;
    this.callStatusArray = [];
    this.leadsStatus = null;
    this.callStatus = "";
    this.statusCheck = false;
    this.callStatusBoolean = false;
    resetDropdown();
    disableStatus();
  }

  feedbackCallStatus(str) {
    this.callStatus = str;
    if (this.callStatus != "") {
      if (this.leadsStatus === "open") {
        if (this.callStatus === "Visited" || this.callStatus === "Virtual Meeting Done") {
          this.callStatusBoolean = true;
          this.statusCheck = false;
        } else {
          this.callStatusBoolean = true;
          this.statusCheck = true;
        }
      } else {
        this.callStatusBoolean = true;
        this.statusCheck = false;
      }
    } else {
      this.callStatusBoolean = false;
      this.callStatus = null;
      alert('Please select call status');
    }
  }

  saveRemark(event) {
    
    var date: string = "";
    var remark: string = "";
    let target = event.target;

    var feedbackObj: any = {}

    if (target.querySelector('#remarkArea').value != null) {
      remark = target.querySelector('#remarkArea').value;
    }
    else {
      remark = "";
    }

    feedbackObj['message'] = remark;
    feedbackObj['status'] = this.leadsStatus;
    feedbackObj['callStatus'] = this.callStatus;
    feedbackObj['user'] = this.feedbackUser;

    if (this.leadsStatus == 'open') {

      if (this.callStatus === "Visited" || this.callStatus === "Virtual Meeting Done") {
        if (this.callStatus === "Visited") {
          feedbackObj['visitStatus'] = true;
        } else if (this.callStatus === "Virtual Meeting Done") {
          feedbackObj['virtualMeetingStatus'] = true;
        }
      } else {
        if (target.querySelector('#chooseDate').value != null) {
          date = target.querySelector('#chooseDate').value;
          var datePipe = new DatePipe("en-US");
          date = datePipe.transform(date, 'dd-MM-yyyy hh:mm a');
        }
        else {
          date = "";
        }

        if (this.callStatus == "Visit Planned") {
          feedbackObj['visitDate'] = date;
        } else if (this.callStatus == "Callback") {
          feedbackObj['callbackDate'] = date;
        } else if (this.callStatus == "Meeting Planned") {
          feedbackObj['meetingDate'] = date;
        } else if (this.callStatus == "Not Picked") {
          feedbackObj['callbackDate'] = date;
        } else if (this.callStatus == "Followup") {
          feedbackObj['followupDate'] = date;
        } else if (this.callStatus == "Virtual Meeting") {
          feedbackObj['virtualMeetingDate'] = date;
        }
      }
    }

    if (remark.trim().length > 0) {
      this.auth.saveLeadsFeedback(this.lead['id'], feedbackObj).subscribe(
        data => {
          closeLeadFeedbackModel();
          
          alert(data['output']);
        },
        err => {
          
          console.log(err);
          alert("Something wrong try again.");
        }
      )
    } else {
      alert("Remark should not be empty.");
    }
  }

  //Multi select dropdown option

  onProjectSelect(item: any) {
    var projObj = {};
    projObj['id'] = item.id;
    projObj['name'] = item.name;
    this.selectedProjects.push(projObj);
  }

  onProjectDeSelect(item: any) {
    let index = this.selectedProjects.findIndex(x => x['id'] === item.id)
    this.selectedProjects.splice(index, 1);
  }

  onSelectAllProject(items: any) {
    for (var item of items) {
      var projObj = {};
      projObj['id'] = item.id;
      projObj['name'] = item.name;
      this.selectedProjects.push(projObj);
    }
  }

  onDeSelectAllProject(items: any) {
    this.selectedProjects = [];
  }

  //Source

  onSourceSelect(item: any) {
    var projObj = {};
    projObj['id'] = item.id;
    projObj['name'] = item.name;
    this.selectedSource.push(projObj);
  }

  onSourceDeSelect(item: any) {
    let index = this.selectedProjects.findIndex(x => x['id'] === item.id)
    this.selectedSource.splice(index, 1);
  }

  onSelectAllSource(items: any) {
    for (var item of items) {
      var projObj = {};
      projObj['id'] = item.id;
      projObj['name'] = item.name;
      this.selectedSource.push(projObj);
    }
  }

  onDeSelectAllSource(items: any) {
    this.selectedSource = [];
  }


  //Multi select dropdown option get data

  getProjects() {
    this.auth.getProjects().subscribe(
      data => {
        var list: Array<any> = [];
        for (let project of data) {
          var obj: any = {};
          obj['id'] = project.id;
          obj['name'] = project.name;
          list.push(obj);
        }
        this.projectNameList = list;
      }
    )
  }

  getSources() {
    this.auth.getSourceString().subscribe(
      data => {
        var list: Array<any> = [];
        var id = 0;
        for (let source of data) {
          id = id + 1;
          var obj: any = {};
          obj['id'] = id;
          obj['name'] = source;
          list.push(obj);
        }
        this.sourceNameList = list;
      }
    )
  }

}
