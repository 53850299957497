import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { AdministrationRoutingModule } from './administration-routing.module';
import { AdminComponent } from './admin/admin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './shared/header/header.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { LeadReportComponent } from './lead-report/lead-report.component';
import { NgxPaginationModule } from 'ngx-pagination'
import { NgxPaginateModule } from 'ngx-paginate';
import { NgxUiLoaderConfig, NgxUiLoaderModule, PB_DIRECTION, POSITION, SPINNER } from 'ngx-ui-loader';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CommonComponent } from './common/common.component';
import { OpenleadComponent } from './openlead/openlead.component';
import { CallingDataComponent } from './calling-data/calling-data.component';
import { DeclineLeadComponent } from './decline-lead/decline-lead.component';
import { CloseLeadComponent } from './close-lead/close-lead.component';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';
import { JunkLeadsComponent } from './junk-leads/junk-leads.component';
import { LeadTransferComponent } from './lead-transfer/lead-transfer.component';
import { AddLeadComponent } from './add-lead/add-lead.component';
import { LeadsComponent } from './leads/leads.component';

import { UiSwitchModule } from 'ngx-toggle-switch';
import { BrowserModule } from '@angular/platform-browser';
import { MeetingDetailsComponent } from './meeting-details/meeting-details.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { AgmCoreModule } from '@agm/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { environment } from 'src/environments/environment';
import { EmployeeAttendanceHistoryComponent } from './employee-attendance-history/employee-attendance-history.component';
import { SheetComponent } from './sheet/sheet.component';
import { TotalLeadsComponent } from './total-leads/total-leads.component';
import { UserGoogleMapComponent } from './user-google-map/user-google-map.component';
import { FibSystemComponent } from '../fib-system/fib-system.component';
import { CallLeadsComponent } from './call-leads/call-leads.component';
import { ShowReviewComponent } from './show-review/show-review.component';
import { ReviewPendingComponent } from './review-pending/review-pending.component';
import { PerformanceComponent } from './performance/performance.component';
import { ShowAllLeadsResponceComponent } from './show-all-leads-responce/show-all-leads-responce.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: 'red',
  fgsPosition: POSITION.centerCenter,
  fgsSize: 40,
  fgsType: SPINNER.foldingCube, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
};


@NgModule({
  declarations: [
    AddLeadComponent,
    AdminComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    LeadReportComponent,
    CommonComponent,
    OpenleadComponent,
    CallingDataComponent, 
    DeclineLeadComponent,
    LeadsComponent,
    CloseLeadComponent,
    EmployeeDetailsComponent,
    JunkLeadsComponent,
    MeetingDetailsComponent,
    LeadTransferComponent,
    AttendanceComponent,
    EmployeeAttendanceHistoryComponent,
    SheetComponent,
    TotalLeadsComponent,
    UserGoogleMapComponent,
    FibSystemComponent,
    CallLeadsComponent,
    ShowReviewComponent,
    ReviewPendingComponent,
    PerformanceComponent,
    ShowAllLeadsResponceComponent,
  ],
  imports: [
    CommonModule,
    AdministrationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    UiSwitchModule,
    NgxPaginationModule,
    NgxPaginateModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgMultiSelectDropDownModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCX_2By8eOjZ-NlHfPfKnid4BmZJaoFDyY',
      // libraries: ["places", "geometry"]
      /* apiKey is required, unless you are a 
      premium customer, in which case you can 
      use clientId 
      */
    }),
  ],
  providers: [
    DatePipe
  ]
})
export class AdministrationModule { }
