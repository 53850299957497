<div class="content-wrapper" style="background-color: #fff !important;">
    <section class="Leads_manage leads_redesign">

        <!--Start filter form section -->

        <div class="bord">
            <div class="col-xs-12 col-sm-3 col-lg-12 ">
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="date_filter_tab toggle__btn">
                                <p class="" for="usr" value="assigned">Filter :</p>
                                <button class="  fib_system_btn">
                  <i class="fa fa-angle-down" aria-hidden="true"></i>
                </button>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <form (submit)="search($event)">
                                    <div class="row">

                                        
                                        <div class="col-xs-12 col-sm-12 col-lg-8">
                                            <div class="form-group">
                                                <input type="text" class="form-control" id="search" name="search" value="" placeholder="Search client name or phone" />
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-12 col-lg-4 d-flex ">
                                            <div class="form-group">
                                                <button type="submit" class="fib_system_btn mr-3">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                                            </div>
                                            <div class="form-group">
                                                <button type="button" class="fib_system_btn  mr-3" data-toggle="modal" data-target="#multipleLeadsAssignModel">
                          <i class="fa fa-user-plus" aria-hidden="true"></i>
                        </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <form (submit)="getclientbyid($event)">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-12 col-lg-8">
                                            <div class="form-group">
                                                <input type="text" class="form-control" id="search12" name="search" value="" placeholder="Enter Lead Id" />
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-12 col-lg-4 d-flex ">
                                            <div class="form-group">
                                                <button type="submit" class="fib_system_btn mr-3">
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                                            </div>
                                            <div class="form-group">
                                                <button type="button" class="fib_system_btn  mr-3" data-toggle="modal" data-target="#multipleLeadsAssignModel">
                          <i class="fa fa-user-plus" aria-hidden="true"></i>
                        </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="collapse" id="collapsefilters">
                        <form #filterForm="ngForm" (submit)="onsubmitFilter(filterForm)">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-3 col-lg-12 ">
                                        <div class="form-group">
                                            <div class="date_filter_tab date_filter_btn">
                                                <p class="" for="usr" value="assigned">Date Filter :</p>
                                                <button class="fib_system_btn" type="button" data-toggle="collapse" data-target="#collapseassigned" aria-expanded="false" aria-controls="collapseExample">
                          <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </button>
                                            </div>

                                            <div class="form-group collapse label-group " id="collapseassigned">
                                                <div class="row">
                                                    <div class="col-xs-12 col-sm-3 col-lg-4 ">
                                                        <div class="form-group">
                                                            <label class="label-text" for="usr" value="receivedon">Recieved Date:</label>

                                                            <div>
                                                                <label for="usr">From:</label>
                                                                <input ngModel name="rdFrom" #rdFrom="ngModel" type="date" class="form-control" id="rFrom" />
                                                                <label for="usr">To:</label>
                                                                <input ngModel name="rdTo" #rdTo="ngModel" type="date" class="form-control" id="rTo" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xs-12 col-sm-3 col-lg-4">
                                                        <div class="form-group">
                                                            <label class="label-text" for="usr" value="assigned">Assigned Date:</label>

                                                            <div>
                                                                <label for="usr">From:</label>
                                                                <input ngModel name="adFrom" #adFrom="ngModel" type="date" class="form-control" id="aFrom" />
                                                                <label for="usr">To:</label>
                                                                <input ngModel name="adTo" #adTo="ngModel" type="date" class="form-control" id="aTo" />
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-xs-12 col-sm-3 col-lg-4 ">
                                                        <div class="form-group">
                                                            <ng-multiselect-dropdown ngModel name="dcStatus" #dcStatus="ngModel" class="label_drop" [placeholder]="'Call Status Date:'" [data]="filterStatus" [settings]="dropdownSettings1">
                                                            </ng-multiselect-dropdown>

                                                            <div>
                                                                <label for="usr">From:</label>
                                                                <input ngModel name="csdFrom" #csdFrom="ngModel" type="date" class="form-control" id="csFrom" />
                                                                <label for="usr">To:</label>
                                                                <input ngModel name="csdTo" #csdTo="ngModel" type="date" class="form-control" id="csTo" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-sm-12 col-lg-2">
                                        <div class="form-group">
                                            <label for="usr">Leads Status</label>
                                            <ng-multiselect-dropdown ngModel name="lStatus" #lStatus="ngModel" class="im_multi" [placeholder]="'Leads Status'" [data]="leadsStatus" [settings]="dropdownSettings" (onSelect)="onLeadsStatusSelect($event)" (onDeSelect)="onLeadsStatusDeSelect($event)"
                                                (onSelectAll)="onLeadsStatusSelectAll($event)" (onDeSelectAll)="onLeadsStatusDeSelectAll($event)">
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-sm-12 col-lg-2">
                                        <div class="form-group">
                                            <label for="usr">Call Status</label>
                                            <ng-multiselect-dropdown ngModel name="lcStatus" #lcStatus="ngModel" class="im_multi" [placeholder]="'Call Status'" [data]="callStatus" [settings]="dropdownSettings">
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xs-12 col-sm-12 col-lg-2">
                                        <div class="form-group">
                                            <label for="usr">Last Status</label>
                                            <ng-multiselect-dropdown ngModel name="lastStatus" #lastStatus="ngModel"  class="im_multi" [placeholder]="'Last Status'" [data]="lastcallstatus" [settings]="dropdownSettings">
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div> -->

                                    <div class="col-xs-12 col-sm-12 col-lg-2">
                                        <div class="form-group">
                                            <label for="usr">Leads Assigned To</label>
                                            <ng-multiselect-dropdown ngModel name="lAssignedTo" #lAssignedTo="ngModel" class="im_multi" [placeholder]="'Leads Assigned To'" [data]="userList" [settings]="dropdownSettings">
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-sm-12 col-lg-2">
                                        <div class="form-group">
                                            <label for="usr">Leads Assigned By</label>
                                            <ng-multiselect-dropdown ngModel name="lAssignedBy" #lAssignedBy="ngModel" class="im_multi" [placeholder]="'Leads Assigned by'" [data]="userList" [settings]="dropdownSettings">
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-sm-12 col-lg-2">
                                        <div class="form-group">
                                            <label for="sel1">Source:</label>
                                            <ng-multiselect-dropdown ngModel name="lSource" #lSource="ngModel" class="im_multi" [placeholder]="'Source Name'" [data]="sourceNameList" [settings]="dropdownSettings">
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-sm-12 col-lg-2">
                                        <div class="form-group">
                                            <label for="usr">Project Name:</label>
                                            <ng-multiselect-dropdown ngModel name="lProject" #lProject="ngModel" class="im_multi" [placeholder]="'Projects Name'" [data]="projectNameList" [settings]="dropdownSettings">
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-sm-12 col-lg-2">
                                        <div class="form-group">
                                            <label for="sel1">Transferred leads</label>
                                            <select ngModel name="tLeads" #tLeads="ngModel" class="form-control" id="transfered">
                        <option value="" selected>All leads</option>
                        <option value="YES">Yes</option>
                        <option value="NO">No</option>
                      </select>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-sm-12 col-lg-2">
                                        <div class="form-group">
                                            <label for="sel1">Order By</label>
                                            <select ngModel name="orderBy" #orderBy="ngModel" class="form-control">
                        <option value="" selected>Select Order By</option>
                        <option value="ASC">Ascending Order</option>
                        <option value="DESC">Descending Order</option>
                      </select>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-sm-12 col-lg-2">
                                        <div class="form-group">
                                            <label for="sel1">Sort By</label>
                                            <select ngModel name="sortBy" #sortBy="ngModel" class="form-control">
                        <option value="" selected>Select Sort By</option>
                        <option value="receivedOn">Received Date</option>
                        <option value="leadId">Lead Id</option>
                        <option value="name">Name</option>
                        <option *ngIf="isValid" value="phone">Phone</option>
                        <option value="projectName">Project Name</option>
                      </select>
                                        </div>
                                    </div>


                                    <div class="col-xs-12 col-sm-12 col-lg-2">
                                        <div class="form-group">
                                            <label for="sel1">Page Size.</label>
                                            <select ngModel name="pSize" #pSize="ngModel" name="pageSize" class="form-control" (change)="onChangeSize($event.target.value)">
                        <option value="" selected>Select Page Size</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </select>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-lg-2">
                                        <div class="form-group">
                                            <label for="sel1">Total Item founds :</label>
                                            <input class="form-control" type="text" value=" {{itemCount}}" disabled />
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-lg-2 d-flex ">
                                        <div class="form-group ">
                                            <label> &nbsp;</label>
                                            <button type="submit" class="fib_system_btn  mr-3">
                        <i class="fa fa-filter" aria-hidden="true"></i>
                      </button>
                                        </div>
                                        <div class="form-group">
                                            <label for="sel1">&nbsp;</label>
                                            <button class="fib_system_btn  mr-3" (click)="exportAsXLSX()">
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-lg-4">
            <!-- Modal -->
            <div class="modal fade" id="multipleLeadsAssignModel" role="dialog" data-keyboard="false" data-backdrop="static">
                <div class="modal-dialog">
                    <!-- Modal content-->
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Select Employee</h4>
                            <button type="button" class="close" data-dismiss="modal">
                <i class="fa fa-window-close" aria-hidden="true"></i>
              </button>
                        </div>
                        <div class="modal-body">
                            <form (submit)="assignMultipleLead($event)">
                                <div class="box-body">
                                    <div class="form-group">
                                        <label>Select Employee</label>
                                        <select class="form-control" (change)="
                        onChangeUserDropBoxOfMultipleAssign(
                          $event.target.value
                        )
                      ">
                      <option *ngFor="let user of activeUsers" value="{{ user.id }}">
                        {{ user.firstName + " " + user.lastName }}
                      </option>
                    </select>
                                    </div>
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="data" checked id="data1" value="fresh">
                                            <label class="form-check-label  radio-label" for="data1">
                        Fresh Data
                      </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <!-- <input class="form-check-input" type="radio" name="data" id="data1" value="calling"> -->
                                            <!-- <label class="form-check-label radio-label" for="data1">Calling Data </label> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- /.box-body -->
                                <div class="box-footer">
                                    <button type="submit" class="btn btn-primary">
                    Submit
                  </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--End filter form section -->

        <div class="col-xs-12">
            <div class="table-responsive table-fixed leads_table_redesign table-bg">
                <table summary="" class="table table-bordered table-hover table-striped text-center borderStyle" style="background-color: #ecf7fc;">
                    <thead class="borderStyle">
                        <tr>
                            <th>
                                <input class="form-check-input" type="checkbox" id="parent" (change)="selectAll($event.target.checked)" />
                            </th>
                            <th>SN</th>
                            <th>Leads Id</th>
                            <th>Client Name</th>
                            <th *ngIf="isValid">Client Mobile</th>
                            <th style="background-color: #ffd75f21">Email</th>
                            <th>Source</th>
                            <th>Project Name</th>
                            <th>Query Info</th>
                            <th style="background-color: #b17fad26">Status</th>
                            <th>Call Status</th>
                            <th>Last Status</th>
                            <th>Recieved On</th>
                            <th>Assigned Date</th>
                            <th>Last Update</th>
                            <th>Call status Date</th>
                            <th>Visit Status</th>
                            <th>Visit Done Date</th>
                            <th>Virtual Meeting Status</th>
                            <th>Remark</th>
                            <th>Created By</th>
                            <th>Assigned By</th>
                            <th>Assigned To</th>
                        </tr>
                    </thead>
                    <tbody class="serialNumber">
                        <tr class="content" *ngFor="let report of leadsReport | paginate: config">
                            <td>
                                <input type="checkbox" id="child_checkbox" (change)="onCheck(report.leads.id, $event)" />
                            </td>
                            <td></td>
                            <td data-toggle="modal" (click)="leadFeedback(report.leads.id, report.user.id)" data-target="#leadsfeedback">
                                {{ report.leads.id }}
                            </td>

                            <td>{{ report.leads.name }}</td>
                            <td *ngIf="isValid">{{ report.leads.phone }}</td>
                            <td style="background-color: hsla(45, 100%, 69%, 0.129); width: 10%">
                                {{ report.leads.email }}
                            </td>
                            <td>{{ report.leads.source }}</td>
                            <td>{{ report.leads.projectName }}</td>
                            <td>{{ report.leads.queryInfo }}</td>
                            <td style="background-color: #b17fad26">
                                {{ report.status }}
                            </td>
                            <td>{{ report.callStatus }}</td>
                            <td>{{ report.lastcallstatus }}</td>
                            <td *ngIf="report.leads.receivedOn != 0">
                                {{ report.leads.receivedOn | date: "dd-MM-yyyy hh:mm a" }}
                            </td>
                            <td *ngIf="report.leads.receivedOn == 0"></td>
                            <td *ngIf="report.assignLeadsDate != 0">
                                {{ report.assignLeadsDate | date: "dd-MM-yyyy hh:mm a" }}
                            </td>
                            <td *ngIf="report.assignLeadsDate == 0"></td>
                            <td *ngIf="report.updateDate != 0">
                                {{ report.updateDate | date: "dd-MM-yyyy hh:mm a" }}
                            </td>
                            <td *ngIf="report.updateDate == 0"></td>
                            <td *ngIf="report.callStatusDate != 0">
                                {{ report.callStatusDate | date: "dd-MM-yyyy hh:mm a" }}
                            </td>
                            <td *ngIf="report.callStatusDate == 0"></td>
                            <td *ngIf="report.visitStatus" style="color: green">Done</td>
                            <td *ngIf="!report.visitStatus" style="color: red">Not Done</td>
                            <td>{{report.visitDoneDate ? (report.visitDoneDate | date: "dd-MM-yyyy hh:mm a") : "" }}</td>
                            <td *ngIf="report.virtualMeetingStatus" style="color: green">
                                Done
                            </td>
                            <td *ngIf="!report.virtualMeetingStatus" style="color: red">
                                Not Done
                            </td>
                            <td>{{ report.message }}</td>
                            <td *ngIf="report.leads.createdBy == null"></td>
                            <td *ngIf="report.leads.createdBy != null">
                                {{ report.leads.createdBy.firstName + " " + report.leads.createdBy.lastName }}
                            </td>
                            <td *ngIf="report.assignedBy == null"></td>
                            <td *ngIf="report.assignedBy != null">
                                {{ report.assignedBy.firstName + " " + report.assignedBy.lastName }}
                            </td>
                            <td id="tt">
                                <select name="users" (change)="onChange($event.target.value, report.leads.id)">
                  <option *ngIf="report.user != null">
                    {{ report.user.firstName + " " + report.user.lastName }}
                  </option>
                  >
                  <option *ngIf="report.user == null" value="0">
                    Select User
                  </option>
                  <option *ngFor="let user of activeUsers" value="{{ user.id }}">
                    {{ user.firstName + " " + user.lastName }}
                  </option>
                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- <div class="row">
          <div class="col-sm-6"></div>
        </div> -->
                <!-- <hr>
            <ngx-paginate [page]="page" [options]="options" (pageChange)='setPage($event)'
              (pageSizeChange)='setPage($event)'>
            </ngx-paginate> -->
                <!-- <pagination-controls (pageChange)="pageChange($event)" class="my-pagination"></pagination-controls>
            <ul class="nav nav-pills">
              <li class="nav-item" *ngFor="let p of pages; let i = index;">
                <a class="nav-link" href="" (click)="setPage(i, $event)">{{i}}</a>
              </li>
            </ul> -->
                <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
            <!--end of .table-responsive-->

        </div>
    </section>
    <!-- partial -->
</div>
<div class="leadfeedback">
    <!-- Modal -->
    <div class="modal fade" id="leadsfeedback" role="dialog" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-xl">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Leads Feedback</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xs-12 col-lg-12">
                            <div class="tabs">
                                <div class="tab-2">
                                    <label for="tab2-1">Feedback</label>
                                    <input id="tab2-1" name="tabs-two" type="radio" checked="checked" />
                                    <div>
                                        <table summary="" class="table table-bordered table-hover table-striped text-center">
                                            <thead>
                                                <tr>
                                                    <th>SN</th>
                                                    <th>Lead id</th>
                                                    <th style="background-color: #b17fad26">Status</th>
                                                    <th>Call Status</th>
                                                    <th>Message</th>
                                                    <th>Time of Feedback</th>
                                                    <th>Call Status Date</th>
                                                    <th>Visit Done Date</th>
                                                    <th>Virtual Meeting Done Date</th>
                                                    <th>Assigned to</th>
                                                </tr>
                                            </thead>
                                            <tbody class="serialNumber">
                                                <tr class="content" *ngFor="let feedback of feedbackList" data-toggle="modal" data-target="#leadsReportmodal">
                                                    <td></td>
                                                    <td>{{ feedback.leads.id }}</td>
                                                    <td style="background-color: #b17fad26">
                                                        {{ feedback.status }}
                                                    </td>
                                                    <td>{{ feedback.callStatus }}</td>
                                                    <td>{{ feedback.message }}</td>
                                                    <td *ngIf="feedback.timeOfFeedback">{{ feedback.timeOfFeedback | date: "dd-MM-yyyy hh:mm a" }}
                                                    </td>
                                                    <td *ngIf="!feedback.timeOfFeedback"></td>
                                                    <td *ngIf="feedback.callStatusDate">{{ feedback.callStatusDate | date: "dd-MM-yyyy hh:mm a" }}
                                                    </td>
                                                    <td *ngIf="!feedback.callStatusDate"></td>
                                                    <td *ngIf="feedback.visitDoneDate">{{ feedback.visitDoneDate | date: "dd-MM-yyyy hh:mm a" }}
                                                    </td>
                                                    <td *ngIf="!feedback.visitDoneDate"></td>
                                                    <td *ngIf="feedback.virtualMeetingDoneDate">{{ feedback.virtualMeetingDoneDate | date: "dd-MM-yyyy hh:mm a" }}</td>
                                                    <td *ngIf="!feedback.virtualMeetingDoneDate"></td>
                                                    <td id="tt">
                                                        {{ feedback.user.firstName + " " + feedback.user.lastName }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <!-- <div class="form-group">
                                            <label for="sel1">&nbsp;</label>
                                            <button class="fib_system_btn  mr-3" (click)="exportAsXLSX1()">
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="tab-2">
                                    <label for="tab2-2">Call Record</label>
                                    <input id="tab2-2" name="tabs-two" type="radio" />
                                    <div>
                                        <table summary="" class="table table-bordered table-hover table-striped text-center">
                                            <thead>
                                                <tr>
                                                    <th>SN</th>
                                                    <th>Client Name</th>
                                                    <th style="background-color: #b17fad26">
                                                        Agent Name
                                                    </th>
                                                    <th>Agent Mobile</th>
                                                    <th>Date</th>
                                                    <th>Audio Play</th>
                                                    <!-- <th>Download</th> -->
                                                </tr>
                                            </thead>
                                            <tbody class="serialNumber">
                                                <tr class="content" *ngFor="let recording of recordings" data-toggle="modal" data-target="#leadsReportmodal">
                                                    <td></td>
                                                    <td>{{ recording.clientName }}</td>
                                                    <td style="background-color: #b17fad26">
                                                        {{ recording.agentName }}
                                                    </td>
                                                    <td>{{ recording.agentMobile }}</td>
                                                    <td>{{ recording.date }}</td>
                                                    <td>
                                                        <!-- <a class="btn btn-primary" (click)="downloadSampleCSVFiles(recording.file)">
                              Download File
                            </a> -->

                                                        <audio id="audio" controls preload="auto">
                              <source id="sourceAudio" src="{{ recording.file }}" type="audio/mp3" />
                            </audio>
                                                    </td>
                                                    <!-- <td><i class="fa fa-download" aria-hidden="true"></i></td> -->
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>