<div class="content-wrapper" style="background-color: #fff !important;">
    <section class="Leads_manage leads_redesign container2">
      <h1>Performance</h1>
      <p style="font-size: 19px;font-weight: 600;">User Id: {{ leadsReport?.userId }}</p>
      <p style="font-size: 19px;font-weight: 600;">Name: {{ leadsReport?.userName }}</p>
  
      <div class="row">
        <div class="col-md-3">
          <div class="card">
            <div class="card-body">
              <p>Total Leads : {{leadsReport?.totalLeads}}</p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card">
            <div class="card-body">
                <p>Not Called : {{leadsReport?.notCalled}}</p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card">
            <div class="card-body">
                <p>Open Leads : {{leadsReport?.openLeads}}</p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                  <p>Success Leads : {{leadsReport?.successLeads}}</p>
              </div>
            </div>
          </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="card">
            <div class="card-body"  >
              <p>Decline Leads  : {{leadsReport?.declineLeads}}</p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card">
            <div class="card-body"  >
                <p>Success Leads : {{leadsReport?.successLeads}}</p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card" >
            <div class="card-body" style="cursor: pointer;" style="cursor: pointer;    background: brown;"  (click)="handleDeclineLeadsClick()">
                <p>Open To Decline Leads : {{leadsReport?.openToDeclineLeads}}</p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                  <p>Follow Up To Decline : {{leadsReport?.followupToDecline}}</p>
              </div>
            </div>
          </div>
      </div>
      <div class="row">
        
        <div class="col-md-3">
          <div class="card">
            <div class="card-body">
                <p>Call Back To Decline : {{leadsReport?.callbackToDecline}}</p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card">
            <div class="card-body">
                <p>Meeting Plan To Decline : {{leadsReport?.meetingPlanToDecline}}</p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                  <p>Visit Plan To Decline : {{leadsReport?.visitPlanToDecline}}</p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <p>Meeting Done To Decline : {{leadsReport?.meetingDoneToDecline}}</p>
              </div>
            </div>
          </div>
      </div>
      <div class="row">
        
        <div class="col-md-3">
          <div class="card">
            <div class="card-body">
                <p>Visit Done To Decline : {{leadsReport?.visitDoneToDecline}}</p>
            </div>
          </div>
        </div>
       
        
      </div>
 
    </section>
  </div>
  