<div class="content-wrapper" style="background-color: #fff !important;">
    <section class="Leads_manage leads_redesign">


        <a class="backbtn" style="margin-left: 6rem;" href="/crm">
            <button class="btn btn-warning"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
        </a>
        <body>
            <section class="Leads_manage leads_redesign" >
            <div class="container">
            <div class="card" style="background-color: rgb(255, 255, 255)">
            <div class="card-body">
            <h1 class="h1">Profile Details</h1>
            <div class="table-responsive">
            <table class="table mt-3 table-hover text-center">
            <thead>

            <tr>
            <th scope="row">User's Active Status</th>
            <th><span>{{user.active}}</span></th>
            </tr>
            <tr>
            <th scope="row">Name</th>
            <th><span>{{name}}</span></th>
            </tr>

            <tr>
            <th scope="col">User Id</th>
            <th scope="col"><span>{{user.id}}</span></th>
            </tr>
            </thead>
            <tbody>

            <tr>
            <th scope="row">Gender</th>
            <td><span>{{user.gender}}</span></td>
            </tr>


            <tr>
            <th scope="row">Date Of Birth</th>
            <td><span>{{user.dob}}</span></td>
            </tr>


            <tr>
            <th scope="row">Phone No</th>
            <td><span>{{mobile}}</span></td>
            </tr>

            <tr>
            <th scope="row">Email</th>
            <td><span>{{user.email}}</span></td>
            </tr>


            <tr>
            <th scope="row">Role</th>
            <th><span>{{role}}</span></th>
            </tr>

            <tr>
            <th scope="row">Address</th>
            <td><span>{{user.address}}</span></td>
            </tr>

            </tbody>
            </table>
            </div>


            </div>

            </div>

            <a href="crm/updateUser">
            <button  class="btn btn-primary btn-block">Edit Details</button>
            </a>

            </div>


            </section>
        </body>
    </section>
</div>
