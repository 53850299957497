<div class="content-wrapper" style="min-height: 906px">
  <section class="topmm">
    <button class="btn btn-primary toggle__btn" type="button" data-toggle="collapse" data-target="#collapsefilters"
      aria-expanded="false" aria-controls="collapseExample">
      &#x25BC;
    </button>
    <div class="container-fluid">
      <div class="collapse" id="collapsefilters">
        <div class="row">
          <div class="filter-leads">
            <form (submit)="onsubmitFilter($event)">
              <div class="col-xs-12 col-sm-12 col-lg-2">
                <div class="form-group">
                  <label for="usr">Received Date From</label>
                  <input type="date" class="form-control" id="datefrom" />
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-lg-2">
                <div class="form-group">
                  <label for="usr">Received Date To:</label>
                  <input type="date" class="form-control" id="dateto" />
                </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-lg-3" *ngIf="ROLE">
                <div class="form-group">
                  <label for="sel1">Source:</label>
                  <!-- <select class="form-control" (change)="onchangeSource($event.target.value)"> -->
                  <ng-multiselect-dropdown [placeholder]="'Source Name'" [data]="sourceNameList"
                    [settings]="dropdownSettings" (onSelect)="onSourceSelect($event)"
                    (onDeSelect)="onSourceDeSelect($event)" (onSelectAll)="onSelectAllSource($event)"
                    (onDeSelectAll)="onDeSelectAllSource($event)">
                  </ng-multiselect-dropdown>
                </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-lg-3">
                <div class="form-group">
                  <label for="usr">Project Name:</label>
                  <ng-multiselect-dropdown [placeholder]="'Projects Name'" [data]="projectNameList"
                    [settings]="dropdownSettings" (onSelect)="onProjectSelect($event)"
                    (onDeSelect)="onProjectDeSelect($event)" (onSelectAll)="onSelectAllProject($event)"
                    (onDeSelectAll)="onSelectAllProject($event)">
                  </ng-multiselect-dropdown>
                </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-lg-2 mb-30">
                <div class="button_ss">
                  <button type="submit" class="btn btn-primary btn-block">
                    Filter
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-3 mb-30">
            <a href="import/leads"><button type="button" class="btn btn-primary btn-block">
                Import
              </button></a>
          </div>
          <div class="col-xs-12 col-lg-3 mb-30">
            <app-add-lead></app-add-lead>
          </div>
          <div class="col-lg-3 col-xs-12 mb-30" *ngIf="ROLE">
            <div class="assignmultiplelead">
              <button type="button" class="btn btn-primary btn-block btn-md" data-toggle="modal"
                data-target="#leadassigned">
                Assign Multiple Leads
              </button>
              <!-- Modal -->
              <div class="modal fade" id="leadassigned" role="dialog" data-keyboard="false" data-backdrop="static">
                <div class="modal-dialog">
                  <!-- Modal content-->
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="close" data-dismiss="modal">
                        ×
                      </button>
                      <h4 class="modal-title">Select Employee</h4>
                    </div>
                    <div class="modal-body">
                      <form role="form" (submit)="assignMultipleLead()">
                        <div class="box-body">
                          <div class="form-group">
                            <label>Select Employee</label>
                            <select class="form-control" (change)="
                                onChangeUserDropBoxOfMultipleAssign(
                                  $event.target.value
                                )
                              ">
                              <option *ngFor="let user of activeUsers" value="{{ user.id }}">
                                {{ user.firstName + " " + user.lastName }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <!-- /.box-body -->
                        <div class="box-footer">
                          <button type="submit" class="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- partial:index.partial.html -->
  <section class="Leads_manage">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12">
          <div class="table-responsive table-fixed">
            <table summary="" class="table table-bordered table-hover table-striped text-center">
              <thead>
                <tr>
                  <th>SN</th>
                  <th *ngIf="ROLE && !MOBILE">#</th>
                  <th>Leads Id</th>
                  <th style="background-color: #ffd75f21">Client Name</th>
                  <th *ngIf="isValid">Clien Mobile</th>
                  <th>Email</th>
                  <th *ngIf="ROLE">Source</th>
                  <th style="background-color: #0ce2e21f">Project Name</th>
                  <th>Query Info.</th>
                  <th>Leads Received</th>
                  <th *ngIf="!ROLE">Feedback</th>
                  <th>Assigned Project</th>
                </tr>
              </thead>
              <tbody class="serialNumber">
                <tr class="content" *ngFor="let leads of leadsArray">
                  <td></td>
                  <td *ngIf="ROLE && !MOBILE">
                    <div>
                      <input type="checkbox" (change)="onCheck(leads.id, $event)" />
                    </div>
                  </td>
                  <td>{{ leads.id }}</td>
                  <td style="background-color: #ffd75f21">{{ leads.name }}</td>
                  <td *ngIf="isValid">{{ leads.phone }}</td>
                  <td>{{ leads.email }}</td>
                  <td *ngIf="ROLE">{{ leads.source }}</td>
                  <td style="background-color: #0ce2e21f">
                    {{ leads.cmpctLabl }}
                  </td>
                  <td style="width: 10%">{{ leads.queryInfo }}</td>
                  <td *ngIf="ROLE && leads.receivedOn != 0">{{ leads.receivedOn | date:"dd-MM-yyyy hh:mm a" }}</td>
                  <td *ngIf="ROLE && leads.receivedOn == 0"></td>
                  <td *ngIf="!ROLE">{{ leads.assignedDate }}</td>
                  <td *ngIf="!ROLE">
                    <button class="btn btn-primary" value="Feedback" title="Feedback" data-toggle="modal"
                      (click)="leadStatusUpdate(leads, leads.user)" data-target="#feedbackModal">
                      Feedback
                    </button>
                  </td>
                  <td id="tt">
                    <select name="users" (change)="assignLead($event.target.value, leads.id)">
                      <option *ngIf="leads.user != null">
                        {{ leads.user.firstName + " " + leads.user.lastName }}
                      </option>
                      >
                      <option *ngIf="leads.user == null" value="0">
                        Select User
                      </option>
                      <option *ngFor="let user of activeUsers" value="{{ user.id }}">
                        {{ user.firstName + " " + user.lastName }}
                      </option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--end of .table-responsive-->
        </div>
      </div>
    </div>
  </section>
  <!-- partial -->
</div>
<!-- Modal -->

<div class="modal fade" id="feedbackModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal_heading text-center">
        <h3 class="text-primary">Update Feedback</h3>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-6">
            <div class="form-group">
              <label for="usr">Status</label>
              <select name="users" class="form-control" (change)="feedbackStatus($event.target.value)"
                style="width: 100%" id="my_select">
                <option value="" selected="selected">Select Satus</option>
                <option value="open">Accept</option>
                <option value="decline">Reject</option>
              </select>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-lg-6">
            <div class="form-group">
              <label for="usr">Call Status</label>
              <select id="mySelect" disabled name="users" class="form-control"
                (change)="feedbackCallStatus($event.target.value)" style="width: 100%">
                <option value="">Select Call Satus</option>
                <option *ngFor="let str of callStatusArray" value="{{ str }}">
                  {{ str }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="callStatusBoolean">
          <div class="col-xs-12 col-sm-12 col-lg-12">
            <form name="feedbackForm" (submit)="saveRemark($event)">
              <div class="form-group" style="width: 100%">
                <label for="">Remark</label>
                <textarea placeholder="Remark please" class="form-control" id="remarkArea" name="remark"></textarea>
              </div>
              <div class="form-group" style="width: 100%" *ngIf="statusCheck">
                <label for="">Choose Date</label>
                <input type="datetime-local" class="form-control" id="chooseDate" placeholder="choose date" required />
              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-primary pull-right">
                  Save Feedback
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>