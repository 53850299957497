import { Component, OnInit } from '@angular/core';

import * as XLSX from 'xlsx';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

type AOA = any[][];

@Component({
  selector: 'app-sheet',
  templateUrl: './sheet.component.html',
  styleUrls: ['./sheet.component.css']
})
export class SheetComponent implements OnInit {

  excelData: Array<any> = [];
  fieldName: any[];
  str:any = null;
  constructor(private auth: AuthService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.str = this.route.snapshot.paramMap.get("str");
    console.log("Str", this.str)
  }

  data: AOA = [];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'LeadsReport.xlsx';

  onFileChange(evt: any) {
    this.excelData.splice(0, this.excelData.length);
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      this.excelImportedLeads()
    };
    reader.readAsBinaryString(target.files[0]);
  }

  excelImportedLeads() {
    var i: number = 0;
    let obj: any;
    for (let index of this.data) {
      i = i + 1;
      //Take all the date fields name.
      if (i == 1) {
        obj = index;
        this.fieldName = index;
      }

      if (index.length > 0 && i > 1) {
        var arrObj = {};
        for (var content in index) {
          arrObj[obj[content]] = index[content];
        }
        this.excelData.push(arrObj);
      }
    }
  }

  saveImportedLeads(event) {
    let target = event.target;
    var arrObj = {};

    if (target.querySelector('#clientName').value != null) {
      arrObj["name"] = target.querySelector('#clientName').value;
    }
    if (target.querySelector('#clientPhone').value != null) {
      arrObj["phone"] = target.querySelector('#clientPhone').value;
    }
    if (target.querySelector('#clientEmail').value != null) {
      arrObj["email"] = target.querySelector('#clientEmail').value;
    }
    if (target.querySelector('#queryInfo').value != null) {
      arrObj["queryInfo"] = target.querySelector('#queryInfo').value;
    }
    if (target.querySelector('#source').value != null) {
      arrObj["source"] = target.querySelector('#source').value;
    }
    if (target.querySelector('#projectName').value != null) {
      arrObj["projectName"] = target.querySelector('#projectName').value;
    }

    var fieldAndValue = {};

    fieldAndValue['fieldName'] = arrObj;
    fieldAndValue['lists'] = this.excelData;
    if (this.excelData.length > 0) {
      this.auth.saveExcelData(fieldAndValue, this.str).subscribe(
        data => {
          alert(data['message']);
        }
      )
    }else{
      alert("Please select excel file, Thank You.");
    }
  }


  export(): void {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

}
