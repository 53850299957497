<div class="content-wrapper" style="min-height: 906px">
    <section class="">
        <div id="ui_warning" class="alert alert-warning ui_warning" role="alert">
            <p>your screen resolution is not compatible with app UI</p>
            <i class="fa fa-times" aria-hidden="true" onclick="hideElement();"></i>
        </div>
        <div id="overlay"></div>
    </section>
    <section class="fib_main">
        <form #customerForm="ngForm" (submit)="saveCustomerRecord(customerForm)">
            <div class="row">
                <div class="col-md-12">
                    <div class="d-flex justify-content-between pt-3">
                        <div class="item">
                            <h3>Fib System</h3>
                        </div>
                        <div class="item">
                            <button type="submit" class="fib_system_btn">Submit</button>
                        </div>
                    </div>
                    <hr />
                </div>
                <div class="col-md-4">
                    <div class="box_white">
                        <h2>Customer Details</h2>
                        <span class="under_line_fib"></span>
                        <div class="row">
                            <div class="col-md-6">
                                <label>Name</label>
                                <input ngModel name="cName" #cName="ngModel" type="text" required class="form-control"
                                    placeholder="Enter Full Name" />
                                <div class="alert alert-danger" *ngIf="cName.touched && !cName.valid">
                                    Customer Name is required
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label>Phone </label>
                                <input ngModel name="cPhone" #cPhone="ngModel" type="number" required
                                    class="form-control" placeholder="Enter Phone :" />
                            </div>
                            <div class="col-md-6">
                                <label>Email</label>
                                <input ngModel name="cEmail" #cEmail="ngModel" type="email" required
                                    class="form-control" placeholder="Enter Email : " />
                            </div>
                            <div class="col-md-6">
                                <label>Pan Card</label>
                                <input ngModel name="cPanCard" #cPanCard="ngModel" type="text" required
                                    class="form-control" placeholder="Enter Pan Card Number" />
                            </div>
                            <div class="col-md-6">
                                <label>DOB</label>
                                <input ngModel name="cDob" #cDob="ngModel" type="date" required class="form-control"
                                    placeholder="Date Of Birth : " />
                            </div>
                            <div class="col-md-6">
                                <label>Anniversary Date</label>
                                <input ngModel name="cAnniDate" #cAnniDate="ngModel" type="date" required
                                    class="form-control" placeholder="Enter Anniversary Date : " />
                            </div>
                            <div class="col-md-12">
                                <label>Source :</label>
                                <ng-multiselect-dropdown ngModel name="cSource" #cSource="ngModel" type="date" required
                                    class="im_multi b-5" [placeholder]="'Select client source'" [data]="sourceList"
                                    [settings]="dropdownSettings">
                                </ng-multiselect-dropdown>
                            </div>
                            <div class="col-md-12">
                                <label>Sales Member :</label>
                                <ng-multiselect-dropdown ngModel name="cSalesMember" #cSalesMamber="ngModel" required
                                    class="im_multi b-5" [placeholder]="'Select sales member'" [data]="userList"
                                    [settings]="dropdownSettings">
                                </ng-multiselect-dropdown>
                            </div>
                            <div class="col-md-12">
                                <label>Sales Head :</label>
                                <ng-multiselect-dropdown ngModel name="cSalesHead" #cSalesHead="ngModel" required
                                    class="im_multi b-5" [placeholder]="'Select sales head'" [data]="userList"
                                    [settings]="dropdownSettings">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="box_white">
                        <h2>Flat Details</h2>
                        <span class="under_line_fib"></span>
                        <div class="row">
                            <div class="col-md-12">
                                <label>Project Name </label>
                                <ng-multiselect-dropdown ngModel name="cProject" #cProject="ngModel" required
                                    class="im_multi b-5" [placeholder]="'Select clent project'" [data]="projectList"
                                    [settings]="dropdownSettings">
                                </ng-multiselect-dropdown>
                            </div>
                            <div class="col-md-6">
                                <label>Unit Id</label>
                                <input ngModel name="fUnitId" #fUnitId="ngModel" type="text" required
                                    class="form-control" placeholder="Enter Unit Id" />
                            </div>
                            <div class="col-md-6">
                                <label>City</label>
                                <input ngModel name="fLocCity" #fLocCity="ngModel" type="text" required
                                    class="form-control" placeholder="Enter City " />
                            </div>
                            <div class="col-md-6">
                                <label>State</label>
                                <input ngModel name="fLocState" #fLocState="ngModel" type="text" required
                                    class="form-control" placeholder="Enter State : " />
                            </div>
                            <div class="col-md-6">
                                <label>Pin Code</label>
                                <input ngModel name="fLocPinCode" #fLocPinCode="ngModel" type="number" required
                                    class="form-control" placeholder="Enter Pin Code : " />
                            </div>
                            <div class="col-md-6">
                                <label>Size</label>
                                <input ngModel name="fSize" #fSize="ngModel" type="number" required class="form-control"
                                    placeholder="Enter Size : " />
                            </div>
                            <div class="col-md-6">
                                <label>Cost</label>
                                <input ngModel name="fCost" #fCost="ngModel" type="number" required class="form-control"
                                    placeholder="Enter Cost : " />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="box_white">
                        <h2>Booking Details</h2>
                        <span class="under_line_fib"></span>
                        <div class="row">
                            <div class="col-md-12">
                                <label>Payment Plan </label>
                                <input ngModel name="fPaymentPlan" #fPaymentPlan="ngModel" required type="text"
                                    class="form-control" placeholder="Enter Payment Plan :" />
                            </div>
                            <div class="col-md-6">
                                <label>Booking Amount</label>
                                <input ngModel name="bAmount" #bAmount="ngModel" required type="text"
                                    class="form-control" placeholder="Enter Booking Amount" />
                            </div>
                            <div class="col-md-6">
                                <label>10% Booking Amt. Status</label>
                                <select ngModel name="bAmountStatus" #bAmountStatus="ngModel" required
                                    class="form-control">
                                    <option value="YES">YES</option>
                                    <option value="NO">NO</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label>Booking Date</label>
                                <input ngModel name="bDate" #bDate="ngModel" required type="date" class="form-control"
                                    placeholder="Enter Date : " />
                            </div>
                            <div class="col-md-6">
                                <label>Disbursement Date</label>
                                <input ngModel name="bDisbursDate" #bDisbursDate="ngModel" required type="date"
                                    class="form-control" placeholder="Enter Date : " />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </section>
</div>