import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  name: string = localStorage.getItem('firstName') + " " + localStorage.getItem('lastName');
  mobile = localStorage.getItem('mobile');
  ROLE: boolean;
  userRole: string;
  userMobile: string;
  MOBILE: boolean;

  constructor(private auth: AuthService) { }

  ngOnInit() {
    this.userRole = localStorage.getItem("role");
    if (this.userRole === 'ADMIN') {
      this.ROLE = true;
    } else {
      this.ROLE = false;
    }
    if (this.mobile === '8076042671') {
      this.MOBILE = true;
    } else {
      this.MOBILE = false;
    }
  }
}
