<div class="container">
  <div class="login-form">
    <h1>Member Login</h1>
    <form name="loginForm" (submit)="submitForm($event)" novalidate>
      <div ng-class="{ 'has-error' : loginForm.name.$invalid && !loginForm.name.$pristine }">
        <input type="number" name="username" placeholder="Username" minlength="10" ng-model="user.username"
          id="username" required />
        <p class="help-block error-text" ng-if="loginForm.username.$error.minlength">It should be 10 digit.</p>
      </div>
      <input type="password" minlength="6" name="password" ng-model="user.password" placeholder="Password" id="password"
        required />
      <p class="help-block error-text" ng-if="loginForm.password.$minlength">Password is too short.</p>
      <button class="login-btn" ng-disabled="loginForm.$invalid">Login</button>
    </form>

    <ul>
      <a href="#" data-target="#forgotPassword" data-toggle="modal">
        Forgot password
      </a>
    </ul>
  </div>
  <div class="img-content">
    <h1>Invest Mango</h1>
    <p>Invest Only to gain Profit</p>
  </div>
</div>

<div class="editUser">
  <!-- Modal -->
  <div class="modal fade" id="forgotPassword" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" #closeBtn>×</button>
          <h4 class="modal-title">Forgot Password</h4>
        </div>
        <div class="modal-body">
          <form name="forgotPasswordForm" (submit)="sendPasswordResetLinkOnEmail($event)">
            <div class="box-body">
              <div class="row">
                <div class="col-xs-12 col-lg-3">
                  <div class="form-group">
                    <label for="">Phone</label>
                    <input type="text" style="text-align: center;" class="form-control" id="phone"
                      placeholder="Enter phone">
                  </div>
                </div>
                <div class="col-xs-12 col-lg-6">
                  <div class="form-group">
                    <label for="">Email</label>
                    <input type="email" style="text-align: center;" class="form-control" id="email"
                      placeholder="Enter Email">
                  </div>
                </div>
                <div class="col-xs-12 col-lg-3">
                  <label>&nbsp;</label>
                  <div class="form-group">
                    <button type="submit" class="btn btn-primary btn-block">Send Link</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div> 
    </div>
  </div>
</div>