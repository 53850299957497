import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';
import {HttpClientModule, HttpResponse } from '@angular/common/http';
import { Users } from 'src/app/model/users.model';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.css']
})
export class UpdateUserComponent implements OnInit {

  user = new Users();
  data : any;

  constructor(private http : HttpClientModule,private auth : AuthService) { }

  id = localStorage.getItem('id');
  mobile = localStorage.getItem('mobile');
  
  ngOnInit(): void {
    this.auth.getProfile(this.mobile).subscribe(res=>{
      this.data=res;
      this.user = this.data;
    })
  }

  updateUser(event) {
    let target = event.target;
    var arrObj = {};

    arrObj['id'] = this.id;
    if (target.querySelector('#fName').value != null) {
      arrObj["firstName"] = target.querySelector('#fName').value;
    } else {
      arrObj["firstName"] = null;
    }
    if (target.querySelector('#lName').value != null) {
      arrObj["lastName"] = target.querySelector('#lName').value;
    } else {
      arrObj["lastName"] = null;
    }
    if (target.querySelector('#email').value != null) {
      arrObj["email"] = target.querySelector('#email').value;
    }
    if (target.querySelector('#mobile').value != null) {
      arrObj["mobile"] = target.querySelector('#mobile').value;
    }
    if (target.querySelector('#dob').value != null) {
      arrObj["dob"] = target.querySelector('#dob').value;
    }
    if (target.querySelector('#address').value != null) {
      arrObj["address"] = target.querySelector('#address').value;
    }

    this.auth.updateUser(arrObj).subscribe(
      (data: HttpResponse<any>) => {
        alert("Successfully updated.")
        window.location.href = 'crm/updateUser';
      }
    );

  }


}
