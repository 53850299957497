import { Component, OnInit, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { RouterTestingModule } from '@angular/router/testing';
import { DatePipe } from '@angular/common';
import * as moment from 'moment'
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})

@NgModule({
  imports: [
    NgxUiLoaderModule,
    HttpClientModule,
    RouterTestingModule
  ],
  providers: [
    DatePipe
  ]
})
export class AdminComponent implements OnInit {

  constructor(private auth: AuthService, private router: Router) { }

  name = localStorage.getItem('firstName') + " " + localStorage.getItem('lastName');
  address = localStorage.getItem('address');
  leadsCount: number = 0;
  totalLeadsCount: number = 0;
  empCount: number = 0;
  openLeadsCount: number = 0;
  leadsReportCount: number = 0;
  declineLeadsCount: number = 0;
  closeLeadsCount: number = 0;
  meetingCount: number = 0;
  junkLeadsCount: number = 0;
  todatAttendanceCount: number = 0;
  userRole: string;
  mobile: string = localStorage.getItem('mobile');
  ngOnInit() {
    this.userRole = localStorage.getItem("role");
    this.auth.startLoader();
    this.tokenChek();
  }

  tokenChek() {
    this.auth.userTokenCheck().subscribe(
      data => {
        if (data['valid']) {
          this.getLeadsCount();
          this.getOpenLeadsCount();
          this.getLeadsReport();
          this.getMeetingCount();
          this.getDeclineLeads();
          this.getCloseLeads();
          this.getTotalLeadsCount();
          this.getEmployeeCount();
          this.getAttendanceCount();
        } else {
          localStorage.clear();
          this.router.navigate(['/'])
          this.auth.stopLoader();
        }
      },
      error => {
        localStorage.clear();
        this.router.navigate(['/'])
        this.auth.stopLoader();
      }
    )
  }

  getLeadsCount() {
    this.auth.getLeadsCount().subscribe(
      data => {
        this.leadsCount = data['count'];
      }
    );
  }

  getTotalLeadsCount() {
    this.auth.getTotalLeadsCount().subscribe(
      data => {
        this.totalLeadsCount = data['count'];;
      }
    );
  }

  getOpenLeadsCount() {
    this.auth.getOpenLeadsCounts().subscribe(
      data => {
        this.openLeadsCount = data['count'];
      }
    );
  }

  getEmployeeCount() {
    this.auth.getUsersCount().subscribe(
      data => {
        this.empCount = data['count'];
      }
    );
  }

  getLeadsReport() {
    this.auth.getLeadsReportsCount().subscribe(
      data => {
        this.auth.stopLoader();
        this.leadsReportCount = data['count'];
      },
      error => {
        this.auth.stopLoader();
      }
    );
  }

  getAttendanceCount() {
    this.auth.getTodayAttendanceList().subscribe(
      data => {
        this.auth.stopLoader();
        this.todatAttendanceCount = data.length;
      },
      error => {
        this.auth.stopLoader();
      }
    );
  }

  getUserMonthAttendanceCount() {
    var id = localStorage.getItem('id');
    const startOfMonth = moment().startOf('month').format('DD-MM-YYYY hh:mm a');
    const endOfMonth = moment().endOf('month').format('DD-MM-YYYY hh:mm a');
    this.auth.getUserMonthAttendanceCount(id, startOfMonth, endOfMonth).subscribe(
      data => {
        this.auth.stopLoader();
        this.todatAttendanceCount = data.length;
      },
      error => {
        this.auth.stopLoader();
      }
    );
  }

  getMeetingCount() {
    var id = localStorage.getItem('id');
    this.auth.getMeetingCount(id).subscribe(
      data => {
        this.auth.stopLoader();
        this.meetingCount = data['count'];
      },
      error => {
        this.auth.stopLoader();
      }
    );
  }

  getDeclineLeads() {
    this.auth.getDeclineLeadsCount().subscribe(
      data => {
        this.declineLeadsCount = data['count'];
      }
    )
  }

  getCloseLeads() {
    this.auth.getCloseLeadsCount().subscribe(
      data => {
        this.closeLeadsCount = data['count'];
      }
    )
  }
}
