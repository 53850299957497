import { Component, OnInit, NgModule } from '@angular/core';
import { HttpClientModule, HttpResponse } from '@angular/common/http';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { RouterTestingModule } from '@angular/router/testing';
import { DatePipe } from '@angular/common';
import { Users } from 'src/app/model/users.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.css']
})

@NgModule({

  imports: [
    NgxUiLoaderModule,
    HttpClientModule,
    RouterTestingModule
  ],
  providers: [
    DatePipe
  ]
})
export class EmployeeDetailsComponent implements OnInit {

  users: Users[] = [];
  usersId: Array<number> = [];
  sorrtedArray: Array<number> = [];

  enableEdit = false;
  enableEditIndex = null;

  showHide: boolean = false;

  selectedUser: Users = null;
  updateSelectedUser: any;
  updateSelectedUserIndex: any;
  
  MOBILE: boolean;
  mobile: string = localStorage.getItem('mobile');

  constructor(private auth: AuthService) { }

  ngOnInit() {

    if (this.mobile === "8076042671") {
      this.MOBILE = true;
    } else {
      this.MOBILE = false;
    }

    this.selectedUser = new Users
    this.usersList();
  }

  onChangeEvent(event, user: any) {
    var obj = {};
    var status = false;
    if (event) {
      status = true;
    }

    obj['id'] = user.id;
    obj['status'] = status;

    this.auth.deleteUser(obj).subscribe(
      data => {
        alert(data['message']);
      },
      error => {
        alert(error.message);
      }
    )
    // if (user.deleted) {
    //   alert("User is already disabled.")
    // } else if (confirm('Are you sure you want to delete this?')) {
    //   alert("OK");
    // }
  }

  usersList() {
    this.auth.startLoader();
    this.auth.getAllActiveUser().subscribe(
      data => {
        this.auth.stopLoader();
        this.users = data;
        for (let user of this.users) {
          this.usersId.push(user.id)
        }
        this.sorrtedArray = this.usersId.sort((n1, n2) => n1 - n2);

      }
    )
  }
  onChange(parentId, userId) {
    this.auth.startLoader();
    var json_arr = {
      'parentId': parentId,
      'userId': userId,
    };
    this.auth.updateParentIdOfUser(json_arr).subscribe(
      (data) => {
        this.auth.stopLoader();
      }
    );
  }

  // enableEditMethod(e, i) {
  //   this.enableEdit = true;
  //   this.enableEditIndex = i;
  // }

  // cancel() {
  //   this.enableEditIndex = null;
  // }

  updateUser(event) {
    let target = event.target;
    var arrObj = {};

    arrObj['id'] = this.selectedUser.id;
    if (target.querySelector('#fName').value != null) {
      arrObj["firstName"] = target.querySelector('#fName').value;
    } else {
      arrObj["firstName"] = null;
    }
    if (target.querySelector('#lName').value != null) {
      arrObj["lastName"] = target.querySelector('#lName').value;
    } else {
      arrObj["lastName"] = null;
    }
    if (target.querySelector('#email').value != null) {
      arrObj["email"] = target.querySelector('#email').value;
    }
    if (target.querySelector('#mobile').value != null) {
      arrObj["mobile"] = target.querySelector('#mobile').value;
    }
    if (target.querySelector('#address').value != null) {
      arrObj["address"] = target.querySelector('#address').value;
    }

    this.auth.updateUser(arrObj).subscribe(
      (data: HttpResponse<any>) => {
        alert("Successfully updated.")
      }
    );
    // var json_arr = {
    //   'id': this.updateSelectedUser,
    //   'firstName': target.querySelector('#firstName').value,
    //   'lastName': target.querySelector('#lastName').value,
    //   'email': target.querySelector('#email').value,
    //   'mobile': target.querySelector('#mobile').value,
    //   'address': target.querySelector('#address').value,
    //   'password': target.querySelector('#password').value,
    // };

  }

  updateUserId(id) {
    this.selectedUser = this.users.find(item => item.id === id);
  }

  showHidePassword() {
    if (this.showHide == false) {
      this.showHide = true;
    } else {
      this.showHide = false;
    }
  }
}

