import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { PasswordResetComponent } from "./password-reset/password-reset.component";

const routes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "password-reset/:token/:user_id/verify", component: PasswordResetComponent },
  {
    path: "admin",
    loadChildren: () =>
      import("./administration/administration.module").then(
        (m) => m.AdministrationModule
      ),
  },
  {
    path: "crm",
    loadChildren: () => import("./crm/crm.module").then((m) => m.CRMModule),
  },
  {
    path: "lead-head",
    loadChildren: () =>
      import("./lead-head/lead-head.module").then((m) => m.LeadHeadModule),
  },
  {
    path: "hr",
    loadChildren: () => import("./hr/hr.module").then((m) => m.HRModule),
  },
  // {
  //   path: 'sales',
  //   loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule)
  // },
  { path: "", redirectTo: "login", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
