import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {

  userId: any;
  token: any;
  isLinkValid: boolean = false;
  msg: string;

  constructor(private auth: AuthService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get("token");
    this.userId = this.route.snapshot.paramMap.get("user_id");
    this.checkTokenAndUserId();
  }

  checkTokenAndUserId() {
    var obj: any = {};
    obj['userId'] = this.userId;
    obj['token'] = this.token;
    this.auth.checkPasswordRestLink(obj).subscribe(
      data => {
        var str: string = data['message']
        if (str.match('success')) {
          this.isLinkValid = true;
        } else {
          this.msg = str;
        }
      },
      error=>{
        this.msg = "Please try again"
      }
    )
  }

  resetPassword(event) {
    var password: string = event.target.querySelector('#password').value;
    var confirmPassword: string = event.target.querySelector('#confirmPassword').value;

    if (password.trim() != "" || confirmPassword.trim() != "") {
      if (password.trim() === confirmPassword.trim()) {
        var obj: any = {};
        obj['userId'] = this.userId;
        obj['token'] = this.token;
        obj['password'] = password;

        this.auth.resetPassword(obj).subscribe(
          data => {
            alert(data.message);
            this.router.navigate(["/"]);
          }
        )
      } else {
        alert('Password not matched');
      }
    } else {
      alert('Please enter the password');
    }

  }

}