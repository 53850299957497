import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Users } from 'src/app/model/users.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-employee-attendance-history',
  templateUrl: './employee-attendance-history.component.html',
  styleUrls: ['./employee-attendance-history.component.css']
})
export class EmployeeAttendanceHistoryComponent implements OnInit {

  users: Users[];
  userAttendanceList: any[]

  lat: any;
  long: any;
  userId: any;

  inputDateFromString: any;
  inputDateToString: any;

  ROLE: boolean;

  constructor(private auth: AuthService) {
  }

  ngOnInit() {
    this.ROLE = localStorage.getItem('role') === 'ADMIN'
    this.userId = 0;
    this.auth.startLoader();
    this.auth.getUsers().subscribe(
      data => {
        this.users = data;
        this.auth.stopLoader();
      }
    );
    if (!this.ROLE) {
      this.auth.getUserAttendanceList(localStorage.getItem('id'), null, null).subscribe(
        data => {
          this.auth.stopLoader();
          this.userAttendanceList = data;
        },
        error => {
          this.auth.stopLoader();
        }
      )
    }
  }

  userLiveLocation() {
    if (this.userId != 0) {
      window.open('/user/google/map')
    } else {
      alert("Please click on attendance first.\nThank you")
    }
  }

  userAttendancde(event) {
    this.auth.startLoader();
    let target = event.target;
    if (localStorage.getItem('role') === 'ADMIN') {
      if (target.querySelector('#agent').value != null) {
        this.userId = target.querySelector('#agent').value;
      } else {
        this.userId = null;
      }
    } else {
      this.userId = localStorage.getItem('id');
    }

    if (target.querySelector('#datefrom').value != null) {
      this.inputDateFromString = target.querySelector('#datefrom').value;
      var datePipe = new DatePipe("en-US");
      this.inputDateFromString = datePipe.transform(this.inputDateFromString, 'dd-MM-yyyy');
    }
    else {
      this.inputDateFromString = null;
    }
    if (target.querySelector('#dateto').value != null) {
      this.inputDateToString = target.querySelector('#dateto').value;
      var datePipe = new DatePipe("en-US");
      this.inputDateToString = datePipe.transform(this.inputDateToString, 'dd-MM-yyyy');
    }
    else {
      this.inputDateToString = null;
    }

    this.auth.getUserAttendanceList(this.userId, this.inputDateFromString, this.inputDateToString).subscribe(
      data => {
        this.auth.stopLoader();
        this.userAttendanceList = data;
      },
      error => {
        this.auth.stopLoader();
      }
    )
  }

  // mapInAttendance(){
  //   var mapProp = {
  //     center: new google.maps.LatLng(18.5793, 73.8143),
  //     zoom: 15,
  //     mapTypeId: google.maps.MapTypeId.ROADMAP
  //   };
  //   this.map = new google.maps.Map(, mapProp);
  // }

}
