<header class="main-header" style="background-color: #262626;margin-top: -76px;position: fixed;width: 100%;">
  <!-- Logo -->
  <a class="logo" style="color: white;font-weight:900;font-size: 26px;    margin-top: 10px;">
    <!-- mini logo for sidebar mini 50x50 pixels -->
    <!-- <span class="logo-mini"><b>Inv</b>est</span> -->
    <!-- logo for regular state and mobile devices -->
    <span class="logo-lg"><b>Invest</b> Mango </span>
  </a>
  <!-- Header Navbar: style can be found in header.less -->
  <nav class="navbar navbar-static-top" style="background: #262626;
  color: white;">
    <!-- Sidebar toggle button-->
    <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button"style="    color: white;">
      <span class="sr-only">Toggle navigation</span>
    </a>

    <div class="navbar-custom-menu">
      <ul class="nav navbar-nav">
        <!-- User Account: style can be found in dropdown.less -->
        <li class="dropdown user user-menu">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <img
              src="./../../assets/dist/img/user.png"
              class="user-image"
              alt="User Image"
            />
            <span class="hidden-xs"style="color: #009688;font-family: time;font-weight: 800;font-size: 18px;">{{ name }}</span>
          </a>
          <ul class="dropdown-menu" >
            <!-- User image -->
            <li class="user-header">
              <img
                src="./../../assets/dist/img/user.png"
                class="img-circle"
                alt="User Image"
              />
              <p style="color:black">{{ name }}</p>
              <p style="color:black;margin-top: -20px;">{{ mobile }}</p>
            </li>
            <!-- Menu Footer-->
            <li class="user-footer" style="background-color: #0c0b0c;">
              <div class="pull-left">
                <a href="crm/profile" class="btn btn-default btn-flat">Profile</a>
              </div>

              <div class="pull-right">
                <a (click)="Logout()" class="btn btn-default btn-flat"
                  >Sign out</a
                >
              </div>
            </li>
          </ul>
        </li>
        <!-- Control Sidebar Toggle Button -->
      </ul>
    </div>
  </nav>
</header>

<div class="addemployee">
  <!-- Modal -->
  <div
    class="modal fade"
    id="addEmployee"
    role="dialog"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">X</button>
          <h4 class="modal-title">Add Employee Form</h4>
        </div>
        <div class="modal-body">
          <form
            name="addEmployeeForm"
            (submit)="saveEmployee($event)"
            ngNativeValidate
          >
            <div class="box-body">
              <div class="row">
                <div class="col-xs-12 col-lg-6">
                  <div class="form-group">
                    <label for="">First Name</label>
                    <input
                      type="text"
                      class="form-control"
                      [required]="true"
                      id="firstName"
                      name="firstName"
                      placeholder="Enter First Name"
                    />
                  </div>
                </div>
                <div class="col-xs-12 col-lg-6">
                  <div class="form-group">
                    <label for="">Last Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="lastName"
                      name="lastName"
                      placeholder="Enter Last Name"
                    />
                  </div>
                </div>
                <div class="col-xs-12 col-lg-6">
                  <div class="form-group">
                    <label for="">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      placeholder="Enter Email"
                    />
                  </div>
                </div>
                <div class="col-xs-12 col-lg-6">
                  <div class="form-group">
                    <label for="">Mobile Number</label>
                    <input
                      type="number"
                      class="form-control"
                      id="mobile"
                      placeholder="Enter Number"
                      required
                    />
                  </div>
                </div>
                <div class="col-xs-12 col-lg-6"></div>
              </div>

              <div class="form-group">
                <label for="">Date Of Birth</label>
                <input
                  type="date"
                  class="form-control"
                  id="dob"
                  placeholder="Enter Date Of Birth"
                />
              </div>
              <div class="form-group">
                <label for="">Address</label>
                <input
                  type="text"
                  class="form-control"
                  id="address"
                  placeholder="Enter Address"
                />
              </div>
              <div class="row">
                <div class="col-lg-6 col-xs-12">
                  <div class="form-group">
                    <label for="sel1">Add Role</label>
                    <select
                      class="form-control"
                      required
                      name="role"
                      (change)="getRoleString($event.target.value)"
                    >
                      <option>Select Role</option>
                      <option
                        *ngFor="let role of roles"
                        name="role"
                        value="{{ role.role }}"
                      >
                        {{ role.role }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-xs-12 col-lg-6">
                  <div class="form-group">
                    <label for="sel1">Add Corporte</label>
                    <select
                      class="form-control"
                      required
                      name="corporate"
                      (change)="getCorporateString($event.target.value)"
                    >
                      <option>Select Corporate</option>
                      <option
                        *ngFor="let corporate of corporates"
                        name="corporate"
                        value="{{ corporate.name }}"
                      >
                        {{ corporate.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-xs-12 col-lg-6">
                  <div class="form-group">
                    <label for="">Password</label>
                    <input
                      type="password"
                      class="form-control"
                      id="password"
                      placeholder="Enter Password"
                      required
                    />
                  </div>
                </div>
                <div class="col-xs-12 col-lg-6">
                  <div class="form-group">
                    <label for="">Confirm Password</label>
                    <input
                      type="password"
                      class="form-control"
                      id="confirmPassword"
                      placeholder="Enter Confirm Password"
                      required
                    />
                  </div>
                </div>
                <div class="col-xs-12">
                  <div class="form-group">
                    <label for="">Gender</label><br />
                    <label class="radio-inline">
                      <input
                        type="radio"
                        name="options"
                        checked="true"
                        value="MALE"
                        [(ngModel)]="genderString"
                      />
                      MALE
                    </label>
                    <label class="radio-inline">
                      <input
                        type="radio"
                        name="options"
                        value="FEMALE"
                        [(ngModel)]="genderString"
                      />
                      FEMALE
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.box-body -->
            <div class="box-footer">
              <button type="submit" class="btn btn-primary btn-block">
                Add Employee
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
