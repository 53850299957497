import { Component, OnInit, NgModule } from "@angular/core";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { RouterTestingModule } from "@angular/router/testing";
import { DatePipe } from "@angular/common";
import { AddLeadComponent } from "../add-lead/add-lead.component";
import { Leads } from "src/app/model/leads.model";
import { Users } from "src/app/model/users.model";
import { AuthService } from "src/app/services/auth.service";
import { Router } from "@angular/router";
import { IDropdownSettings } from "ng-multiselect-dropdown";

@Component({
  selector: "app-total-leads",
  templateUrl: "./total-leads.component.html",
  styleUrls: ["./total-leads.component.css"],
})
export class TotalLeadsComponent implements OnInit {
  active = false;
  leadsArray: Leads[];
  users: Users[];
  selectedIds: Array<any> = [];
  selectedId: Array<any> = [];
  userId: any;
  count = 0;
  itemCount: any;

  onchangeAgentString: any;
  onchangeSourceString: any;

  inputDateFromString: any;
  inputDateToString: any;
  inputProjectNameString: any;
  onchangeStatusString: any;
  sourceString: any[];
  projectNameString: any[];

  userList: Users[];
  callStatus: Array<any> = [];
  status: Array<any> = ["CLOSE"];
  statusCheck: boolean = false;
  callStatusArray: string[];
  callStatusBoolean: boolean = false;
  feedbackUser: any = {};

  projectNameList: Array<any> = [];
  sourceNameList: Array<any> = [];
  selectedProjects: Array<any> = [];
  selectedSource: Array<any> = [];
  selectedEmp: Array<any> = [];
  selectedLeadStatus: Array<any> = [];
  selectedAssignedToList: Array<any> = [];

  dropdownSettings: IDropdownSettings = {};

  rFromString: any;
  rToString: any;
  aFromString: any;
  aToString: any;

  leadsStatus: any[] = [
    { id: 1, name: "Open" },
    { id: 2, name: "Decline" },
    { id: 3, name: "Close" },
  ];

  private json_obj = {
    rFromDate: null,
    rToDate: null,
    aFromDate: null,
    aToDate: null,
    statusList: null,
    assignedToIds: null,
    projectsString: null,
    transferred: "NO",
    sourceString: null,
  };
  activeUsers: any[];
  callStatusFieldCheck: boolean = false;
  isValid: boolean = false;

  public config = {
    itemsPerPage: 20,
    currentPage: 0,
    totalItems: 0,
  };
  private page: number = 1;
  
  mobile: string = localStorage.getItem("mobile");

  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit() {
    this.isValid = localStorage.getItem("isValid") == "YES";

    this.dropdownSettings = {
      singleSelection: false,
      allowSearchFilter: true,
      idField: "id",
      textField: "name",
      itemsShowLimit: 1,
    };

    this.getProjects();

    this.getAgents();

    this.getSources();

    this.auth.startLoader();

    this.getTotalLeadsByPage(this.json_obj);
  }

  getTotalLeadsByPage(jsonObj) {
    this.auth.startLoader();
    this.auth.getTotalLeadsByPageNumber(jsonObj, this.page, 20).subscribe(
      (data) => {
        this.leadsArray = data["content"];
        this.config.totalItems = data["totalElements"];
        this.itemCount = data["totalElements"];
        this.auth.stopLoader();
      },
      (error) => {
        this.auth.stopLoader();
      }
    );
  }

  pageChanged(event) {
    this.page = event;
    this.config.currentPage = event;
    this.getTotalLeadsByPage(this.json_obj);
  }

  onsubmitFilter(event) {
    let formFilter = event.value;

    let projects: Array<any> = [];
    let sources: Array<any> = [];
    let assignedTo: Array<any> = [];
    let status: Array<any> = [];

    if (formFilter.lAssignedTo.length > 0) {
      for (var str of formFilter.lAssignedTo) {
        assignedTo.push(str.id);
      }
    } else {
      assignedTo = [];
    }
    if (formFilter.lStatus.length > 0) {
      for (var str of formFilter.lStatus) {
        status.push(str.name);
      }
    } else {
      status = [];
    }
    if (formFilter.lProject.length > 0) {
      for (var str of formFilter.lProject) {
        projects.push(str.name);
      }
    } else {
      projects = [];
    }
    if (formFilter.lSource.length > 0) {
      for (var str of formFilter.lSource) {
        sources.push(str.name);
      }
    } else {
      sources = [];
    }
   
    this.json_obj = {
      rFromDate: this.auth.toTimestamp(formFilter.rdFrom),
      rToDate: this.auth.toTimestamp(formFilter.rdTo),
      aFromDate: this.auth.toTimestamp(formFilter.adFrom),
      aToDate: this.auth.toTimestamp(formFilter.adTo),
      statusList: status,
      assignedToIds: assignedTo,
      projectsString: projects,
      transferred: "NO",
      sourceString: sources,
    };

    console.log(this.json_obj);

    this.page = 1;
    this.getTotalLeadsByPage(this.json_obj);
  }

  getProjects() {
    this.auth.getProjects().subscribe((data) => {
      var list: Array<any> = [];
      for (let project of data) {
        var obj: any = {};
        obj["id"] = project.id;
        obj["name"] = project.name;
        list.push(obj);
      }
      this.projectNameList = list;
    });
  }

  getSources() {
    this.auth.getSourceString().subscribe((data) => {
      var list: Array<any> = [];
      var id = 0;
      for (let source of data) {
        id = id + 1;
        var obj: any = {};
        obj["id"] = id;
        obj["name"] = source;
        list.push(obj);
      }
      this.sourceNameList = list;
    });
  }

  getAgents() {
    this.auth.getUsers().subscribe((data) => {
      var list: Array<any> = [];
      for (let user of data) {
        var obj: any = {};
        obj["id"] = user.id;
        obj["name"] = user.firstName + " " + user.lastName;
        list.push(obj);
      }
      this.userList = list;
    });
  }

  deleteLeads(leadsId) {
    if (confirm("Are you sure to delete " + leadsId + "?")) {
      this.auth.startLoader();
      this.auth.deleteLeads(leadsId).subscribe(
        (data) => {
          this.auth.stopLoader();
          this.getTotalLeadsByPage(this.json_obj);
          alert(data["message"]);
        },
        (err) => {
          this.auth.stopLoader();
        }
      );
    }
  }
}
