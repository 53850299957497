<div class="content-wrapper " style="background-color: #fff !important;">
  <section class="topmm leads_redesign">
    <div class="container-fluid">
      
        <div class="filter-leads">
          <form role="form" (submit)=saveImportedLeads($event)>
            <div class="row">
            <div class="col-xs-12 col-sm-12 col-lg-3">
              <div class="form-group">
                <label for="sel1">Client Name</label>
                <!-- <select class="form-control" (change)=onchangeAgent($event.target.value)> -->
                <select class="form-control" id="clientName">
                  <option value="">Select Field Name</option>
                  <option *ngFor="let content of fieldName" value="{{content}}">{{content}}</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-lg-3">
              <div class="form-group">
                <label for="sel1">Client Phone</label>
                <!-- <select class="form-control" (change)=onchangeAgent($event.target.value)> -->
                <select class="form-control" id="clientPhone">
                  <option value="">Select Field Name</option>
                  <option *ngFor="let content of fieldName" value="{{content}}">{{content}}</option>
                </select>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-lg-3">
              <div class="form-group">
                <label for="sel1">Client Email</label>
                <!-- <select class="form-control" (change)=onchangeAgent($event.target.value)> -->
                <select class="form-control" id="clientEmail">
                  <option value="">Select Field Name</option>
                  <option *ngFor="let content of fieldName" value="{{content}}">{{content}}</option>
                </select>
              </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-lg-3">
              <div class="form-group">
                <label for="sel1">Query Info</label>
                <!-- <select class="form-control" (change)=onchangeAgent($event.target.value)> -->
                <select class="form-control" id="queryInfo">
                  <option value="">Select Field Name</option>
                  <option *ngFor="let content of fieldName" value="{{content}}">{{content}}</option>
                </select>
              </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-lg-3">
              <div class="form-group">
                <label for="sel1">Project Name</label>
                <!-- <select class="form-control" (change)=onchangeAgent($event.target.value)> -->
                <select class="form-control" id="projectName">
                  <option value="">Select Field Name</option>
                  <option *ngFor="let content of fieldName" value="{{content}}">{{content}}</option>
                </select>
              </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-lg-3">
              <div class="form-group">
                <label for="sel1">Source:</label>
                <!-- <select class="form-control" (change)="onchangeSource($event.target.value)"> -->
                <select class="form-control" id="source">
                  <option value="">Select Field Name</option>
                  <option *ngFor="let content of fieldName" value="{{content}}">{{content}}</option>
                </select>
              </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-lg-3">
              <div class="form-group">
                <label for="sel1">&nbsp;</label>
                <!-- <input type="file" (change)="onFileChange($event)" multiple="false" /> -->
                <div class="custom-file">
                  <input type="file" class="custom-file-input" (change)="onFileChange($event)" multiple="false" id="customFile">
                  <label class="custom-file-label" for="customFile">Choose file</label>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-lg-3">
              <div class="flie_uploade form-group">
                <label for="sel1">&nbsp;</label>
                <button type="submit" class="fib_system_btn_auto_w min-h-44"><i class="fa fa-download" aria-hidden="true"></i> import</button>
              </div>
            </div>
          </div>
          </form>
        </div>
      
    </div>
  </section>
  <!-- partial:index.partial.html -->
  <section class="Leads_manage leads_redesign">
      
        <div class="col-xs-12">
          <div class="table-responsive leads_table_redesign table-fixed">
            <table summary="" class="table table-bordered table-hover table-striped text-center borderStyle" style="background-color: #ecf7fc;">
              <tbody>
                <tr *ngFor="let row of data">
                  <td *ngFor="let val of row">
                    {{val}}
                  </td>
                </tr>
            </table>
          </div>
          <!--end of .table-responsive-->
        </div>
      
  </section>
  <!-- partial -->
</div>