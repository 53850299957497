import { Component, OnInit, NgModule } from '@angular/core';
import { HttpResponse, HttpClientModule, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormArray, FormGroup, FormBuilder, FormControl } from '@angular/forms'
import { from } from 'rxjs';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { RouterTestingModule } from '@angular/router/testing';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-meeting-details',
  templateUrl: './meeting-details.component.html',
  styleUrls: ['./meeting-details.component.css']
})

@NgModule({
  imports: [
    NgxUiLoaderModule,
    HttpClientModule,
    RouterTestingModule,
    HttpClient
  ],
  providers: [
    DatePipe
  ]
})
export class MeetingDetailsComponent implements OnInit {

  onchangeAgentString: any;
  inputDateFromString: any;
  inputDateToString: any;

  ROLE: boolean = false;

  meetingsData: any[];
  users: any[];
  activeUsers: any[];

  private pageSize: number = 20;
  // public config = {
  //   itemsPerPage: 20,
  //   currentPage: 0,
  //   totalItems: 0
  // };
  private page: number = 1;

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {
    this.ROLE = localStorage.getItem('role') === 'ADMIN';
    this.auth.startLoader();
    this.auth.getSalesAndActiveUsers().subscribe(
      data => {
        this.activeUsers = data;
      }
    )

    if (this.ROLE) {
      this.getMeetingDetailsByPage();
    } else {
      this.getAgentTodayMeeting();
    }

  }

  getAgentTodayMeeting() {
    this.auth.getAgentTodayMeeting().subscribe(
      data => {
        this.auth.stopLoader();
        this.meetingsData = data;
      },
      error => {
        this.auth.stopLoader();
      }
    )
  }

  getMeetingDetailsByPage() {
    // this.filterStr = "normal";
    this.auth.getMeetingDetails(this.page).subscribe(
      data => {
        this.auth.stopLoader();
        this.meetingsData = data['content'];
        // this.config.totalItems = data['totalElements'];
      },
      (error) => {
        this.auth.stopLoader();
      }
    );
  }

  activeUser(userList: any[]) {
    for (let user of userList) {
      if (user.deleted) {
        let index = userList.findIndex(x => x.id === user.id)
        userList.splice(index, 1);
      }
    }
    return userList;
  }

  onsubmitFilter(event) {
    this.auth.startLoader();
    event.preventDefault();
    let target = event.target;

    if (target.querySelector('#agent').value != null) {
      this.onchangeAgentString = target.querySelector('#agent').value;
    } else {
      this.onchangeAgentString = null;
    }

    if (target.querySelector('#date').value != null) {
      this.inputDateFromString = target.querySelector('#date').value;
      var datePipe = new DatePipe("en-US");
      this.inputDateFromString = datePipe.transform(this.inputDateFromString, 'dd-MM-yyyy');
    } else {
      this.inputDateFromString = null;
    }

    if (this.inputDateFromString != null || this.onchangeAgentString != null) {

      var json_arr = {
        'startDate': this.inputDateFromString,
        'userId': this.onchangeAgentString,
      };

      this.auth.meetingFilter(json_arr).subscribe(
        data => {
          this.auth.stopLoader();
          this.meetingsData = data;
        }
      )
    } else {
      alert('Please select date or agent name!');
    }
  }
}

