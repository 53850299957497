<div class="content-wrapper leads_redesign" style="background-color: #fff !important;">

  <section class="Leads_manage leads_redesign">


    <!--Start filter form section -->

    <div class="bord">
      <div class="col-xs-12 col-sm-3 col-lg-12 ">
        <div class="form-group">
          <div class="date_filter_tab toggle__btn">
            <p class="" for="usr" value="assigned">Filter :</p>
            <button class="fib_system_btn">
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </button>
          </div>
          <div class="collapse" id="collapsefilters">
            <form #filterForm="ngForm" (submit)="onsubmitFilter(filterForm)">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-xs-12 col-sm-3 col-lg-12 ">
                    <div class="form-group">
                      <div class="date_filter_tab date_filter_btn">
                        <p class="" for="usr" value="assigned">Date Filter :</p>
                        <button class="fib_system_btn" type="button" data-toggle="collapse"
                          data-target="#collapseassigned" aria-expanded="false" aria-controls="collapseExample">
                          <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </button>
                      </div>

                      <div class="form-group collapse label-group " id="collapseassigned">
                        <div class="row">
                          <div class="col-xs-12 col-sm-12 col-lg-12 ">
                            <div class="form-group">
                              <label class="label-text" for="usr" value="receivedon">Recieved Date:</label>

                              <div>
                                <label for="usr">From:</label>
                                <input ngModel name="rdFrom" #rdFrom="ngModel" type="date" class="form-control" />
                                <label for="usr">To:</label>
                                <input ngModel name="rdTo" #rdTo="ngModel" type="date" class="form-control" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-3">
                    <div class="form-group">
                      <label for="sel1">Status:</label>
                      <ng-multiselect-dropdown ngModel name="lStatus" #lStatus="ngModel" class="im_multi"
                        [placeholder]="'Source Name'" [data]="status" [settings]="dropdownSettings">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-3">
                    <div class="form-group">
                      <label for="usr">Agent:</label>
                      <ng-multiselect-dropdown ngModel name="lAgent" #lAgent="ngModel" class="im_multi"
                        [placeholder]="'Projects Name'" [data]="usersList" [settings]="dropdownSettings">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-2">
                    <div class="form-group">
                      <label for="sel1">Page Size:</label>
                      <select class="form-control" (change)="onChangeSize($event.target.value)">
                        <option value="" selected>Select Page Size</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-2">
                    <div class="form-group">
                      <label for="sel1">Total Item founds :</label>
                      <input class="form-control" type="text" value=" {{itemCount}}" disabled />
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-2 d-flex ">
                    <div class="form-group ">
                      <label> &nbsp;</label>
                      <button data-toggle="tooltip" data-placement="top" title="Filter" type="submit"
                        class="fib_system_btn  mr-3">
                        <i class="fa fa-filter" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>

    <!--End filter form section -->

    <div class="col-xs-12">
      <div class="table-responsive leads_table_redesign table-fixed">
        <table summary="" class="table table-bordered table-hover table-striped text-center borderStyle" style="background-color: #ecf7fc;">
          <thead class="borderStyle">
            <tr>
              <th></th>
              <th>Sr. No.</th>
              <th>Leads Id</th>
              <th style="background-color: #ffd75f21">Name</th>
              <th>Email</th>
              <th style="background-color: #b17fad26">Mobile No</th>
              <th *ngIf="ROLE">Source</th>
              <th style="background-color: #0ce2e21f">Project Name</th>
              <th>Received Date</th>
              <th *ngIf="ROLE">Assigned Date</th>
              <th>Update Date</th>
              <th>Query</th>
              <th>Status</th>
              <th *ngIf="ROLE">Feedback</th>
              <th *ngIf="!ROLE">Update Feedback</th>
              <th>Assign Project</th>
            </tr>
          </thead>
          <tbody class="serialNumber">

            <tr class="content" *ngFor="let leads of callLeads | paginate: config">
              <th></th>
              <td></td>
              <td>{{ leads.id }}</td>
              <td style="background-color: #ffd75f21">{{ leads.name }}</td>
              <td>{{ leads.email }}</td>
              <td style="background-color: #b17fad26">{{ leads.phone }}</td>
              <td *ngIf="ROLE">{{ leads.source }}</td>
              <td style="background-color: #0ce2e21f">{{ leads.cmpctLabl }}</td>
              <td *ngIf="ROLE">{{ leads.receivedOn }}</td>
              <td>{{ leads.assignedDate }}</td>
              <td>{{ leads.updateDate }}</td>
              <td style="width: 10%">{{ leads.queryInfo }}</td>
              <td>{{ leads.status }}</td>
              <td *ngIf="ROLE">{{ leads.feedback }}</td>
              <td *ngIf="!ROLE">
                <button class="fib_system_btn_auto_w" value="Feedback" title="Feedback" data-toggle="modal"
                  (click)="leadStatusUpdate(leads, leads.user)" data-target="#feedbackModal">
                  <i class="fa fa-comments-o" aria-hidden="true"></i>
                </button>
              </td>
              <td id="tt" *ngIf="ROLE">
                <select name="users" (change)="assignLead($event.target.value, leads.id)">
                  <option *ngIf="leads.user != null">
                    {{ leads.user.firstName + " " + leads.user.lastName }}
                  </option>
                  >
                  <option *ngIf="leads.user == null" value="0">
                    Select User
                  </option>
                  <option *ngFor="let user of userList" value="{{ user.id }}">
                    {{ user.firstName + " " + user.lastName }}
                  </option>
                </select>
              </td>
              <td *ngIf="!ROLE">
                {{ leads.user.firstName + " " + leads.user.lastName }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--end of .table-responsive-->
      <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
    </div>
  </section>
  <!-- partial -->
</div>

<div class="modal fade" id="feedbackModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal_heading text-center">
        <h3 class="text-primary">Update Feedback</h3>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-12">
            <div class="form-group">
              <label for="usr">Status</label>
              <select name="users" class="form-control" (change)="feedbackStatus($event.target.value)"
                style="width: 100%">
                <option value="">Select Status</option>
                <option value="accepted">Accept</option>
                <option value="rejected">Reject</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-12" *ngIf="statusCheck">
            <div class="form-group">
              <label for="usr">Call Status</label>
              <select id="myCallSelect" name="users" class="form-control"
                (change)="feedbackCallStatus($event.target.value)" style="width: 100%">
                <option value="">Select Call Status</option>
                <option *ngFor="let str of callStatusArray" value="{{ str }}">
                  {{ str }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="callStatusBoolean">
          <div class="col-xs-12 col-sm-12 col-lg-12">
            <form name="feedbackForm" (submit)="saveRemark($event)">
              <div class="form-group" style="width: 100%">
                <label for="">Remark</label>
                <textarea placeholder="Remark please" class="form-control" id="remarkArea" name="remark"></textarea>
              </div>
              <div class="form-group" style="width: 100%" *ngIf="statusCheck">
                <label for="">Choose Date</label>
                <input type="datetime-local" class="form-control" id="chooseDate" placeholder="choose date" required />
              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-primary pull-right">
                  Save Feedback
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>