import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { RouteGuardService } from '../auth/route-guard.service';
import { CommonComponent } from '../hr/common/common.component';
import { AdminComponent } from './admin/admin.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';

const routes: Routes = [
  {
    path: 'hr', component: CommonComponent,
    children: [
      { path: '', component: AdminComponent },
      { path: 'attendance', component: AttendanceComponent, canActivate: [RouteGuardService] },
      { path: 'employeeDetail', component: EmployeeDetailsComponent, canActivate: [RouteGuardService] },
      { path: 'hr', redirectTo: 'hr', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  declarations: [
  ],
  imports: [
    RouterModule.forChild(routes)],
  exports: [
    RouterModule
  ]
})
export class HRRoutingModule { }
