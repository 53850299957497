import { Component, OnInit } from '@angular/core';
import { AuthService } from "src/app/services/auth.service";


@Component({
  selector: 'app-mark-attendance',
  templateUrl: './mark-attendance.component.html',
  styleUrls: ['./mark-attendance.component.css'],
 
})
export class MarkAttendanceComponent implements OnInit {
  user_id: any; 
  users: any; 

  constructor(private auth: AuthService) { }

  ngOnInit() {
    this.user_id = localStorage.getItem('id');
  }

  markAttendance() {
    this.auth.markAttendance(this.user_id).subscribe(
      (data) => {
        this.users = data;
        alert(data.message);
        window.location.href = "crm/attendance";
      },
      (error) => {
        console.error(error.error.message);
        alert(JSON.stringify(error.error.message));
        window.location.reload();
      }
    );
  }

  updateAttendance() {
    this.auth.updateAttendance(this.user_id).subscribe(
      (data) => {
        this.users = data;
        alert(data.message);
        window.location.href = "crm/attendance";
      },
      (error) => {
        console.error(error.error.message);
        alert(JSON.stringify(error.error.message));
        window.location.reload();
      }
    );
  }

 
  
 
}
