<div class="content-wrapper" style="min-height: 906px">
  <section class="Leads_manage">
    <div class="bord">
      <div class="user-panel">
        <div class="pull-left image">
          <img
            src="./../../assets/dist/img/user.png"
            class="img-circle"
            alt="User Image"
          />
        </div>
        <div class="pull-left info">
          <p>{{ name }}</p>
          <p>{{ dateAndTime }}</p>
        </div>
      </div>
      <agm-map
        class="sebm-google-map-container"
        [latitude]="lat"
        [longitude]="long"
        [zoom]="15"
        [mapTypeControl]="true"
      >
        <agm-marker [latitude]="lat" [longitude]="long"> </agm-marker>
      </agm-map>
    </div>
  </section>
</div>
