import { Role } from './role.model';
import { Corporate } from './corporate.model';

export class Users {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    address: string;
    gender: string;
    dob: string;
    password: string;
    active: boolean;
    deleted: boolean;
    emailVerified: boolean;
    parentId: number;
    role: {
        role: string;
    }
    corporate: {
        corporate: string;
    }

}
