<div class="content-wrapper">
  <section class="Leads_manage leads_redesign">

    <!--Start filter form section -->

    <div class="bord">
      <div class="col-xs-12 col-sm-3 col-lg-12 ">
        <div class="form-group">
          <div class="date_filter_tab toggle__btn">
            <p class="" for="usr" value="assigned">Filter :</p>
            <button class="  fib_system_btn">
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </button>
          </div>
          <div class="collapse" id="collapsefilters">
            <form #filterForm="ngForm" (submit)="userAttendanceFilter(filterForm)">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-lg-3">
                    <div class="form-group">
                      <label for="usr">Date From:</label>
                      <input ngModel name="dFrom" #dFrom="ngModel" type="date" class="form-control" />
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-3">
                    <div class="form-group">
                      <label for="usr">Date To:</label>
                      <input ngModel name="dTo" #dTo="ngModel" type="date" class="form-control" />
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-2 d-flex ">
                    <div class="form-group ">
                      <label> &nbsp;</label>
                      <button type="submit" class="fib_system_btn  mr-3">
                        <i class="fa fa-filter" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!--End filter form section -->


    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-lg-12">
          <div class="in_time_location">
            <div class="table-responsive table-fixed table-bg">
              <table class="table table-bordered table-hover table-striped text-center">
                <thead>
                  <tr>
                    <th style="width: 5%">Sn.</th>
                    <th style="width: 10%">Date</th>
                    <th>In Time</th>
                    <th>Out Time</th>
                  </tr>
                </thead>
                <tbody class="serialNumber">
                  <tr *ngFor="let user of userAttendanceList">
                    <td class="td_center"></td>
                    <td class="td_center">{{ user.date }}</td>
                    <td class="td_center">{{ user.inTime | date:"hh:mm a"}}</td>
                    <td class="td_center">{{ user.outTime | date:"hh:mm a" }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>