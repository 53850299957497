import { HttpClientModule,HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Users } from 'src/app/model/users.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit {

  user = new Users();
  data : any;

  constructor(private auth : AuthService,private http : HttpClientModule) { }
  
  id = localStorage.getItem('id');
  mobile = localStorage.getItem('mobile');

  ngOnInit(): void {
    this.auth.getProfile(this.mobile).subscribe(res=>{
      this.data=res;
      this.user = this.data;
    })
  }

  uPassword(event) {
    let target = event.target;
    var arrObj = {};
    arrObj['id'] = this.id;
    if (target.querySelector('#mobile').value != null) {
      arrObj["mobile"] = target.querySelector('#mobile').value;
    } 
    if (target.querySelector('#password').value != null) {
      arrObj["password"] = target.querySelector('#password').value;
    }
    if (target.querySelector('#confirmPassword').value != null) {
      arrObj["confirmPassword"] = target.querySelector('#confirmPassword').value;
    }

    if(target.querySelector('#confirmPassword').value == target.querySelector('#password').value)
    {
    this.auth.uPassword(this.id,arrObj).subscribe(
      (data: HttpResponse<any>)=> {
        this.data = data;
        alert("Password Updated Successfully.");
        window.location.href = 'crm/updatePassword';
        alert("Password Updated Successfully.");
      }
    );
    }
    else
    {
      alert("Incorrect password")
      // window.location.href = 'crm/updatePassword';
    }
  }

}
