import { Component, OnInit } from "@angular/core";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { AuthService } from "../services/auth.service";

@Component({
  selector: "app-fib-system",
  templateUrl: "./fib-system.component.html",
  styleUrls: ["./fib-system.component.css"],
})
export class FibSystemComponent implements OnInit {
  salesHeadList: Array<any> = [];
  salesMemberList: Array<any> = [];
  userList: Array<any> = [];
  dropdownSettings: IDropdownSettings = {};
  projectList: Array<any> = [];
  sourceList: Array<any> = [];

  selectedSalesHead: Array<any> = [];
  selectedSalesMember: Array<any> = [];
  selectedProjects: Array<any> = [];
  selectedSource: Array<any> = [];

  constructor(private auth: AuthService) {}

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: true,
      allowSearchFilter: true,
      idField: "id",
      textField: "name",
      itemsShowLimit: 1,
    };
    this.getAgents();
    this.getProjects();
    this.getSources();
  }

  saveCustomerRecord(data) {
    console.log(data.value);

    let customer = {};
    let flat = {};
    let booking = {};
    if (data.valid) {
      let formData = data.value;
      customer["name"] = formData.cName;
      customer["number"] = formData.cPhone;
      customer["email"] = formData.cEmail;
      customer["panCard"] = formData.cPanCard;
      customer["dob"] = this.auth.toTimestamp(formData.cDob);
      customer["anniversaryDate"] = this.auth.toTimestamp(formData.cAnniDate);
      customer["source"] = formData.cSource[0].name;
      customer["salesTeamId"] = formData.cSalesMember[0].id;
      customer["salesHeadId"] = formData.cSalesHead[0].id;

      flat["unitId"] = formData.fUnitId;
      flat["projectName"] = formData.cProject[0].name;
      flat["city"] = formData.fLocCity;
      flat["state"] = formData.fLocState;
      flat["pinCode"] = formData.fLocPinCode;
      flat["size"] = formData.fSize;
      flat["cost"] = formData.fCost;

      booking["paymentPlan"] = formData.fPaymentPlan;
      booking["bookingAmount"] = formData.bAmount;
      booking["tenPercentAmountStatus"] = formData.bAmountStatus;
      booking["bookingDate"] = this.auth.toTimestamp(formData.bDate);
      booking["disbursementDate"] = this.auth.toTimestamp(
        formData.bDisbursDate
      );

      flat["booking"] = booking;

      customer["flat"] = flat;

      console.log("customer details : " + customer);

      this.auth.saveCustomer(customer).subscribe(
        (data) => {
          console.log(data);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      alert("All the fields are required in form");
    }
  }

  getAgents() {
    this.auth.getUsers().subscribe((data) => {
      var list: Array<any> = [];
      for (let user of data) {
        var obj: any = {};
        obj["id"] = user.id;
        obj["name"] = user.firstName + " " + user.lastName;
        list.push(obj);
      }
      this.userList = list;
    });
  }

  getProjects() {
    this.auth.getProjects().subscribe((data) => {
      var list: Array<any> = [];
      for (let project of data) {
        var obj: any = {};
        obj["id"] = project.id;
        obj["name"] = project.name;
        list.push(obj);
      }
      this.projectList = list;
    });
  }

  getSources() {
    this.auth.getSource().subscribe((data) => {
      var list: Array<any> = [];
      var id = 0;
      for (let source of data) {
        id = id + 1;
        var obj: any = {};
        obj["id"] = source.id;
        obj["name"] = source.name;
        list.push(obj);
      }
      this.sourceList = list;
    });
  }
}
