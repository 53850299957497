<div class="content-wrapper">
  <section class="Leads_manage leads_redesign">

    <!--Start filter form section -->

    <div class="bord" style="display: flex;">
      <div class="col-xs-12 col-sm-3 col-lg-8">
        <div class="form-group">
          <div class="date_filter_tab toggle__btn">
            <p class="" for="usr" value="assigned">Filter :</p>
            <button class="  fib_system_btn">
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </button>
          </div>
          <div class="collapse" id="collapsefilters">
            <form #filterForm="ngForm" (submit)="userAttendanceFilter(filterForm)">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-lg-3">
                    <div class="form-group">
                      <label for="usr">Date From:</label>
                      <input ngModel name="dFrom" #dFrom="ngModel" type="date" class="form-control" />
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-3">
                    <div class="form-group">
                      <label for="usr">Date To:</label>
                      <input ngModel name="dTo" #dTo="ngModel" type="date" class="form-control" />
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-3">
                    <div class="form-group">
                      <label for="usr">Employee</label>
                      <ng-multiselect-dropdown ngModel name="emp" #emp="ngModel" class="im_multi"
                        [placeholder]="'Leads Assigned To'" [data]="userList" [settings]="dropdownSettings">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-2 d-flex ">
                    <div class="form-group ">
                      <label> &nbsp;</label>
                      <button type="submit" class="fib_system_btn  mr-3">
                        <i class="fa fa-filter" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div class="form-group ">
                      <label> &nbsp;</label>
                      <button class="fib_system_btn  mr-3" (click)="userLiveLocation()">
                        <i class="fa fa-location-arrow" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div class="form-group" *ngIf="isClickedOnAttendance">
                      <label for="sel1">&nbsp;</label>
                      <button class="fib_system_btn  mr-3" (click)="exportAttendance()">
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-xs-4 col-lg-4" style="display: flex;  gap: 10px;">

        <form (ngSubmit)="onSubmit()" style="display: flex; align-items: center; gap: 10px;    margin-top: -34px;">
          <label for="selectedDate" style="margin-right: 10px;">Select Date:</label>
          <input type="date" id="selectedDate" name="selectedDate" [(ngModel)]="selectedDate" style="padding: 8px; 
          font-size: 14px; " required>
          <button type="submit" class="download-button" style="    height: 38px;">Download</button>
        </form>
      
        <button (click)="downloadexcel()" class="download-button" style="height: 37px;width: 50px;margin-top: 5px;">
          <i class="fa fa-download" aria-hidden="true"></i>
        </button>
      
      </div>
      


    </div>

    <!--End filter form section -->

    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-lg-12">
          <div class="in_time_location">
            <div class="table-responsive table-fixed table-bg">
              <table class="
                  table table-bordered table-hover table-striped
                  text-center
                ">
                <thead>
                  <tr>
                    <th rowspan="2" style="width: 5%">Sn.</th>
                    <th rowspan="2" style="width: 5%" *ngIf="!isClickedOnAttendance">Name.</th>
                    <th rowspan="2" style="width: 10%">Date</th>
                    <!-- <th colspan="2">In Time</th>
                    <th colspan="2">Out Time</th> -->
                  </tr>
                  <tr>
                    <th style="width: 10%">In Time</th>
                    <th>Location</th>
                    <th style="width: 10%">Out Time</th>
                    <th>Location</th>
                  </tr>
                </thead>
                <tbody class="serialNumber">
                  <tr *ngFor="let user of userAttendanceList">
                    <td class="td_center"></td>
                    <!-- <td class="td_center">{{ (user.user.firstName?user.user.firstName:"")+" "+(user.user.lastName?user.user.lastName:"") }}</td> -->
                    <td class="td_center" *ngIf="!isClickedOnAttendance">
                      {{ user.user.firstName + " " + user.user.lastName }}
                      <br /><br /><br /><a (click)="liveLocation(user.user.id)"><img
                          src="./../../assets/dist/img/curr-loc-icon.png" style="width: 40px; height: 40px" /></a>
                    </td>
                    <td class="td_center">{{ user.date }}</td>
                    <td class="td_center" *ngIf="user.inTime">
                      {{ user.inTime | date: "hh:mm a" }}
                    </td>
                    <td class="td_center" *ngIf="!user.inTime"></td>
                    <td style="height: 150px">
                      <agm-map style="width: 100%; height: 100%" [latitude]="user.inLat" [longitude]="user.inLong"
                        [zoom]="15" [mapTypeControl]="true">
                        <agm-marker [latitude]="user.inLat" [longitude]="user.inLong">
                        </agm-marker>
                      </agm-map>
                    </td>
                    <td class="td_center" *ngIf="user.outTime">
                      {{ user.outTime | date: "hh:mm a" }}
                    </td>
                    <td class="td_center" *ngIf="!user.outTime"></td>

                    <td style="height: 150px">
                      <agm-map style="width: 100%; height: 100%" [latitude]="user.outLat" [longitude]="user.outLong"
                        [zoom]="15" [mapTypeControl]="true">
                        <agm-marker [latitude]="user.outLat" [longitude]="user.outLong">
                        </agm-marker>
                      </agm-map>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>