import { Component, OnInit, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { RouterTestingModule } from '@angular/router/testing';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Leads } from 'src/app/model/leads.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-add-lead',
  templateUrl: './add-lead.component.html',
  styleUrls: ['./add-lead.component.css']
})

@NgModule({
  declarations: [AddLeadComponent],
  imports: [HttpClientModule, RouterTestingModule],
  providers: []
})
export class AddLeadComponent implements OnInit {

  showMsg: boolean = false;
  modalVisible: boolean = false;
  sourceString: any[];
  leadsArray: Leads[];
  onchangeSourceString: any;
  leads: Leads = new Leads();
  inputProjectNameString: any;
  projectNameString: any[];

  selectedProjects: Array<any> = [];
  selectedSource: Array<any> = [];
  projectNameList: Array<any> = [];
  sourceNameList: Array<any> = [];

  dropdownSettings: IDropdownSettings = {};
  
  


  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {
    
    this.dropdownSettings = {
      singleSelection: true,
      allowSearchFilter: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
    };

    this.getProjects();
    this.getSources();

  }

  leadsBySource(sourceString) {    
  }

  getProjects() {
    this.auth.getProjects().subscribe(
      data => {
        var list: Array<any> = [];
        for (let project of data) {
          var obj: any = {};
          obj['id'] = project.id;
          obj['name'] = project.name;
          list.push(obj);
        }
        this.projectNameList = list;
      }
    )
  }

  getSources() {
    this.auth.getSourceString().subscribe(
      data => {
        var list: Array<any> = [];
        var id = 0;
        for (let source of data) {
          id = id + 1;
          var obj: any = {};
          obj['id'] = id;
          obj['name'] = source;
          list.push(obj);
        }
        this.sourceNameList = list;
      }
    )
  }

  saveLeads(addLead) {
    this.auth.startLoader()
    this.leads.name = addLead.value.cName;
    this.leads.email = addLead.value.email;
    this.leads.phone = addLead.value.mobile;
    this.leads.queryInfo = addLead.value.query;
    this.leads.projectName = this.selectedProjects[0].name;
    this.leads.source = this.selectedSource[0].name;
    console.log("leads", this.leads);
    this.auth.addLeads(this.leads).subscribe(
      data => {
        this.auth.stopLoader();
        this.router.navigate(['/admin/lead'])
        alert(data['message']);
      },
      error => {
        this.auth.stopLoader();
        this.router.navigate(['/admin/lead'])
        alert(error['message']);
      }
    );
  }


  onProjectSelect(item: any) {
    var projObj = {};
    projObj['id'] = item.id;
    projObj['name'] = item.name;
    this.selectedProjects.push(projObj);
  }

  onProjectDeSelect(item: any) {
    let index = this.selectedProjects.findIndex(x => x['id'] === item.id)
    this.selectedProjects.splice(index, 1);
  }

  onSelectAllProject(items: any) {
    for (var item of items) {
      var projObj = {};
      projObj['id'] = item.id;
      projObj['name'] = item.name;
      this.selectedProjects.push(projObj);
    }
  }

  onDeSelectAllProject(items: any) {
    this.selectedProjects = [];
  }

  //Source

  onSourceSelect(item: any) {
    var projObj = {};
    projObj['id'] = item.id;
    projObj['name'] = item.name;
    this.selectedSource.push(projObj);
  }

  onSourceDeSelect(item: any) {
    let index = this.selectedSource.findIndex(x => x['id'] === item.id)
    this.selectedSource.splice(index, 1);
  }

  onSelectAllSource(items: any) {
    for (var item of items) {
      var projObj = {};
      projObj['id'] = item.id;
      projObj['name'] = item.name;
      this.selectedSource.push(projObj);
    }
  }

  onDeSelectAllSource(items: any) {
    this.selectedSource = [];
  }



}
