import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Leads } from "src/app/model/leads.model";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-junk-leads",
  templateUrl: "./junk-leads.component.html",
  styleUrls: ["./junk-leads.component.css"],
})
export class JunkLeadsComponent implements OnInit {
  active = false;
  leadsArray: Leads[];
  selectedIds: Array<any> = [];
  selectedId: Array<any> = [];
  userId: any;
  count = 0;

  onchangeAgentString: any;
  onchangeSourceString: any;

  inputDateFromString: any;
  inputDateToString: any;
  inputProjectNameString: any;
  onchangeStatusString: any;
  sourceString: any[];
  projectNameString: any[];

  public config = {
    itemsPerPage: 20,
    currentPage: 0,
    totalItems: 0,
  };
  private page: number = 1;
  private pages: Array<number>;
  private filterStr: any;
  private json_arr = {
    startDate: null,
    endDate: null,
    projectName: null,
    source: null,
  };

  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit() {
    this.auth.startLoader();
    this.auth.getSourceStringOfDeletedLeads().subscribe((data) => {
      this.sourceString = data;
    });
    this.auth.getProjectNameStringOfDeletedLeads().subscribe((data) => {
      this.projectNameString = data;
    });
    this.getFilteredTotalLeadsByPage(this.json_arr);
  }

  pageChanged(event) {
    this.page = event;
    this.config.currentPage = event;
    this.getFilteredTotalLeadsByPage(this.json_arr);
  }

  getFilteredTotalLeadsByPage(jsonObj) {
    this.auth.filterTotalDeletedLeadsByPage(jsonObj, this.page).subscribe(
      (data) => {
        this.leadsArray = data["content"];
        this.config.totalItems = data["totalElements"];
        this.auth.stopLoader();
      },
      (error) => {
        this.auth.stopLoader();
      }
    );
  }

  onsubmitFilter(event) {
    this.auth.startLoader();
    this.page = 1;
    this.filterFieldsData(event);
    this.getFilteredTotalLeadsByPage(this.json_arr);
  }

  private filterFieldsData(event: any) {
    let target = event.target;
    if (target.querySelector("#source").value != null) {
      this.onchangeSourceString = target.querySelector("#source").value;
    } else {
      this.onchangeSourceString = null;
    }
    if (target.querySelector("#datefrom").value != null) {
      this.inputDateFromString = target.querySelector("#datefrom").value;
      var datePipe = new DatePipe("en-US");
      this.inputDateFromString = datePipe.transform(
        this.inputDateFromString,
        "dd-MM-yyyy"
      );
    } else {
      this.inputDateFromString = null;
    }
    if (target.querySelector("#dateto").value != null) {
      this.inputDateToString = target.querySelector("#dateto").value;
      var datePipe = new DatePipe("en-US");
      this.inputDateToString = datePipe.transform(
        this.inputDateToString,
        "dd-MM-yyyy"
      );
    } else {
      this.inputDateToString = null;
    }
    if (target.querySelector("#projectName").value != null) {
      this.inputProjectNameString = target.querySelector("#projectName").value;
    } else {
      this.inputProjectNameString = null;
    }
    this.json_arr = {
      startDate: this.inputDateFromString,
      endDate: this.inputDateToString,
      projectName: this.inputProjectNameString,
      source: this.onchangeSourceString,
    };
  }
}
