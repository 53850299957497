import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { from } from 'rxjs';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';

declare function closeLeadAssignModel(): any;
declare function closeCallLeadFeedbackModel(): any;

@Component({
  selector: 'app-calling-data',
  templateUrl: './calling-data.component.html',
  styleUrls: ['./calling-data.component.css']
})
export class CallingDataComponent implements OnInit {

  str: any = null;
  callLeads: any[];
  users: Array<any> = [];
  userList: any = [];

  selectedIds: Array<any> = [];
  selectedId: Array<any> = [];
  userId: any;


  ROLE: boolean;
  userRole: string;


  dropdownSettings: IDropdownSettings = {};
  page: number = 1;
  private pageSize: number = 20;
  inputDateFromString: any;
  inputDateToString: any;
  inputProjectNameString: any;
  onchangeAgentString: any;

  public config = {
    itemsPerPage: 20,
    currentPage: 0,
    totalItems: 0
  };

  acceptCallStatus: string[] = new Array("Followup", "Meeting Planned", "Visit Planned", "Not Picked", "Callback", "Visited");
  // rejectCallStatus: string[] = new Array("Not Interested", "Invalid Number", "Already Purchased");
  leadsStatus: string = "";
  callStatus: string = "";
  statusCheck: boolean = false;
  callStatusArray: string[];

  callStatusBoolean: boolean = false;
  feedbackUser: any = {};

  lead: any = {}


  private json_obj = {
    'startDate': null,
    'endDate': null,
    'userId': null,
    'status': null,
  };

  onchangeStatusString: any;

  constructor(private auth: AuthService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.auth.startLoader();
    this.userRole = localStorage.getItem("role");
    if (this.userRole === 'ADMIN') {
      this.ROLE = true;
    } else {
      this.ROLE = false;
    }
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      // selectAllText: 'Select All',
      // unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      // allowSearchFilter: true
    };

    this.getFilteredCallLeadsByPage(this.json_obj);

    this.auth.getSalesAndActiveUsers().subscribe(
      data => {
        this.userList = data;
      }
    )
  }

  getCallLeads() {
    this.auth.getCallLeads().subscribe(
      data => {
        this.auth.stopLoader();
        this.callLeads = data;
      },
      err => {
        this.auth.stopLoader();
        alert(err);
      }
    )
  }

  onChangeUserDropBoxOfMultipleAssign(user_id) {
    this.userId = user_id;
  }

  onCheck(leadId, event) {
    var arrObj = {};
    arrObj["leadsId"] = leadId;
    if (event.target.checked) {
      this.selectedIds.push(arrObj);
    } else {
      let index = this.selectedIds.findIndex(x => x['leadsId'] === leadId)
      this.selectedIds.splice(index, 1);
    }
  }

  assignMultipleLead() {
    this.auth.startLoader();
    if (this.selectedIds.length > 0) {
      this.auth.assignCallLeads(this.userId, this.selectedIds).subscribe(
        data => {
          this.selectedIds = [];
          this.auth.stopLoader();
          closeLeadAssignModel()
          this.getCallLeads();
          alert(data['message']);
        },
        error => {
          this.auth.stopLoader();
          this.getCallLeads();
          alert(error);
        }
      );
    } else {
      this.auth.stopLoader();
      closeLeadAssignModel();
      alert("Please select leads.")
    }
  }

  declineCallLeads() {
    this.auth.startLoader();
    if (this.selectedIds.length > 0) {
      this.auth.declineCallLeads(this.selectedIds).subscribe(
        data => {
          this.selectedIds = [];
          this.auth.stopLoader();
          this.getCallLeads();
          alert(data['message']);
        },
        error => {
          this.auth.stopLoader();
          this.getCallLeads();
          alert(error);
        }
      );
    } else {
      this.auth.stopLoader();
      alert("Please select leads.")
    }
  }



  assignLead(userId, leadId) {
    let leadsArray = [
      { "leadsId": leadId },
    ];
    this.auth.startLoader();
    this.auth.assignCallLeads(userId, leadsArray).subscribe(
      data => {
        this.auth.stopLoader();
        alert(data['message'])
      },
      error => {
        this.auth.stopLoader();
        alert(error);
      }
    );
  }

  importCallingData() {
    window.open('import/leads/calling')
  }


  onChangeSize(event) {
    this.pageSize = event;
  }

  getFilteredCallLeadsByPage(jsonObj) {
    this.auth.filterCallLeadsByPage(jsonObj, this.page, this.pageSize).subscribe(
      data => {
        this.callLeads = data['content'];
        this.config.totalItems = data['totalElements'];
        this.config.itemsPerPage = this.pageSize
        this.auth.stopLoader();
      },
      (error) => {
        this.auth.stopLoader();
      }
    );
  }

  pageChanged(event) {
    this.auth.startLoader();
    this.page = event;
    this.config.currentPage = event;
    this.getFilteredCallLeadsByPage(this.json_obj)
  }

  onsubmitFilter(event) {

    this.page = 1;
    this.filterFieldsData(event);

    this.getFilteredCallLeadsByPage(this.json_obj)
  }

  private filterFieldsData(event: any) {
    this.auth.startLoader();
    event.preventDefault();
    let target = event.target;
    if (target.querySelector('#agent').value != null) {
      this.onchangeAgentString = target.querySelector('#agent').value;
    }
    else {
      this.onchangeAgentString = null;
    }
    if (target.querySelector('#status').value != null) {
      this.onchangeStatusString = target.querySelector('#status').value;
    }
    else {
      this.onchangeStatusString = null;
    }
    if (target.querySelector('#datefrom').value != null) {
      this.inputDateFromString = target.querySelector('#datefrom').value;
      var datePipe = new DatePipe("en-US");
      this.inputDateFromString = datePipe.transform(this.inputDateFromString, 'dd-MM-yyyy');
    }
    else {
      this.inputDateFromString = null;
    }
    if (target.querySelector('#dateto').value != null) {
      this.inputDateToString = target.querySelector('#dateto').value;
      var datePipe = new DatePipe("en-US");
      this.inputDateToString = datePipe.transform(this.inputDateToString, 'dd-MM-yyyy');
    }
    else {
      this.inputDateToString = null;
    }
    this.json_obj = {
      'startDate': this.inputDateFromString,
      'endDate': this.inputDateToString,
      'status': this.onchangeStatusString,
      'userId': this.onchangeAgentString,
    };
  }


  feedbackStatus(str) {
    this.leadsStatus = str;
    if (str === 'accepted') {
      this.saveAndAssigneCallLeadsToLeads(this.lead, this.feedbackUser['id']);
      this.callStatusArray = this.acceptCallStatus;
      this.callStatusBoolean = false;
    } else if (str === 'rejected') {
      this.callStatusBoolean = true;
      this.statusCheck = false;
      // this.callStatusArray = this.rejectCallStatus;
      // enableStatus();
    } else {
      this.callStatusArray = [];
      this.leadsStatus = null;
      this.callStatusBoolean = false;
      this.statusCheck = false;
      alert('Please select status');
    }
  }

  leadStatusUpdate(obj, userObj) {
    this.lead = obj;
    this.feedbackUser = userObj;
    this.leadsStatus = "";
    this.callStatus = "";
    this.statusCheck = false;
    this.callStatusBoolean = false;
  }

  saveAndAssigneCallLeadsToLeads(leads, userId) {
    this.auth.startLoader()
    this.auth.saveAndAssignLeads(leads, userId).subscribe(
      data => {
        this.auth.stopLoader();
        this.lead = data;
        this.statusCheck = true;
      }
    )
  }

  feedbackCallStatus(str) {
    this.callStatus = str;
    if (this.callStatus != "") {
      this.callStatusBoolean = true;
    } else {
      this.callStatusBoolean = false;
      this.callStatus = null;
      alert('Please select call status');
    }
  }

  saveRemark(event) {
    this.auth.startLoader();
      var date: string = "";
      var remark: string = "";
      let target = event.target;

      var feedbackObj: any = {}

      if (target.querySelector('#remarkArea').value != null) {
        remark = target.querySelector('#remarkArea').value;
      }
      else {
        remark = "";
      }

      if (this.leadsStatus === 'accepted') {
      feedbackObj['message'] = remark;
      feedbackObj['status'] = 'OPEN';
      feedbackObj['callStatus'] = this.callStatus;
      feedbackObj['user'] = this.feedbackUser;

      if (this.leadsStatus == 'accepted') {

        if (this.callStatus != "Visited") {
          if (target.querySelector('#chooseDate').value != null) {
            date = target.querySelector('#chooseDate').value;
            var datePipe = new DatePipe("en-US");
            date = datePipe.transform(date, 'dd-MM-yyyy hh:mm a');
          }
          else {
            date = "";
          }

          if (this.callStatus == "Visit Planned") {
            feedbackObj['visitDate'] = date;
          } else if (this.callStatus == "Callback") {
            feedbackObj['callbackDate'] = date;
          } else if (this.callStatus == "Meeting Planned") {
            feedbackObj['meetingDate'] = date;
          } else if (this.callStatus == "Not Picked") {
            feedbackObj['callbackDate'] = date;
          } else if (this.callStatus == "Followup") {
            feedbackObj['followupDate'] = date;
          }
        } else {
          feedbackObj['visitStatus'] = true;
        }
      }

      if (remark.trim().length > 0) {
        this.auth.saveLeadsFeedback(this.lead['id'], feedbackObj).subscribe(
          data => {
            closeCallLeadFeedbackModel();
            this.updateCallLeadsAndReport(remark);
            this.auth.stopLoader();
          },
          err => {
            this.auth.stopLoader();
            alert("Something wrong try again.");
          }
        )
      } else {
        alert("Remark should not be empty.");
      }
    }else if(this.leadsStatus === 'rejected'){
      this.updateCallLeadsAndReport(remark)
    }
  }

  updateCallLeadsAndReport(remark) {
    var callLeadsObj: any = {};
    callLeadsObj['feedback'] = remark;
    callLeadsObj['status'] = this.leadsStatus;
    callLeadsObj['updateDate'] = new Date().getTime();
    callLeadsObj['user'] = this.feedbackUser;
    callLeadsObj['rejected'] = false;
    callLeadsObj['phone'] = this.lead['phone'];
    this.auth.updateCallLeadsAndReport(callLeadsObj).subscribe(
      data => {
        this.auth.stopLoader();
        closeCallLeadFeedbackModel();
        alert(data['output']);
      },
      err => {
        this.auth.stopLoader();
        closeCallLeadFeedbackModel();
        alert('Something wrong.');
      }
    )
  }

}