<div class="content-wrapper" style="background-color: #fff !important;">
    <section class="Leads_manage leads_redesign">

        <div class="bord">
            <div class="col-xs-12 col-sm-3 col-lg-12 ">
                <div class="form-group">
                    <div class="form-field">
                        <label for="userIds">Select Employee:</label>
                        <form (submit)="onSubmit()">
                            <select id="userIds" name="employeeSelector" [(ngModel)]="user_id"
                                style="background-color: #2d2d2d; color: white;">
                                <option *ngFor="let employee of data" [value]="employee.id">
                                    {{ employee.firstName }} {{ employee.lastName }}
                                </option>
                            </select>
                            <button type="submit" class="button1" style="    margin-left: 447px;  margin-top: 45px;"
                                (click)="onSubmit()">
                                Pending Task </button>
                            <button type="submit" class="button1" style="     margin-top: 45px;" (click)="onUpdate()">
          Performance </button>


                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>