import { Component, OnInit, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse, HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common'
import { NgForm, FormsModule } from '@angular/forms';
import { RouterTestingModule } from '@angular/router/testing';
import { Role } from 'src/app/model/role.model';
import { Corporate } from 'src/app/model/corporate.model';
import { AuthService } from 'src/app/services/auth.service';
import { Users } from 'src/app/model/users.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

@NgModule({
  imports: [
    FormsModule,
    RouterTestingModule,
    HttpClientModule
  ],
  providers: [DatePipe]
})
export class HeaderComponent implements OnInit {

  genderString: string;
  corporateString: string;
  roleString: string;
  roles: Role[];
  corporates: Corporate[];
  user: Users
  
  constructor(private router: Router, private auth: AuthService, private datePipe: DatePipe) { }

  name = localStorage.getItem('firstName') + " " + localStorage.getItem('lastName');
  mobile = localStorage.getItem('mobile');
  userRole: string;
  // success : boolean = true;

  ngOnInit() {
    this.userRole = localStorage.getItem("role");
    this.datePipe = new DatePipe('en-US')
  }

  Logout() {
    localStorage.clear();
    this.router.navigate(['/'])
  }

  saveEmployee(event) {

    this.auth.startLoader();
    let target = event.target;
    let latest_date = this.datePipe.transform(target.querySelector('#dob').value, 'dd-MM-yyyy');

    var pass = target.querySelector('#password').value;
    var cnfPass = target.querySelector('#confirmPassword').value;

    if (this.roleString == null || this.roleString == "") {
      this.auth.stopLoader();
      alert("PLease select user role.")
      return;
    }
    if (this.corporateString == null || this.corporateString == "") {
      this.auth.stopLoader();
      alert("PLease select user corporate.")
      return;
    }
    if (pass != cnfPass) {
      this.auth.stopLoader();
      alert("Password not matched, please check it.")
      return;
    }
    if (this.genderString == null || this.genderString == "") {
      this.auth.stopLoader();
      alert("PLease select user Gender.")
      return;
    }
    var json_arr = {
      'firstName': target.querySelector('#firstName').value,
      'lastName': target.querySelector('#lastName').value,
      'email': target.querySelector('#email').value,
      'mobile': target.querySelector('#mobile').value,
      'address': target.querySelector('#address').value,
      'dob': latest_date,
      'gender': this.genderString,
      'password': pass,
      'roles': [
        {
          'role': this.roleString
        }
      ],
      'corporate': {
        'name': this.corporateString
      }
    };
    this.auth.addEmployee(json_arr).subscribe(
      data => {
        this.auth.stopLoader();
        // this.success = false;
        alert(data['message']);
      },
      err => {
        this.auth.stopLoader();
        alert("Somthing wrong, try again.");
        console.log(err);
      }
    );
  }

  getCorporateString(corporate: any) {
    this.corporateString = corporate;
  }

  getRoleString(role: any) {
    this.roleString = role;
  }

  loadAddEmployeeDependency() {
    this.auth.getRoles().subscribe(
      data => {
        this.roles = data;
      }
    )

    this.auth.getCorporate().subscribe(
      data => {
        this.corporates = data;
      }
    )
  }
}
