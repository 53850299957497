<div class="content-wrapper" style="min-height: 906px">
  <section class="Leads_manage">
    <!-- <div>
      <div class="col-xs-12 col-sm-12 col-lg-12">
        <button class="btn btn-primary toggle__btn" type="button" data-toggle="collapse" data-target="#collapsefilters"
          aria-expanded="false" aria-controls="collapseExample">
          &#x25BC;
        </button>
      </div> -->

    <div class="bord">
      <div class="col-xs-12 col-sm-3 col-lg-12 border1">
        <div class="form-group">
          <div class="collapse" id="collapsefilters">
            <form (submit)="onsubmitFilter($event)">
              <div class="container-fluid">
                <div class="row">
                  <div class="filter-leads">
                    <div class="bord">
                      <div class="col-xs-12 col-sm-3 col-lg-12 border1" *ngIf="ROLE">
                        <div class="form-group">
                          <label class="label-text center" for="usr" value="assigned">Date Filter:</label>
                          <button class="btn btn-primary toggle__btn label-btn" type="button" data-toggle="collapse"
                            data-target="#collapseassigned" aria-expanded="false" aria-controls="collapseExample">
                            &#x25BC;
                          </button>

                          <div class="form-group collapse label-group" id="collapseassigned">

                            <div class="col-xs-12 col-sm-3 col-lg-3 border" *ngIf="ROLE">
                              <div class="form-group">
                                <label class="label-text" for="usr" value="receivedon">Recieved Date:</label>

                                <div>
                                  <label for="usr">From:</label>
                                  <input type="date" class="form-control" id="rFrom" />
                                  <label for="usr">To:</label>
                                  <input type="date" class="form-control" id="rTo" />
                                </div>
                              </div>
                            </div>

                            <div class="col-xs-12 col-sm-3 col-lg-3 border" *ngIf="ROLE">
                              <div class="form-group">
                                <label class="label-text" for="usr" value="assigned">Assigned Date:</label>

                                <div>
                                  <label for="usr">From:</label>
                                  <input type="date" class="form-control" id="aFrom" />
                                  <label for="usr">To:</label>
                                  <input type="date" class="form-control" id="aTo" />
                                </div>
                              </div>
                            </div>


                            <div class="col-xs-12 col-sm-3 col-lg-3 border" *ngIf="ROLE">
                              <div class="form-group">
                                <label class="label-text" for="usr" value="lastmodified">Last Modified Date:</label>

                                <div>
                                  <label for="usr">From:</label>
                                  <input type="date" class="form-control" id="lmFrom" />
                                  <label for="usr">To:</label>
                                  <input type="date" class="form-control" id="lmTo" />
                                </div>
                              </div>
                            </div>

                            <div class="col-xs-12 col-sm-3 col-lg-3 border" *ngIf="ROLE">
                              <div class="form-group">
                                <label class="label-text" for="usr" value="callstatus">Call Status Date:</label>

                                <div>
                                  <label for="usr">From:</label>
                                  <input type="date" class="form-control" id="csFrom" />
                                  <label for="usr">To:</label>
                                  <input type="date" class="form-control" id="csTo" />
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-lg-2">
                      <div class="form-group">
                        <label for="usr">Leads Status</label>
                        <ng-multiselect-dropdown [placeholder]="'Leads Status'" [data]="leadsStatus"
                          [settings]="dropdownSettings" (onSelect)="onLeadsStatusSelect($event)"
                          (onDeSelect)="onLeadsStatusDeSelect($event)" (onSelectAll)="onLeadsStatusSelectAll($event)"
                          (onDeSelectAll)="onLeadsStatusDeSelectAll($event)">
                        </ng-multiselect-dropdown>
                      </div>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-lg-2">
                      <div class="form-group">
                        <label for="usr">Call Status</label>
                        <ng-multiselect-dropdown [placeholder]="'Call Status'" [data]="callStatus"
                          [settings]="dropdownSettings" (onSelect)="onCallStatusSelect($event)"
                          (onDeSelect)="onCallStatusDeSelect($event)" (onSelectAll)="onCallStatusSelectAll($event)"
                          (onDeSelectAll)="onCallStatusDeSelectAll($event)">
                        </ng-multiselect-dropdown>
                      </div>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-lg-2">
                      <div class="form-group">
                        <label for="usr">Leads Assigned To</label>
                        <ng-multiselect-dropdown [placeholder]="'Leads Assigned To'" [data]="userList"
                          [settings]="dropdownSettings" (onSelect)="leadsAssignedToSelect($event)"
                          (onDeSelect)="leadsAssignedToDeSelect($event)"
                          (onSelectAll)="leadsAssignedToSelectAll($event)"
                          (onDeSelectAll)="leadsAssignedToDeSelectAll($event)">
                        </ng-multiselect-dropdown>
                      </div>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-lg-2">
                      <div class="form-group">
                        <label for="usr">Leads Assigned By</label>
                        <ng-multiselect-dropdown [placeholder]="'Leads Assigned by'" [data]="userList"
                          [settings]="dropdownSettings" (onSelect)="leadsAssignedBySelect($event)"
                          (onDeSelect)="leadsAssignedByDeSelect($event)"
                          (onSelectAll)="leadsAssignedBySelectAll($event)"
                          (onDeSelectAll)="leadsAssignedByDeSelectAll($event)">
                        </ng-multiselect-dropdown>
                      </div>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-lg-2" *ngIf="ROLE">
                      <div class="form-group">
                        <label for="sel1">Source:</label>
                        <!-- <select class="form-control" (change)="onchangeSource($event.target.value)"> -->
                        <ng-multiselect-dropdown [placeholder]="'Source Name'" [data]="sourceNameList"
                          [settings]="dropdownSettings" (onSelect)="onSourceSelect($event)"
                          (onDeSelect)="onSourceDeSelect($event)" (onSelectAll)="onSelectAllSource($event)"
                          (onDeSelectAll)="onDeSelectAllSource($event)">
                        </ng-multiselect-dropdown>
                      </div>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-lg-2">
                      <div class="form-group">
                        <label for="usr">Project Name:</label>
                        <ng-multiselect-dropdown [placeholder]="'Projects Name'" [data]="projectNameList"
                          [settings]="dropdownSettings" (onSelect)="onProjectSelect($event)"
                          (onDeSelect)="onProjectDeSelect($event)" (onSelectAll)="onSelectAllProject($event)"
                          (onDeSelectAll)="onSelectAllProject($event)">
                        </ng-multiselect-dropdown>
                      </div>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-lg-2">
                      <div class="button_ss">
                        <button type="submit" class="btn btn-primary btn-block">
                          Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="container-fluid">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-lg-2" *ngIf="ROLE">
                  <div class="form-group">
                    <label for="sel1">&nbsp;</label>
                    <button class="btn btn-primary btn-block" (click)="exportAsXLSX()">
                      Export
                    </button>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-lg-2" *ngIf="ROLE">
                  <div class="form-group">
                    <label for="sel1">&nbsp;</label>
                    <button type="button" class="btn btn-primary btn-block btn-md" data-toggle="modal"
                      data-target="#multipleLeadsAssignModel">
                      Assign Multiple Leads
                    </button>
                  </div>
                  <!-- Modal -->
                  <div class="modal fade" id="multipleLeadsAssignModel" role="dialog" data-keyboard="false"
                    data-backdrop="static">
                    <div class="modal-dialog">
                      <!-- Modal content-->
                      <div class="modal-content">
                        <div class="modal-header">
                          <button type="button" class="close" data-dismiss="modal">
                            ×
                          </button>
                          <h4 class="modal-title">Select Employee</h4>
                        </div>
                        <div class="modal-body">
                          <form role="form" (submit)="assignMultipleLead()">
                            <div class="box-body">
                              <div class="form-group">
                                <label>Select Employee</label>
                                <select class="form-control" (change)="
                              onChangeUserDropBoxOfMultipleAssign(
                                $event.target.value
                              )
                            ">
                                  <option *ngFor="let user of activeUsers" value="{{ user.id }}">
                                    {{ user.firstName + " " + user.lastName }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <!-- /.box-body -->
                            <div class="box-footer">
                              <button type="submit" class="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-lg-2" *ngIf="ROLE">
                  <div class="form-group">
                    <label for="sel1">Page Size.</label>
                    <select name="pageSize" class="form-control" (change)="onChangeSize($event.target.value)">
                      <option value="20">Select Page Size</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-lg-6" *ngIf="ROLE">
                  <div class="form-group">
                    <label for="sel1">&nbsp;</label>
                    <form (submit)="search($event)">
                      <div class="col-xs-12 col-sm-12 col-lg-4" *ngIf="ROLE">
                        <div class="form-group">
                          <input type="text" class="form-control" id="search" name="search" value=""
                            placeholder="Search client name or phone" />
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-lg-2" *ngIf="ROLE">
                        <div class="form-group">
                          <button type="submit" class="btn btn-primary btn-block">
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button class="btn btn-primary toggle__btn label-btn" type="button" data-toggle="collapse"
            data-target="#collapsefilters" aria-expanded="false" aria-controls="collapseExample">
            &#x25BC;
          </button>
        </div>
      </div>
    </div>

    <div class="col-xs-12">
      <div class="table-responsive table-fixed table-bg">
        <table summary="" class="table table-bordered table-hover table-striped text-center borderStyle" style="background-color: #ecf7fc;">
          <thead class="borderStyle">
            <tr>
              <th>SN</th>
              <th>Leads Id</th>
              <th style="width: 200px">
                <label class="form-check-label" id="lable"> Select All </label>
                <input class="form-check-input" type="checkbox" id="parent"
                  (change)="selectAll($event.target.checked)" />
              </th>
              <th>Client Name</th>
              <th *ngIf="isValid">Client Mobile</th>
              <th style="background-color: #ffd75f21">Email</th>
              <th *ngIf="ROLE">Source</th>
              <th>Project Name</th>
              <th>Query Info</th>
              <th style="background-color: #b17fad26">Status</th>
              <th>Call Status</th>
              <th>Recieved On</th>
              <th *ngIf="ROLE">Assigned Date</th>
              <th>Last Update</th>
              <th>Call status Date</th>
              <th>Visit Status</th>
              <th>Virtual Meeting Status</th>
              <th>Closer Date</th>
              <th>Remark</th>
              <th *ngIf="ROLE">Assigned By</th>
              <th>Assigned To</th>
            </tr>
          </thead>
          <tbody class="serialNumber">
            <tr class="content" *ngFor="let report of leadsReport | paginate: config">
              <td></td>
              <td data-toggle="modal" (click)="leadFeedback(report.leads.id, report.user.id)"
                data-target="#leadsfeedback">
                {{ report.leads.id }}
              </td>
              <td>
                <div>
                  <input type="checkbox" id="child_checkbox" (change)="onCheck(report.leads.id, $event)" />
                </div>
              </td>
              <td>{{ report.leads.name }}</td>
              <td *ngIf="isValid">{{ report.leads.phone }}</td>
              <td style="background-color: hsla(45, 100%, 69%, 0.129); width: 10%">
                {{ report.leads.email }}
              </td>
              <td *ngIf="ROLE">{{ report.leads.source }}</td>
              <td>{{ report.leads.cmpctLabl }}</td>
              <td>{{ report.leads.queryInfo }}</td>
              <td style="background-color: #b17fad26">
                {{ report.status }}
              </td>
              <td>{{ report.callStatus }}</td>
              <td *ngIf="ROLE && report.leads.receivedOn != 0">{{ report.leads.receivedOn | date:"dd-MM-yyyy hh:mm a"
                }}
              </td>
              <td *ngIf="ROLE && report.leads.receivedOn == 0"></td>
              <td *ngIf="report.assignLeadsDate != 0">{{ report.assignLeadsDate | date:"dd-MM-yyyy hh:mm a" }}</td>
              <td *ngIf="report.assignLeadsDate == 0"></td>
              <td *ngIf="report.updateDate != 0">{{ report.updateDate | date:"dd-MM-yyyy hh:mm a" }}</td>
              <td *ngIf="report.updateDate == 0"></td>
              <td *ngIf="report.callStatusDate != 0">{{ report.callStatusDate | date:"dd-MM-yyyy hh:mm a"}}</td>
              <td *ngIf="report.callStatusDate == 0"></td>
              <td *ngIf="report.visitStatus" style="color: green">Done</td>
              <td *ngIf="!report.visitStatus" style="color: red">Not Done</td>
              <td *ngIf="report.virtualMeetingStatus" style="color: green">
                Done
              </td>
              <td *ngIf="!report.virtualMeetingStatus" style="color: red">
                Not Done
              </td>
              <td *ngIf="report.expectedCloserDate != 0">{{ report.expectedCloserDate | date:"dd-MM-yyyy hh:mm a" }}
              </td>
              <td *ngIf="report.expectedCloserDate == 0"></td>
              <td>{{ report.message }}</td>
              <td *ngIf="report.assignedBy == null && ROLE">NULL</td>
              <td *ngIf="report.assignedBy != null && ROLE">
                {{
                report.assignedBy.firstName + " " + report.assignedBy.lastName
                }}
              </td>
              <td id="tt">
                <select name="users" (change)="onChange($event.target.value, report.leads.id)">
                  <option *ngIf="report.user != null">
                    {{ report.user.firstName + " " + report.user.lastName }}
                  </option>
                  >
                  <option *ngIf="report.user == null" value="0">
                    Select User
                  </option>
                  <option *ngFor="let user of activeUsers" value="{{ user.id }}">
                    {{ user.firstName + " " + user.lastName }}
                  </option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-sm-6"></div>
        </div>
        <!-- <hr>
            <ngx-paginate [page]="page" [options]="options" (pageChange)='setPage($event)'
              (pageSizeChange)='setPage($event)'>
            </ngx-paginate> -->
        <!-- <pagination-controls (pageChange)="pageChange($event)" class="my-pagination"></pagination-controls>
            <ul class="nav nav-pills">
              <li class="nav-item" *ngFor="let p of pages; let i = index;">
                <a class="nav-link" href="" (click)="setPage(i, $event)">{{i}}</a>
              </li>
            </ul> -->
      </div>
      <!--end of .table-responsive-->
      <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
    </div>
  </section>
  <!-- partial -->
</div>
<div class="leadfeedback">
  <!-- Modal -->
  <div class="modal fade" id="leadsfeedback" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-lg">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">X</button>
          <h4 class="modal-title">Leads Feedback</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xs-12 col-lg-12" *ngIf="!ROLE">
              <h3 class="text-center">Feedback</h3>
              <div>
                <table summary="" class="table table-bordered table-hover table-striped text-center">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Call Status</th>
                      <th>Message</th>
                      <th style="background-color: #b17fad26">Status</th>
                      <th>Time of Feedback</th>
                      <th>Callbak Date</th>
                      <th>Visit Date</th>
                      <th>Meeting Date</th>
                      <th>Lead Id</th>
                      <th>Assigned To</th>
                    </tr>
                  </thead>
                  <tbody class="serialNumber">
                    <tr class="content" *ngFor="let feedback of feedbackList" data-toggle="modal"
                      data-target="#leadsReportmodal">
                      <td></td>
                      <td>{{ feedback.callStatus }}</td>
                      <td>{{ feedback.message }}</td>
                      <td style="background-color: #b17fad26">
                        {{ feedback.status }}
                      </td>
                      <td>{{ feedback.timeOfFeedback }}</td>
                      <td>{{ feedback.callbackDate }}</td>
                      <td>{{ feedback.visitDate }}</td>
                      <td>{{ feedback.meetingDate }}</td>
                      <td>{{ feedback.leads.id }}</td>
                      <td id="tt">
                        {{
                        feedback.user.firstName + " " + feedback.user.lastName
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-xs-12 col-lg-12" *ngIf="ROLE">
              <div class="tabs">
                <div class="tab-2">
                  <label for="tab2-1">Feedback</label>
                  <input id="tab2-1" name="tabs-two" type="radio" checked="checked" />
                  <div>
                    <table summary="" class="table table-bordered table-hover table-striped text-center">
                      <thead>
                        <tr>
                          <th>SN</th>
                          <th>Call Status</th>
                          <th>Message</th>
                          <th style="background-color: #b17fad26">Status</th>
                          <th>Time of Feedback</th>
                          <th>Callbak Date</th>
                          <th>Visit Date</th>
                          <th>Meeting Date</th>
                          <th>Lead Id</th>
                          <th>Assigned To</th>
                        </tr>
                      </thead>
                      <tbody class="serialNumber">
                        <tr class="content" *ngFor="let feedback of feedbackList" data-toggle="modal"
                          data-target="#leadsReportmodal">
                          <td></td>
                          <td>{{ feedback.callStatus }}</td>
                          <td>{{ feedback.message }}</td>
                          <td style="background-color: #b17fad26">
                            {{ feedback.status }}
                          </td>
                          <td>{{ feedback.timeOfFeedback }}</td>
                          <td>{{ feedback.callbackDate }}</td>
                          <td>{{ feedback.visitDate }}</td>
                          <td>{{ feedback.meetingDate }}</td>
                          <td>{{ feedback.leads.id }}</td>
                          <td id="tt">
                            {{
                            feedback.user.firstName +
                            " " +
                            feedback.user.lastName
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="tab-2" *ngIf="ROLE">
                  <label for="tab2-2">Call Record</label>
                  <input id="tab2-2" name="tabs-two" type="radio" />
                  <div>
                    <table summary="" class="table table-bordered table-hover table-striped text-center">
                      <thead>
                        <tr>
                          <th>SN</th>
                          <th>Client Name</th>
                          <th style="background-color: #b17fad26">
                            Agent Name
                          </th>
                          <th>Agent Mobile</th>
                          <th>Date</th>
                          <th>Audio Play</th>
                          <!-- <th>Download</th> -->
                        </tr>
                      </thead>
                      <tbody class="serialNumber">
                        <tr class="content" *ngFor="let recording of recordings" data-toggle="modal"
                          data-target="#leadsReportmodal">
                          <td></td>
                          <td>{{ recording.clientName }}</td>
                          <td style="background-color: #b17fad26">
                            {{ recording.agentName }}
                          </td>
                          <td>{{ recording.agentMobile }}</td>
                          <td>{{ recording.date }}</td>
                          <td>
                            <!-- <a class="btn btn-primary" (click)="downloadSampleCSVFiles(recording.file)">
                              Download File
                            </a> -->

                            <audio id="audio" controls preload="auto">
                              <source id="sourceAudio" src="{{ recording.file }}" type="audio/mp3" />
                            </audio>
                          </td>
                          <!-- <td><i class="fa fa-download" aria-hidden="true"></i></td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>