import { Users } from './users.model';

export class Leads {
    id          :   number;
    queryId     :   string;
    name        :   string;
    queryInfo   :   string;
    email       :   string;
    phone       :   string;
    projectName :   string;
    action      :   string;
    receivedOn  :   string;
    user        :   Users;
    source      :   string;
}