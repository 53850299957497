<div class="content-wrapper" style="background-color: #fff !important;">
    <section class="Leads_manage leads_redesign">
        <!-- <h3 style="text-decoration: underline;">Pending Tasks </h3> -->
        <h3 style="text-decoration: underline;text-align: center;">Total Pending Tasks : {{ numberOfLeads }}</h3>

      
      <div class="col-xs-12">
        <div class="table-responsive table-fixed leads_table_redesign table-bg">
            <table summary="" class="table table-bordered table-hover table-striped text-center borderStyle" style="background-color: #ecf7fc;">
                <thead class="borderStyle">
                    <tr>
                        <th>
                            <!-- <input class="form-check-input" type="checkbox" id="parent" (change)="selectAll($event.target.checked)" /> -->
                        </th>
                        <th>SN</th>
                        <th>Leads Id</th>
                        <th>Client Name</th>
                        <th *ngIf="isValid">Client Mobile</th>
                        <th style="background-color: #ffd75f21">Email</th>
                        <th>Source</th>
                        <th>Project Name</th>
                        <th>Query Info</th>
                        <th style="background-color: #b17fad26">Status</th>
                        <th>Call Status</th>
                        <th>Last Status</th>
                        <th>Recieved On</th>
                        <th>Assigned Date</th>
                        <th>Last Update</th>
                        <th>Call status Date</th>
                        <th>Visit Status</th>
                        <th>Visit Done Date</th>
                        <th>Virtual Meeting Status</th>
                        <th>Remark</th>
                        <th>Created By</th>
                        <th>Assigned By</th>
                        <th>Assigned To</th>
                    </tr>
                </thead>
                <tbody class="serialNumber">
                    <tr class="content" *ngFor="let report of leadsReport">
                        <td>
                            <!-- <input type="checkbox" id="child_checkbox" (change)="onCheck(report.leads.id, $event)" /> -->
                        </td>
                        <td></td>
                        <td data-toggle="modal" (click)="leadFeedback(report.leads.id, report.user.id)" data-target="#leadsfeedback">
                            {{ report.leads.id }}
                        </td>

                        <td>{{ report.leads.name }}</td>
                        <td *ngIf="isValid">{{ report.leads.phone }}</td>
                        <td style="background-color: hsla(45, 100%, 69%, 0.129); width: 10%">
                            {{ report.leads.email }}
                        </td>
                        <td>{{ report.leads.source }}</td>
                        <td>{{ report.leads.projectName }}</td>
                        <td>{{ report.leads.queryInfo }}</td>
                        <td style="background-color: #b17fad26">
                            {{ report.status }}
                        </td>
                        <td>{{ report.callStatus }}</td>
                        <td>{{ report.lastcallstatus }}</td>
                        <td *ngIf="report.leads.receivedOn != 0">
                            {{ report.leads.receivedOn | date: "dd-MM-yyyy hh:mm a" }}
                        </td>
                        <td *ngIf="report.leads.receivedOn == 0"></td>
                        <td *ngIf="report.assignLeadsDate != 0">
                            {{ report.assignLeadsDate | date: "dd-MM-yyyy hh:mm a" }}
                        </td>
                        <td *ngIf="report.assignLeadsDate == 0"></td>
                        <td *ngIf="report.updateDate != 0">
                            {{ report.updateDate | date: "dd-MM-yyyy hh:mm a" }}
                        </td>
                        <td *ngIf="report.updateDate == 0"></td>
                        <td *ngIf="report.callStatusDate != 0">
                            {{ report.callStatusDate | date: "dd-MM-yyyy hh:mm a" }}
                        </td>
                        <td *ngIf="report.callStatusDate == 0"></td>
                        <td *ngIf="report.visitStatus" style="color: green">Done</td>
                        <td *ngIf="!report.visitStatus" style="color: red">Not Done</td>
                        <td>{{report.visitDoneDate ? (report.visitDoneDate | date: "dd-MM-yyyy hh:mm a") : "" }}</td>
                        <td *ngIf="report.virtualMeetingStatus" style="color: green">
                            Done
                        </td>
                        <td *ngIf="!report.virtualMeetingStatus" style="color: red">
                            Not Done
                        </td>
                        <td>{{ report.message }}</td>
                        <td *ngIf="report.leads.createdBy == null"></td>
                        <td *ngIf="report.leads.createdBy != null">
                            {{ report.leads.createdBy.firstName + " " + report.leads.createdBy.lastName }}
                        </td>
                        <td *ngIf="report.assignedBy == null"></td>
                        <td *ngIf="report.assignedBy != null">
                            {{ report.assignedBy.firstName + " " + report.assignedBy.lastName }}
                        </td>
                        <td id="tt">
                            <select name="users" (change)="onChange($event.target.value, report.leads.id)">
              <option *ngIf="report.user != null">
                {{ report.user.firstName + " " + report.user.lastName }}
              </option>
              >
              <option *ngIf="report.user == null" value="0">
                Select User
              </option>
              <option *ngFor="let user of activeUsers" value="{{ user.id }}">
                {{ user.firstName + " " + user.lastName }}
              </option>
            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
    </div>
    </section>
    <!-- partial -->
  </div>
  <!-- Modal -->
  <div class="leadfeedback">
    <!-- Modal -->
    <div class="modal fade" id="leadsfeedback" role="dialog" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-xl">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Leads Feedback</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xs-12 col-lg-12">
                            <div class="tabs">
                                <div class="tab-2">
                                    <!-- <label for="tab2-1">Feedback</label> -->
                                    <!-- <input id="tab2-1" name="tabs-two" type="radio" checked="checked" /> -->
                                    <div>
                                        <table summary="" class="table table-bordered table-hover table-striped text-center">
                                            <thead>
                                                <tr>
                                                    <th>SN</th>
                                                    <th>Lead id</th>
                                                    <th style="background-color: #b17fad26">Status</th>
                                                    <th>Call Status</th>
                                                    <th>Message</th>
                                                    <th>Time of Feedback</th>
                                                    <th>Call Status Date</th>
                                                    <th>Visit Done Date</th>
                                                    <th>Virtual Meeting Done Date</th>
                                                    <th>Assigned to</th>
                                                </tr>
                                            </thead>
                                            <tbody class="serialNumber">
                                                <tr class="content" *ngFor="let feedback of feedbackList" data-toggle="modal" data-target="#leadsReportmodal">
                                                    <td></td>
                                                    <td>{{ feedback.leads.id }}</td>
                                                    <td style="background-color: #b17fad26">
                                                        {{ feedback.status }}
                                                    </td>
                                                    <td>{{ feedback.callStatus }}</td>
                                                    <td>{{ feedback.message }}</td>
                                                    <td *ngIf="feedback.timeOfFeedback">{{ feedback.timeOfFeedback | date: "dd-MM-yyyy hh:mm a" }}
                                                    </td>
                                                    <td *ngIf="!feedback.timeOfFeedback"></td>
                                                    <td *ngIf="feedback.callStatusDate">{{ feedback.callStatusDate | date: "dd-MM-yyyy hh:mm a" }}
                                                    </td>
                                                    <td *ngIf="!feedback.callStatusDate"></td>
                                                    <td *ngIf="feedback.visitDoneDate">{{ feedback.visitDoneDate | date: "dd-MM-yyyy hh:mm a" }}
                                                    </td>
                                                    <td *ngIf="!feedback.visitDoneDate"></td>
                                                    <td *ngIf="feedback.virtualMeetingDoneDate">{{ feedback.virtualMeetingDoneDate | date: "dd-MM-yyyy hh:mm a" }}</td>
                                                    <td *ngIf="!feedback.virtualMeetingDoneDate"></td>
                                                    <td id="tt">
                                                        {{ feedback.user.firstName + " " + feedback.user.lastName }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <!-- <div class="form-group">
                                            <label for="sel1">&nbsp;</label>
                                            <button class="fib_system_btn  mr-3" (click)="exportAsXLSX1()">
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                                        </div> -->
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>