<div class="wrapper">
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
            <h1>
                Dashboard
                <small>Control panel</small>
            </h1>
            <ol class="breadcrumb">
                <li><a href="#"><i class="fa fa-dashboard"></i> Home</a></li>
                <li class="active">Dashboard</li>
            </ol>
        </section>

        <!-- Main content -->
        <section class="content">
            <!-- Small boxes (Stat box) -->
            <div class="row">
                <div class="col-lg-4 col-xs-6">
                    <!-- small box -->
                    <a href="open/leads">
                        <div class="small-box bg-green">
                            <div class="inner">
                                <h3>{{openLeadsCount}}</h3>
                                <p>OPEN LEAD</p>
                            </div>
                            <div class="icon">
                                <i class="fa fa-folder-open-o" aria-hidden="true"></i>
                            </div>
                            <a href="open/leads" class="small-box-footer">More info <i
                                    class="fa fa-arrow-circle-right"></i></a>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 col-xs-6">
                    <!-- small box -->
                    <a href="leads">
                        <div class="small-box bg-aqua">
                            <div class="inner">
                                <h3>{{leadsCount}}</h3>
                                <p>NEW LEADS</p>
                            </div>
                            <div class="icon">
                                <i class="fa fa-calculator" aria-hidden="true"></i>
                            </div>
                            <a class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                        </div>
                    </a>
                </div>
                <!-- ./col -->
                <div class="col-lg-4 col-xs-6" *ngIf='ROLE'>
                    <!-- small box -->
                    <a href="employee/details">
                        <div class="small-box bg-orange">
                            <div class="inner">
                                <h3>{{empCount}}</h3>
                                <p>EMPLOYEE DETAILS</p>
                            </div>
                            <div class="icon">
                                <i class="fa fa-users" aria-hidden="true"></i>
                            </div>
                            <a href="employee/details" class="small-box-footer">More info <i
                                    class="fa fa-arrow-circle-right"></i></a>
                        </div>
                    </a>
                </div>
                <!-- ./col -->
                <div class="col-lg-4 col-xs-6">
                    <!-- small box -->
                    <a href="leads/report">
                        <div class="small-box bg-yellow">
                            <div class="inner">
                                <h3>{{leadsReportCount}}</h3>

                                <p>LEAD REPORT</p>
                            </div>
                            <div class="icon">
                                <i class="fa fa-bar-chart" aria-hidden="true"></i>
                            </div>
                            <a href="leads/report" class="small-box-footer">More info <i
                                    class="fa fa-arrow-circle-right"></i></a>
                        </div>
                    </a>
                </div>

                <div class="col-lg-4 col-xs-6"  *ngIf='ROLE'>
                    <!-- small box -->
                    <a href="decline/leads">
                        <div class="small-box bg-red">
                            <div class="inner">
                                <h3>{{declineLeadsCount}}</h3>

                                <p>DECLINE LEAD</p>
                            </div>
                            <div class="icon">
                                <i class="fa fa-ban" aria-hidden="true"></i>
                            </div>
                            <a href="decline/leads" class="small-box-footer">More info <i
                                    class="fa fa-arrow-circle-right"></i></a>
                        </div>
                    </a>
                </div>

                <div class="col-lg-4 col-xs-6" *ngIf='ROLE'>
                    <!-- small box -->
                    <a href="close/leads">
                        <div class="small-box bg-blue">
                            <div class="inner">
                                <h3>{{closeLeadsCount}}</h3>

                                <p>CLOSED LEAD</p>
                            </div>
                            <div class="icon">
                                <i class="fa fa-power-off" aria-hidden="true"></i>
                            </div>
                            <a href="close/leads" class="small-box-footer">More info <i
                                    class="fa fa-arrow-circle-right"></i></a>
                        </div>
                    </a>
                </div>

                <div class="col-lg-4 col-xs-6" *ngIf='ROLE'>
                    <!-- small box -->
                    <a href="total/leads">
                        <div class="small-box bg-aqua">
                            <div class="inner">
                                <h3>{{totalLeadsCount}}</h3>
                                <p>TOTAL LEADS</p>
                            </div>
                            <div class="icon">
                                <i class="fa fa-calculator" aria-hidden="true"></i>
                            </div>
                            <a class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                        </div>
                    </a>
                </div>

                <div class="col-lg-4 col-xs-6">
                    <!-- small box -->
                    <a href="attendance">
                        <div class="small-box bg-Purple">
                            <div class="inner">
                                <h3>{{todatAttendanceCount}}</h3>
                                <p>ATTENDANCE</p>
                            </div>
                            <div class="icon">
                                <i class="fa fa-bell" aria-hidden="true"></i>
                            </div>
                            <a class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                        </div>
                    </a>
                </div>

                <div class="col-lg-4 col-xs-6">
                    <!-- small box -->
                    <a href="meeting/details">
                        <div class="small-box bg-Purple">
                            <div class="inner">
                                <h3>{{meetingCount}}</h3>
                                <p>MEETINGS</p>
                            </div>
                            <div class="icon">
                                <i class="fa fa-bell" aria-hidden="true"></i>
                            </div>
                            <a class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                        </div>
                    </a>
                </div>
            </div>
            <!-- /.row -->
            <!-- Main row -->
        </section>
        <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->
    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
        <!-- Create the tabs -->
        <ul class="nav nav-tabs nav-justified control-sidebar-tabs">
            <li><a href="#control-sidebar-home-tab" data-toggle="tab"><i class="fa fa-home"></i></a></li>
            <li><a href="#control-sidebar-settings-tab" data-toggle="tab"><i class="fa fa-gears"></i></a></li>
        </ul>
        <!-- Tab panes -->
        <div class="tab-content">
            <!-- Home tab content -->
            <div class="tab-pane" id="control-sidebar-home-tab">
                <h3 class="control-sidebar-heading">Recent Activity</h3>
                <ul class="control-sidebar-menu">
                    <li>
                        <a href="javascript:void(0)">
                            <i class="menu-icon fa fa-birthday-cake bg-red"></i>
                            <div class="menu-info">
                                <h4 class="control-sidebar-subheading">Langdon's Birthday</h4>
                                <p>Will be 23 on April 24th</p>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)">
                            <i class="menu-icon fa fa-user bg-yellow"></i>
                            <div class="menu-info">
                                <h4 class="control-sidebar-subheading">Frodo Updated His Profile</h4>
                                <p>New phone +1(800)555-1234</p>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)">
                            <i class="menu-icon fa fa-envelope-o bg-light-blue"></i>
                            <div class="menu-info">
                                <h4 class="control-sidebar-subheading">Nora Joined Mailing List</h4>
                                <p>nora@example.com</p>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)">
                            <i class="menu-icon fa fa-file-code-o bg-green"></i>
                            <div class="menu-info">
                                <h4 class="control-sidebar-subheading">Cron Job 254 Executed</h4>
                                <p>Execution time 5 seconds</p>
                            </div>
                        </a>
                    </li>
                </ul>
                <!-- /.control-sidebar-menu -->
                <h3 class="control-sidebar-heading">Tasks Progress</h3>
                <ul class="control-sidebar-menu">
                    <li>
                        <a href="javascript:void(0)">
                            <h4 class="control-sidebar-subheading">
                                Custom Template Design
                                <span class="label label-danger pull-right">70%</span>
                            </h4>
                            <div class="progress progress-xxs">
                                <div class="progress-bar progress-bar-danger" style="width: 70%"></div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)">
                            <h4 class="control-sidebar-subheading">
                                Update Resume
                                <span class="label label-success pull-right">95%</span>
                            </h4>
                            <div class="progress progress-xxs">
                                <div class="progress-bar progress-bar-success" style="width: 95%"></div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)">
                            <h4 class="control-sidebar-subheading">
                                Laravel Integration
                                <span class="label label-warning pull-right">50%</span>
                            </h4>
                            <div class="progress progress-xxs">
                                <div class="progress-bar progress-bar-warning" style="width: 50%"></div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)">
                            <h4 class="control-sidebar-subheading">
                                Back End Framework
                                <span class="label label-primary pull-right">68%</span>
                            </h4>
                            <div class="progress progress-xxs">
                                <div class="progress-bar progress-bar-primary" style="width: 68%"></div>
                            </div>
                        </a>
                    </li>
                </ul>
                <!-- /.control-sidebar-menu -->
            </div>
            <!-- /.tab-pane -->
            <!-- Stats tab content -->
            <div class="tab-pane" id="control-sidebar-stats-tab">Stats Tab Content</div>
            <!-- /.tab-pane -->
            <!-- Settings tab content -->
            <div class="tab-pane" id="control-sidebar-settings-tab">
                <form method="post">
                    <h3 class="control-sidebar-heading">General Settings</h3>
                    <div class="form-group">
                        <label class="control-sidebar-subheading">
                            Report panel usage
                            <input type="checkbox" class="pull-right" checked>
                        </label>
                        <p>
                            Some information about this general settings option
                        </p>
                    </div>
                    <!-- /.form-group -->

                    <div class="form-group">
                        <label class="control-sidebar-subheading">
                            Allow mail redirect
                            <input type="checkbox" class="pull-right" checked>
                        </label>

                        <p>
                            Other sets of options are available
                        </p>
                    </div>
                    <!-- /.form-group -->

                    <div class="form-group">
                        <label class="control-sidebar-subheading">
                            Expose author name in posts
                            <input type="checkbox" class="pull-right" checked>
                        </label>
                        <p>
                            Allow the user to show his name in blog posts
                        </p>
                    </div>
                    <!-- /.form-group -->
                    <h3 class="control-sidebar-heading">Chat Settings</h3>
                    <div class="form-group">
                        <label class="control-sidebar-subheading">
                            Show me as online
                            <input type="checkbox" class="pull-right" checked>
                        </label>
                    </div>
                    <!-- /.form-group -->
                    <div class="form-group">
                        <label class="control-sidebar-subheading">
                            Turn off notifications
                            <input type="checkbox" class="pull-right">
                        </label>
                    </div>
                    <!-- /.form-group -->
                    <div class="form-group">
                        <label class="control-sidebar-subheading">
                            Delete chat history
                            <a href="javascript:void(0)" class="text-red pull-right"><i class="fa fa-trash-o"></i></a>
                        </label>
                    </div>
                    <!-- /.form-group -->
                </form>
            </div>
            <!-- /.tab-pane -->
        </div>
    </aside>
    <!-- /.control-sidebar -->
    <!-- Add the sidebar's background. This div must be placed
           immediately after the control sidebar -->
    <div class="control-sidebar-bg"></div>
</div>