import { Component, OnInit, NgModule } from '@angular/core';
import {
  HttpResponse,
  HttpClientModule,
  HttpClient,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { RouterTestingModule } from "@angular/router/testing";
import { DatePipe } from "@angular/common";
import { AddLeadComponent } from "../add-lead/add-lead.component";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { AppModule } from "src/app/app.module";
import { Leads } from "src/app/model/leads.model";
import { Users } from "src/app/model/users.model";
import { AuthService } from "src/app/services/auth.service";

declare function resetDropdown(): any;
declare function enableStatus(): any;
declare function disableStatus(): any;
declare function closeLeadFeedbackModel(): any;

@Component({
  selector: 'app-call-leads',
  templateUrl: './call-leads.component.html',
  styleUrls: ['./call-leads.component.css']
})
@NgModule({
  declarations: [AddLeadComponent],
  imports: [
    NgxUiLoaderModule,
    AppModule,
    HttpClientModule,
    RouterTestingModule,
    HttpClient,
  ],
  providers: [DatePipe],
})
export class CallLeadsComponent implements OnInit {
  active = false;
  leadsArray: Leads[];
  users: Users[];
  activeUsers: Users[];
  selectedIds: Array<any> = [];
  selectedId: Array<any> = [];
  userId: any;
  count = 0;

  dropdownSettings: IDropdownSettings = {};

  sourceString: any[];
  projectNameString: any[];

  userRole: string;

  lead: any = {};

  acceptCallStatus: string[] = new Array(
    "Followup",
    "Callback",
    "Not Picked",
    "Visit Planned",
    "Meeting Planned",
    "Virtual Meeting Planned",
    "Visit Done",
    "Meeting Done",
    "Virtual Meeting Done"
  );

  rejectCallStatus: string[] = new Array(
    "Already Purchased",
    "Budget Issue",
    "Location Issue",
    "Loan Issue",
    "Followup Done, Not Responding",
    "Plan Postponed",
    "Invalid Number",
    "Not Interested"
  );

  leadsStatus: string = "";
  callStatus: string = "";
  statusCheck: boolean = false;
  callStatusArray: string[];

  private json_arr = {
    startDate: null,
    endDate: null,
    projectName: null,
    source: null,
  };

  callStatusBoolean: boolean = false;
  feedbackUser: any = {};

  lable: any;
  inp: any;

  mobile: string = localStorage.getItem("mobile");
  isValid: boolean = false;
  projectNameList: any[];
  sourceNameList: any[];
  selectedProjects: Array<any> = [];
  selectedSource: Array<any> = [];
  itemCount: any;

  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit() {
    this.inp = document.getElementById("parent");
    this.lable = document.getElementById("lable");

    this.dropdownSettings = {
      singleSelection: false,
      allowSearchFilter: true,
      idField: "id",
      textField: "name",
      itemsShowLimit: 1,
    };

    this.getProjects();

    this.getSources();

    this.userRole = localStorage.getItem("role");

    this.isValid = localStorage.getItem("isValid") == "YES";

    this.auth.getSalesAndActiveUsers().subscribe((data) => {
      this.activeUsers = data;
    });

    this.filterLeads(this.json_arr);

    // this.auth.getSourceString().subscribe(
    //   data => {
    //     this.sourceString = data;
    //   }
    // )

    // this.auth.getProjectNameString().subscribe(
    //   data => {
    //     this.projectNameString = data;
    //   }
    // )
  }

  // private getNewLeads() {
  //   this.auth.getLeads().subscribe(
  //     data => {
  //       this.leadsArray = data;
  //       this.itemCount = this.leadsArray.length;
  //     },
  //     error => {

  //       console.log(error);
  //     }
  //   );
  // }

  // activeUser(userList: any[]) {
  //   for (let user of userList) {
  //     if (user.deleted) {
  //       let index = userList.findIndex(x => x.id === user.id)
  //       userList.splice(index, 1);
  //     }
  //   }
  //   return userList;
  // }

  // leadsBySource(sourceString) {
  //   if (sourceString == "all") {
  //     this.auth.getLeads().subscribe(
  //       data => {
  //         this.leadsArray = data;
  //         this.router.navigate['leads'];
  //       }
  //     );
  //   } else {
  //     this.auth.getLeadsBySource(sourceString).subscribe(
  //       data => {
  //         this.leadsArray = data;
  //         this.router.navigate['leads'];
  //       }
  //     );
  //   }
  // }

  onChange(userId, leadsId) {
    this.selectedId = [];
    var arrObj = {};
    arrObj["leadsId"] = leadsId;
    this.selectedId.push(arrObj);
    if (userId != 0) {
      this.auth.assignLeads(userId, this.selectedId).subscribe((data) => {
        alert(data["message"]);
      });
    }
  }
  onChangeUserDropBoxOfMultipleAssign(user_id) {
    this.userId = user_id;
  }

  // onCheck(leadId, event) {
  //   var arrObj = {};
  //   arrObj["leadsId"] = leadId;
  //   if (event.target.checked) {
  //     this.selectedIds.push(arrObj);
  //   } else {
  //     let index = this.selectedIds.findIndex(x => x['leadsId'] === leadId)
  //     this.selectedIds.splice(index, 1);
  //   }
  // }

  spamLeads() {
    if (this.selectedIds.length == 0) {
      alert("Select leads please");
      return;
    }
    this.auth.startLoader();
    this.auth.spamLeads(this.selectedIds).subscribe(
      (data) => {
        this.selectedIds = [];
        this.inp.checked = false;
        this.lable.innerHTML = "Select All";
        this.auth.stopLoader();
        this.filterLeads(this.json_arr);
        alert(data["message"]);
      },
      (error) => {
        this.auth.stopLoader();
        console.log(error);
      }
    );
  }

  onCheck(leadId, event) {
    var arrObj = {};
    arrObj["leadsId"] = leadId;
    if (event.target.checked) {
      this.selectedIds.push(arrObj);
    } else {
      this.inp.checked = false;
      this.lable.innerHTML = "Select All";
      let index = this.selectedIds.findIndex((x) => x["leadsId"] === leadId);
      this.selectedIds.splice(index, 1);
    }
  }

  assignMultipleLead() {
    if (this.selectedIds.length == 0) {
      alert("Select leads please");
      return;
    }
    this.auth.startLoader();
    this.auth.assignLeads(this.userId, this.selectedIds).subscribe(
      (data) => {
        this.auth.stopLoader();
        this.filterLeads(this.json_arr);
        alert(data["message"]);
      },
      (error) => {
        this.auth.stopLoader();
        alert(error);
      }
    );
  }

  assignLead(userId, leadId) {
    let leadsArray = [{ leadsId: leadId }];
    if (leadsArray.length == 0) {
      alert("Select agent please");
      return;
    }
    this.auth.startLoader();
    this.auth.assignLeads(userId, leadsArray).subscribe(
      (data) => {
        this.auth.stopLoader();
        this.filterLeads(this.json_arr);
        alert(data["message"]);
      },
      (error) => {
        this.auth.stopLoader();
        alert(error);
      }
    );
  }

  onsubmitFilter(event) {
    let formFilter = event.value;

    let projects: Array<any> = [];
    let sources: Array<any> = [];

    for (var str of formFilter.lProject) {
      projects.push(str.name);
    }
    for (var str of formFilter.lSource) {
      sources.push(str.name);
    }

    this.json_arr = {
      startDate: this.auth.toTimestamp(formFilter.rdFrom),
      endDate: this.auth.toTimestamp(formFilter.rdTo),
      projectName: projects,
      source: sources,
    };

    this.filterLeads(this.json_arr);
  }

  filterLeads(jsonObj) {
    this.auth.startLoader();
    this.auth.filterLeads1(jsonObj).subscribe(
      (data) => {
        this.auth.stopLoader();
        this.leadsArray = data;
        this.itemCount = this.leadsArray.length;
        this.router.navigate["leads"];
      },
      (err) => {
        this.auth.stopLoader();
        console.log(err);
      }
    );
  }

  feedbackStatus(str) {
    this.leadsStatus = str;
    if (str === "open") {
      this.callStatusBoolean = false;
      this.statusCheck = true;
      this.callStatusArray = this.acceptCallStatus;
      enableStatus();
    } else if (str === "decline") {
      this.callStatusBoolean = false;
      this.statusCheck = false;
      this.callStatusArray = this.rejectCallStatus;
      enableStatus();
    } else {
      this.callStatusArray = [];
      this.leadsStatus = null;
      this.callStatusBoolean = false;
      disableStatus();
      alert("Please select status");
    }
  }

  leadStatusUpdate(obj, userObj) {
    this.lead = obj;
    this.feedbackUser = userObj;
    this.callStatusArray = [];
    this.leadsStatus = null;
    this.callStatus = "";
    this.statusCheck = false;
    this.callStatusBoolean = false;
    resetDropdown();
    disableStatus();
  }

  feedbackCallStatus(str) {
    this.callStatus = str;
    if (this.callStatus != "") {
      if (this.leadsStatus === "open") {
        if (
          this.callStatus === "Visited" ||
          this.callStatus === "Virtual Meeting Done"
        ) {
          this.callStatusBoolean = true;
          this.statusCheck = false;
        } else {
          this.callStatusBoolean = true;
          this.statusCheck = true;
        }
      } else {
        this.callStatusBoolean = true;
        this.statusCheck = false;
      }
    } else {
      this.callStatusBoolean = false;
      this.callStatus = null;
      alert("Please select call status");
    }
  }

  saveRemark(event) {
    var date: string = "";
    var remark: string = "";
    let target = event.target;

    var feedbackObj: any = {};

    if (target.querySelector("#remarkArea").value != null) {
      remark = target.querySelector("#remarkArea").value;
    } else {
      remark = "";
    }

    feedbackObj["message"] = remark;
    feedbackObj["status"] = this.leadsStatus;
    feedbackObj["callStatus"] = this.callStatus;
    feedbackObj["user"] = this.feedbackUser;

    if (this.leadsStatus == "open") {
      if (
        this.callStatus === "Visited" ||
        this.callStatus === "Virtual Meeting Done"
      ) {
        if (this.callStatus === "Visited") {
          feedbackObj["visitStatus"] = true;
        } else if (this.callStatus === "Virtual Meeting Done") {
          feedbackObj["virtualMeetingStatus"] = true;
        }
      } else {
        if (target.querySelector("#chooseDate").value != null) {
          date = target.querySelector("#chooseDate").value;
          var datePipe = new DatePipe("en-US");
          date = datePipe.transform(date, "dd-MM-yyyy hh:mm a");
        } else {
          date = "";
        }

        if (this.callStatus == "Visit Planned") {
          feedbackObj["visitDate"] = date;
        } else if (this.callStatus == "Callback") {
          feedbackObj["callbackDate"] = date;
        } else if (this.callStatus == "Meeting Planned") {
          feedbackObj["meetingDate"] = date;
        } else if (this.callStatus == "Not Picked") {
          feedbackObj["callbackDate"] = date;
        } else if (this.callStatus == "Followup") {
          feedbackObj["followupDate"] = date;
        } else if (this.callStatus == "Virtual Meeting") {
          feedbackObj["virtualMeetingDate"] = date;
        }
      }
    }

    if (remark.trim().length > 0) {
      this.auth.saveLeadsFeedback(this.lead["id"], feedbackObj).subscribe(
        (data) => {
          closeLeadFeedbackModel();

          alert(data["output"]);
        },
        (err) => {
          console.log(err);
          alert("Something wrong try again.");
        }
      );
    } else {
      alert("Remark should not be empty.");
    }
  }

  getProjects() {
    this.auth.getProjects().subscribe((data) => {
      var list: Array<any> = [];
      for (let project of data) {
        var obj: any = {};
        obj["id"] = project.id;
        obj["name"] = project.name;
        list.push(obj);
      }
      this.projectNameList = list;
    });
  }

  getSources() {
    this.auth.getSourceString().subscribe((data) => {
      var list: Array<any> = [];
      var id = 0;
      for (let source of data) {
        id = id + 1;
        var obj: any = {};
        obj["id"] = id;
        obj["name"] = source;
        list.push(obj);
      }
      this.sourceNameList = list;
    });
  }

  selectAll(isChecked: boolean) {
    var input = document.getElementsByTagName("input");
    if (isChecked) {
      this.selectedIds = [];
      for (var i = 0; i < input.length; i++) {
        if (
          input[i].type === "checkbox" &&
          input[i].id === "child_checkbox" &&
          input[i].checked === false
        ) {
          input[i].checked = true;
        }
      }
      for (var i = 0; i < this.leadsArray.length; i++) {
        var obj = {};
        obj["leadsId"] = this.leadsArray[i].id;
        this.selectedIds.push(obj);
      }
      this.lable.innerHTML = "Selected All";
    }
    if (!isChecked) {
      this.selectedIds = [];
      for (var i = 0; i < input.length; i++) {
        var obj = {};
        if (
          input[i].type === "checkbox" &&
          input[i].id === "child_checkbox" &&
          input[i].checked === true
        ) {
          input[i].checked = false;
        }
      }
      this.lable.innerHTML = "Select All";
    }
  }
}


