import { ChartComponent } from './chart/chart.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RouteGuardService } from '../auth/route-guard.service';
// import { MeetingDetailsComponent } from '../crm/meeting-details/meeting-details.component';
import { AdminComponent } from './admin/admin.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { CallingDataComponent } from './calling-data/calling-data.component';
import { CommonComponent } from './common/common.component';
import { LeadReportComponent } from './lead-report/lead-report.component';
import { LeadsComponent } from './leads/leads.component';
import { OpenleadComponent } from './openlead/openlead.component';
import { ProfileComponent } from './profile/profile.component';
import { UpdateUserComponent } from './update-user/update-user.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { MarkAttendanceComponent } from './mark-attendance/mark-attendance.component';

const routes: Routes = [
  {
    path: 'crm', component: CommonComponent,
    children: [
      { path: '', component: AdminComponent, canActivate: [RouteGuardService] },
      { path: 'attendance', component: AttendanceComponent, canActivate: [RouteGuardService] },
      { path: 'leadReport', component: LeadReportComponent, canActivate: [RouteGuardService] },
      { path: 'lead', component: LeadsComponent, canActivate: [RouteGuardService] },
      { path: 'openLead', component: OpenleadComponent, canActivate: [RouteGuardService] },
      { path: 'callingData', component: CallingDataComponent, canActivate: [RouteGuardService] },
      { path: 'profile', component: ProfileComponent, canActivate: [RouteGuardService] },
      // { path: 'meetingDetails', component: MeetingDetailsComponent },
      { path: 'crm', redirectTo: 'crm', pathMatch: 'full' },
      { path: 'updateUser', component: UpdateUserComponent, canActivate: [RouteGuardService] },
      { path: 'chart', component: ChartComponent, canActivate: [RouteGuardService] },
      { path: 'updatePassword', component: UpdatePasswordComponent, canActivate: [RouteGuardService] },
      { path: 'markAttendance', component: MarkAttendanceComponent, canActivate: [RouteGuardService] }
    ],
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CRMRoutingModule { }
