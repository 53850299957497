<div class="content-wrapper" style="background-color: #fff !important; ">
<section  class="Leads_manage leads_redesign">
    <a class="backbtn" href="crm/profile">
    <button class="btn btn-warning"style="color: white;    margin-top: 22px;"><i class="fa fa-arrow-left" aria-hidden="true" style="color: white;"></i> Back</button>
    </a>
    <div class="homebtn">
    <a href="/crm">
        <button class="btn btn-success"style="margin-top: -8px;">Home <i class="fa fa-arrow-right" aria-hidden="true"></i></button>
        </a>
    </div>
<div class="update">
<div class="modal-body">
    <form name="addLeadForm" (submit)="uPassword($event)">
    <div class="box-body">
    <div class="row">
        <div class="col-xs-12 col-lg-6">
        <div class="form-group">
            <label for="mobile">Mobile Number</label>
            <input type="text" class="form-control" value="{{user.mobile}}" id="mobile"
            placeholder="Enter Number" required>
        </div>
        </div>
        <div class="col-xs-12 col-lg-6">
            <div class="form-group">
                <label for="">New Password</label>
                <input type="password" class="form-control" id="password"
                placeholder="Enter Password" required>
            </div>
            </div>
            <div class="col-xs-12 col-lg-6">
                <div class="form-group">
                    <label for="">Confirm Password</label>
                    <input type="password" class="form-control" id="confirmPassword"
                    placeholder="Confirm Password" required>
                </div>
                </div>
    </div>
    </div>
    <!-- /.box-body -->
    <div class="box-footer">
    <button type="submit" class="btn btn-primary btn-block" style="font-size: 20px;font-weight: 600;width: 36%;margin-left: 222px;">Update Password</button>
    </div>
</form>
</div>
</div>
</section>
</div>