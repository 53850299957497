<div class="content-wrapper" style="min-height: 906px">
  <section class="topmm">
    <button
      class="btn btn-primary toggle__btn"
      type="button"
      data-toggle="collapse"
      data-target="#collapsefilters"
      aria-expanded="false"
      aria-controls="collapseExample"
    >
      &#x25BC;
    </button>
    <div class="container-fluid">
      <div class="row">
        <div class="collapse" id="collapsefilters">
          <div class="filter-leads">
            <form (submit)="onsubmitFilter($event)">
              <div class="col-xs-12 col-sm-12 col-lg-3">
                <div class="form-group">
                  <label for="usr">Date From</label>
                  <input type="date" class="form-control" id="date" />
                </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-lg-3">
                <div class="form-group">
                  <label for="sel1">Agents</label>
                  <!-- <select class="form-control" (change)=onchangeAgent($event.target.value)> -->
                  <select class="form-control" id="agent">
                    <option value="">Select Agent</option>
                    <option
                      *ngFor="let user of activeUsers"
                      value="{{ user.id }}"
                    >
                      {{ user.firstName + " " + user.lastName }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-lg-3">
                <div class="button_ss">
                  <button type="submit" class="btn btn-primary btn-block">
                    Filter
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- partial:index.partial.html -->
  <section class="Leads_manage">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12">
          <div class="table-responsive table-fixed">
            <table
              summary=""
              class="table table-bordered table-hover table-striped text-center"
            >
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Meeting Id</th>
                  <th style="background-color: #ffd75f21">Client Name</th>
                  <th>Meeting Status</th>
                  <th>Meeting's In Time</th>
                  <th>Meeting's Out Time</th>
                  <th>Agent Name</th>
                </tr>
              </thead>
              <tbody class="serialNumber">
                <tr class="content" *ngFor="let meeting of meetingsData">
                  <td></td>
                  <td>{{ meeting.id }}</td>
                  <td style="background-color: #ffd75f21">
                    {{ meeting.leads.name }}
                  </td>
                  <td>{{ meeting.status }}</td>
                  <td>{{ meeting.meetingInTime }}</td>
                  <td>{{ meeting.meetingOutTime }}</td>
                  <td>
                    {{ meeting.user.firstName + " " + meeting.user.lastName }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--end of .table-responsive-->
        </div>
      </div>
    </div>
  </section>
  <!-- partial -->
</div>
