<div class="content-wrapper"  style="background-color: #fff !important;">
  <section class="Leads_manage leads_redesign">
  
    <div class="bord">
      <div class="col-xs-12 col-sm-3 col-lg-12 ">
        <div class="form-group">
          <div class="date_filter_tab toggle__btn">
            <p class="" for="usr" value="assigned">Filter :</p>
            <button class="  fib_system_btn">
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </button>
          </div>
          <div class="collapse" id="collapsefilters">
            <form #filterForm="ngForm" (submit)="onsubmit()">
              <div class="container-fluid"> 
                <div class="row">
                  
                  <div class="col-xs-12 col-sm-12 col-lg-4">
                    <div class="form-group">
                      <label for="usr">Select Project:</label>
                      <ng-multiselect-dropdown class="im_multi" [placeholder]="'Projects Name'" [data]="projectNameList"
                        [settings]="dropdownSettings" (onSelect)="onProjectSelect($event)"
                        (onDeSelect)="onProjectDeSelect($event)" (onSelectAll)="onSelectAllProject($event)"
                        (onDeSelectAll)="onSelectAllProject($event)">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-lg-4">
                    <div class="form-group">
                      <label for="usr">Select Employees:</label>
                      <ng-multiselect-dropdown class="im_multi" [placeholder]="'Employees Name'" [data]="userList"
                        [settings]="dropdownSettings" (onSelect)="onEmpSelect($event)"
                        (onDeSelect)="onEmpDeSelect($event)" (onSelectAll)="onSelectAllEmp($event)"
                        (onDeSelectAll)="onDeSelectAllEmp($event)">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-2">
                    <div class="form-group ">
                      <label> &nbsp;</label>
                      <button type="submit" class="fib_system_btn_auto_w min-h-44">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>


  </section>
  <div class="col-lg-12">&nbsp;</div>
  <!-- partial:index.partial.html -->
  <section class="Leads_manage leads_transfer">
        <div class="col-xs-12">
          <div class="table-responsive table-fixed">
            <table class="table table-bordered table-hover bg-white text-left">
              <thead>
                <tr class="header_color">
                  <th>Sr. No.</th>
                  <th>Project</th>
                  <th>Employees</th>
                  <th>No Of Leads</th>
                  <th>Priority</th>
                  <th>Delete</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let select of selectedItems; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ select.name }}</td>
                  <td>
                    <table class="gfg">
                      <tr *ngFor="let usr of select.users">
                        <td>{{ usr.userName }}</td>
                      </tr>
                    </table>
                  </td>
                  <td>
                    <table class="gfg">
                      <tr *ngFor="let usr of select.users">
                        <td>{{ usr.count }}</td>
                      </tr>
                    </table>
                  </td>
                  <td>
                    <table class="gfg">
                      <tr *ngFor="let usr of select.users">
                        <td>{{ usr.priority }}</td>
                      </tr>
                    </table>
                  </td>
                  <td>
                    <table class="gfg">
                      <tr *ngFor="let usr of select.users">
                        <td>
                        <button class="fib_system_btn_auto_w" (click)="trash(select, usr.userId)">
                          <i class="fa fa-trash"></i>
                        </button>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td>
                    <table class="gfg">
                      <tr *ngFor="let usr of select.users">
                      <td>
                          <button class="fib_system_btn_auto_w" data-target="#editUser"
                            (click)="getProjectAndUserID(select.id, usr.userId)" data-toggle="modal">
                            <i class="fa fa-pencil-square-o"></i>
                          </button>
                        </td> 
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- <table summary="" class="table table-bordered table-hover bg-white text-center">
              <thead>
                <tr class="header_color">
                  <th>Sr. No.</th>
                  <th>Project</th>
                  <th>Employees</th>
                  <th>No Of Leads</th>
                  <th>Delete</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody class="serialNumber">
                <tr *ngFor="let select of selectedItems">
                  <td></td>
                  <td>{{select.name}}</td>
                  <td>

                  <tr *ngFor="let agent of select.users">
                    <td>
                      {{agent.userName}}
                    </td>
                  </tr>

                </td>
                <td>221</td>
                <td>
                  <button class="btn btn-primary">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
                <td>
                  <button class="btn btn-primary">
                    <i class="fa fa-edit"></i>
                  </button>
                </td>
                </tr>
              </tbody>
            </table> -->
          </div>
          <!--end of .table-responsive-->
        </div>
  </section>
  <!-- partial -->
</div>
<div class="editUser">
  <!-- Modal -->
  <div class="modal fade" id="editUser" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Update leads count</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <form name="addLeadForm" (submit)="updateLeadsCount($event)">
            <div class="box-body">
              <div class="row">
                <div class="col-xs-12 col-lg-12">
                  <div class="form-group">
                    <label for="">Leads count</label>
                    <input type="text" class="form-control" placeholder="Enter total no. of leads count" id="countNo" />
                  </div>
                </div>
              </div>
            </div>
            <div class="box-body">
              <div class="row">
                <div class="col-xs-12 col-lg-12">
                  <div class="form-group">
                    <label for="">Priority</label>
                    <input type="text" class="form-control" placeholder="Enter priority of user" id="priority" />
                  </div>
                </div>
              </div>
            </div>
            <!-- /.box-body -->
            <div class="box-footer">
              <button type="submit" class="btn btn-primary btn-block">
                Update Leads Count
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>