<div class="content-wrapper" style="min-height: 906px;">
  <section class="Leads_manage">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12">
          <div class="table-responsive table-fixed table-bg">
            <table summary="" class="table table-bordered table-hover text-center">
              <thead>
                <tr>
                  <th>Id</th>
                  <th style="background-color:#ffd75f21">Employee Name</th>
                  <th style="background-color:#b17fad26">Mobile</th>
                  <th style="background-color:#ffd75f21">Email</th>
                  <th style="background-color:#ffd75f21">Address</th>
                  <th>Gender</th>
                  <th style="background-color:#0ce2e21f">Date Of Birth</th>
                  <th>Deleted</th>
                  <th>Parent Id</th>
                  <th>Role</th>
                  <th *ngIf="!MOBILE">Edit</th>
                </tr>
              </thead>
              <tbody>
                <tr class="content" *ngFor="let user of users ;index as i">
                  <td>{{user.id}}</td>
                  <td>{{user.firstName +" "+user.lastName}}</td>
                  <td style="background-color:#b17fad26">{{user.mobile}}</td>
                  <td style="background-color:#ffd75f21">{{user.email}}</td>
                  <td>{{user.address}}</td>
                  <td>{{user.gender}}</td>
                  <td style="background-color:#0ce2e21f">{{user.dob}}</td>
                  <td>
                    <ui-switch size="small" color="red" [checked]="user.deleted" defaultBgColor="green"
                      (change)="onChangeEvent($event, user)">
                    </ui-switch>
                  </td>
                  <!-- User is deleted show Yes -->
                  <!-- <td *ngIf="user.deleted">
                    <div class="toggle-button-cover">
                      <div class="button-cover">
                        <div class="button r" id="button-1">
                          <input type="checkbox" class="checkbox">
                          <div class="knobs"></div>
                          <div class="layer"></div>
                        </div>
                      </div>
                    </div>
                  </td> -->

                  <!-- User not deleted show no -->
                  <!-- <td *ngIf="!user.deleted" style="width: 5.5%;">
                    <div class="onoffswitch">
                      <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="myonoffswitch" checked>
                      <label class="onoffswitch-label" for="myonoffswitch">
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </td> -->
                  <select name="users" (change)="onChange($event.target.value, user.id)">
                    <option>{{user.parentId}}</option>
                    <option *ngFor="let user of users" value="{{user.id}}">{{user.id}}
                    </option>
                  </select>
                  <td>{{user.roles[0].role}}</td>
                  <td *ngIf="!MOBILE">
                    <button class="btn btn-primary" data-target="#editUser" (click)="updateUserId(user.id)"
                      data-toggle="modal">
                      <i class="fa fa-pencil-square-o"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--end of .table-responsive-->
        </div>
      </div>
    </div>
  </section>
  <!-- partial -->
</div>


<div class="editUser">
  <!-- Modal -->
  <div class="modal fade" id="editUser" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">×</button>
          <h4 class="modal-title">Update User</h4>
        </div>
        <div class="modal-body">
          <form name="addLeadForm" (submit)="updateUser($event)">
            <div class="box-body">
              <div class="row">
                <div class="col-xs-12 col-lg-6">
                  <div class="form-group">
                    <label for="">First Name</label>
                    <input type="text" class="form-control" value="{{selectedUser.firstName}}" id="fName"
                      placeholder="Enter First Name">
                  </div>
                </div>
                <div class="col-xs-12 col-lg-6">
                  <div class="form-group">
                    <label for="">Last Name</label>
                    <input type="text" class="form-control" value="{{selectedUser.lastName}}" id="lName"
                      placeholder="Enter Last Name">
                  </div>
                </div>
                <div class="col-xs-12 col-lg-6">
                  <div class="form-group">
                    <label for="">Email</label>
                    <input type="email" class="form-control" value="{{selectedUser.email}}" id="email"
                      placeholder="Enter Email">
                  </div>
                </div>
                <div class="col-xs-12 col-lg-6">
                  <div class="form-group">
                    <label for="">Mobile Number</label>
                    <input type="text" class="form-control" value="{{selectedUser.mobile}}" id="mobile"
                      placeholder="Enter Number">
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="">Address</label>
                <input type="text" class="form-control" value="{{selectedUser.address}}" id="address"
                  placeholder="Enter Address" required>
              </div>
            </div>
            <!-- /.box-body -->
            <div class="box-footer">
              <button type="submit" class="btn btn-primary btn-block">Update User</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>