import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { NgxUiLoaderService } from "ngx-ui-loader";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Leads } from "../model/leads.model";
import { DatePipe } from "@angular/common";
import { environment as config } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private loggedInStatus = false;
  private leadsArray: Leads[];
  private userId: any;

  // private baseUrl = config.apiUrl;
  private baseUrl = 'http://148.66.133.154:8181/'
  // private baseUrl = 'http://localhost:8181/';

  constructor(
    private http: HttpClient,
    private ngxService: NgxUiLoaderService
  ) {}

  setUserId(userId: any) {
    this.userId = userId;
  }

  getUserId() {
    return this.userId;
  }

  callRecordOfLeads(leadsId, userId) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    if (localStorage.getItem("role") === "ADMIN") {
      return this.http.get<any>(
        this.baseUrl + "get/call/record/" + userId + "/" + leadsId,
        httpOptions
      );
    }
  }

  getLeadReportById(leadId: any) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any>(
      this.baseUrl + "leads/report/by/lead/" + leadId,
      httpOptions
    );
  }

  getUserImage() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token",
        observable: "response",
      }),
    };
    return this.http.get<any>(this.baseUrl + "get/user/image", httpOptions);
  }

  getLeadsReportByPageNumber(page: number) {
    page = page - 1;
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get(this.baseUrl + "list?page=" + page, httpOptions);
  }

  filterLeadsReportByPage(jsonObject, page, size) {
    page = page - 1;
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    if (localStorage.getItem("role") === "ADMIN") {
      return this.http.post<any[]>(
        this.baseUrl +
          "leads/report/filter/by/criteria?page=" +
          page +
          "&size=" +
          size,
        jsonObject,
        httpOptions
      );
    } else {
      return this.http.post<any[]>(
        this.baseUrl +
          "leads/report/filter/by/criteria/of/employee?page=" +
          page +
          "&size=" +
          size,
        jsonObject,
        httpOptions
      );
    }
  }

  filterReport(jsonObject, page, size) {
    page = page - 1;
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    if (localStorage.getItem("role") === "ADMIN") {
      return this.http.post<any[]>(
        this.baseUrl + "leads/report/filter?page=" + page + "&size=" + size,
        jsonObject,
        httpOptions
      );
    } else {
      return this.http.post<any[]>(
        this.baseUrl +
          "leads/report/filter/of/employee?page=" +
          page +
          "&size=" +
          size,
        jsonObject,
        httpOptions
      );
    }
  }

  filterTotalLeadsReport(jsonObject, page, size) {
    page = page - 1;
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    if (localStorage.getItem("role") === "ADMIN") {
      return this.http.post<any[]>(
        this.baseUrl +
          "filter/total/leads/by/page?page=" +
          page +
          "&size=" +
          size,
        jsonObject,
        httpOptions
      );
    }
    // } else {
    //   return this.http.post<any[]>(this.baseUrl + 'leads/report/filter/of/employee?page=' + page + '&size=' + size, jsonObject, httpOptions)
    // }
  }

  searchClientNameOrPhone(keyword) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    return this.http.get<any[]>(
      this.baseUrl + "search/client/name/or/phone/" + keyword,
      httpOptions
    );
  }

  filterTotalLeadsByPage(jsonObject, page) {
    page = page - 1;
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    return this.http.post<any[]>(
      this.baseUrl +
        "total/leads/filter/by/criteria/with/pagination?page=" +
        page,
      jsonObject,
      httpOptions
    );
  }

  filterTotalDeletedLeadsByPage(jsonObject, page) {
    page = page - 1;
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    return this.http.post<any[]>(
      this.baseUrl +
        "total/deleted/leads/filter/by/criteria/with/pagination?page=" +
        page,
      jsonObject,
      httpOptions
    );
  }

  filterDeclineLeadsByPage(jsonObject, page) {
    page = page - 1;
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    return this.http.post<any[]>(
      this.baseUrl +
        "total/leads/filter/by/criteria/with/pagination?page=" +
        page,
      jsonObject,
      httpOptions
    );
  }

  getTotalLeadsByPageNumber(json_obj: any, page: number, size) {
    page = page - 1;
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.post(
      this.baseUrl + "total/leads/filter?page=" + page,
      json_obj,
      httpOptions
    );
  }

  filterLeadsReport(jsonObject) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    return this.http.post<any[]>(
      this.baseUrl + "leads/report/filter/by/criteria",
      jsonObject,
      httpOptions
    );
  }

  deleteLeads(leadsId) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    return this.http.put<any>(
      this.baseUrl + "delete/leads/" + leadsId,
      null,
      httpOptions
    );
  }

  setLoggedIn(status: boolean) {
    this.loggedInStatus = status;
  }

  getLoggedIn() {
    return this.loggedInStatus;
  }

  setLeadsArray(leads: any[]) {
    this.leadsArray = leads;
  }

  getLeadsArray() {
    return this.leadsArray;
  }

  userLogin(mobile, password) {
    var auth = "Basic " + btoa(mobile + ":" + password);
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.post<any>(
      this.baseUrl + "generate/token",
      null,
      httpOptions
    );
  }

  userTokenCheck() {
    var auth = localStorage.getItem("token");
    var mobile = localStorage.getItem("mobile");
    var body = { token: auth, mobile: mobile };
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.post<any>(
      this.baseUrl + "generate/validate/token",
      body,
      httpOptions
    );
  }

  getLeads() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token",
        observable: "response",
      }),
    };
    if (localStorage.getItem("role") === "ADMIN") {
      return this.http.get<any[]>(
        this.baseUrl + "get/all/new/leads",
        httpOptions
      );
    } else {
      return this.http.get<any[]>(
        this.baseUrl + "get/all/new/leads/of/user/and/under/the/user",
        httpOptions
      );
    }
  }

  getCallLeads() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(this.baseUrl + "get/call/leads", httpOptions);
  }

  getLeadsCount() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token",
        observable: "response",
      }),
    };
    if (localStorage.getItem("role") === "ADMIN") {
      return this.http.get<any>(
        this.baseUrl + "get/all/new/leads/count",
        httpOptions
      );
    } else {
      return this.http.get<any>(
        this.baseUrl + "get/all/new/leads/counts/of/user/and/under/the/user",
        httpOptions
      );
    }
  }

  getTotalLeads() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(this.baseUrl + "get/all/leads", httpOptions);
  }

  getMeetingDetails(page) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(
      this.baseUrl + "get/all/meetings?page=" + (page - 1),
      httpOptions
    );
  }

  getAgentTodayMeeting() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any>(
      this.baseUrl + "get/agent/meeting/details/" + localStorage.getItem("id"),
      httpOptions
    );
  }

  getMeetingDetailsCount() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any>(
      this.baseUrl + "get/all/meetings/count",
      httpOptions
    );
  }

  getTotalLeadsCount() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any>(
      this.baseUrl + "get/all/leads/count/",
      httpOptions
    );
  }

  getAllActiveUser() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(
      this.baseUrl + "get/all/active/user",
      httpOptions
    );
  }

  getOpenLeads() {
    status = "open";
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(
      this.baseUrl + "get/all/leads/report/by/status/" + status,
      httpOptions
    );
  }

  getOpenLeadsCounts() {
    status = "open";
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    if (localStorage.getItem("role") === "ADMIN") {
      return this.http.get<any>(
        this.baseUrl + "get/all/leads/report/by/status/count/" + status,
        httpOptions
      );
    } else {
      return this.http.get<any>(
        this.baseUrl +
          "get/all/leads/report/count/of/user/under/user/by/status/" +
          status,
        httpOptions
      );
    }
  }

  getOpenLeadsByPage(page) {
    let status = "open";
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    if (localStorage.getItem("role") === "ADMIN") {
      return this.http.get<any[]>(
        this.baseUrl +
          "list/all/leads/report/by/status/" +
          status +
          "?page=" +
          page,
        httpOptions
      );
    } else {
    }
  }

  getLeadsBySource(source: string) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(
      this.baseUrl + "get/leads/by/source/" + source + "",
      httpOptions
    );
  }

  addLeads(leads) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.post<any>(this.baseUrl + "add/leads", leads, httpOptions);
  }

  addLeadsByEmployee(leads: any) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.post<any>(
      this.baseUrl + "add/leads/by/employee",
      leads,
      httpOptions
    );
  }

  saveProjectUserCount(obj: any) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "cache-control": "no-cache",
        "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.post<any>(
      this.baseUrl + "save/project/user/count",
      obj,
      httpOptions
    );
  }

  saveCustomer(obj: any) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "cache-control": "no-cache",
        "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.post<any>(this.baseUrl + "saveCustomer", obj, httpOptions);
  }

  getProjects() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "cache-control": "no-cache",
        "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(
      this.baseUrl + "get/all/projects/",
      httpOptions
    );
  }

  getSources() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "cache-control": "no-cache",
        "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(this.baseUrl + "get/source/", httpOptions);
  }

  getAllAssignedProjects() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "cache-control": "no-cache",
        "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(
      this.baseUrl + "get/All/assigned/project/",
      httpOptions
    );
  }

  updateProjects(project: any) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.post<any>(
      this.baseUrl + "update/project",
      project,
      httpOptions
    );
  }

  getUsers() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    if (localStorage.getItem("role") === "ADMIN") {
      return this.http.get<any[]>(this.baseUrl + "users", httpOptions);       // changes in (this.baseUrl + "users", httpOptions)
    } else {
      return this.http.get<any[]>(
        this.baseUrl + "get/all/child/user/" + localStorage.getItem("id"),
        httpOptions
      );
    }
  }

  getSalesAndActiveUsers() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "cache-control": "no-cache",
        "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    if (localStorage.getItem("role") === "ADMIN") {
      return this.http.get<any[]>(this.baseUrl + "sales/users", httpOptions);
    } else {
      return this.http.get<any[]>(
        this.baseUrl + "get/all/child/user/" + localStorage.getItem("id"),
        httpOptions
      );
    }
  }

  getProjectUserCount(projectId, userId) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any>(
      this.baseUrl + "get/project/user/count/" + projectId + "/" + userId,
      httpOptions
    );
  }

  getUsersCount() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any>(this.baseUrl + "users/count/", httpOptions);
  }

  assignLeads(userId, leadsId) {
    var body = { userId: userId, leadsId: leadsId };
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.put<any>(
      this.baseUrl + "assign/leads/to/sales/member",
      body,
      httpOptions
    );
  }

  assignLeadsAsCallingData(userId, leadsId) {
    var body = { userId: userId, leadsId: leadsId };
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.post<any>(
      this.baseUrl + "assignAsCallLeads",
      body,
      httpOptions
    );
  }

  spamLeads(leadsId) {
    var body = { leadsId: leadsId };
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.put<any>(this.baseUrl + "spamLeads", body, httpOptions);
  }

  assignCallLeads(userId, leadsId) {
    var body = { userId: userId, leadsId: leadsId };
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.put<any>(
      this.baseUrl + "assign/call/leads/to/sales/member",
      body,
      httpOptions
    );
  }

  declineCallLeads(leadsId) {
    var body = { leadsId: leadsId };
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.put<any>(
      this.baseUrl + "decline/call/leads",
      body,
      httpOptions
    );
  }

  getLeadsReports() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(
      this.baseUrl + "get/all/leads/report/",
      httpOptions
    );
  }

  getLeadsReportsCount() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };

    if (localStorage.getItem("role") === "ADMIN") {
      return this.http.get<any>(
        this.baseUrl + "get/all/leads/report/count",
        httpOptions
      );
    } else {
      return this.http.get<any>(
        this.baseUrl + "get/all/leads/report/count/of/user/under/user/",
        httpOptions
      );
    }
  }

  addEmployee(user) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "cache-control": "no-cache",
        "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.post<any>(this.baseUrl + "signup", user, httpOptions);
  }

  saveProject(user) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "cache-control": "no-cache",
        "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.post<any>(this.baseUrl + "signup", user, httpOptions);
  }

  updateProject(project) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "cache-control": "no-cache",
        "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.post<any>(
      this.baseUrl + "update/user/projects",
      project,
      httpOptions
    );
  }

  deleteProjectUser(project) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "cache-control": "no-cache",
        "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.post<any>(
      this.baseUrl + "delete/projects/user",
      project,
      httpOptions
    );
  }

  deleteProjectUserCountEntry(projectId, userId) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "cache-control": "no-cache",
        "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.delete<any>(
      this.baseUrl +
        "delete/project/user/count/entry/" +
        projectId +
        "/" +
        userId,
      httpOptions
    );
  }

  updateUser(user) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "cache-control": "no-cache",
        "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.put<any>(this.baseUrl + "update/user", user, httpOptions);
  }

  deleteUser(jsonObject: any) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    return this.http.put<any>(
      this.baseUrl + "delete/user",
      jsonObject,
      httpOptions
    );
  }

  getSourceString() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(this.baseUrl + "get/source/list", httpOptions);
  }

  getSource() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(this.baseUrl + "getAllSource", httpOptions);
  }

  getSourceStringOfDeletedLeads() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(
      this.baseUrl + "get/source/String/of/deleted/leads",
      httpOptions
    );
  }

  getProjectNameString() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(
      this.baseUrl + "get/project/name/string",
      httpOptions
    );
  }

  getProjectNameStringOfDeletedLeads() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(
      this.baseUrl + "get/project/name/string/of/deleted/leads",
      httpOptions
    );
  }

  getRoles() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(this.baseUrl + "get/all/roles", httpOptions);
  }

  getFeedbackOfLeads(leadsId) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(
      this.baseUrl + "feedback/get/all/" + leadsId,
      httpOptions
    );
  }

  getFeedbackOfLeads1(leadsId) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(
      this.baseUrl + "feedback/get/all/employee/" + leadsId  ,
      httpOptions
    );
  }

  getCorporate() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(
      this.baseUrl + "get/all/corporate",
      httpOptions
    );
  }

  getCloseLeads() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(this.baseUrl + "get/close/leads", httpOptions);
  }

  getCloseLeadsCount() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any>(
      this.baseUrl + "get/close/leads/count",
      httpOptions
    );
  }

  getDeclineLeads() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(
      this.baseUrl + "get/decline/leads",
      httpOptions
    );
  }

  getDeclineLeadsCount() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any>(
      this.baseUrl + "get/decline/leads/count",
      httpOptions
    );
  }

  getDeclineLeadsByPage(page) {
    page = page - 1;
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(
      this.baseUrl + "get/decline/leads/with/pagination?page=" + page,
      httpOptions
    );
  }

  updateParentIdOfUser(jsonObject) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    return this.http.put<any>(
      this.baseUrl + "update/user/parent_id",
      jsonObject,
      httpOptions
    );
  }

  filterLeads(jsonObject) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    if (localStorage.getItem("role") === "ADMIN") {
      return this.http.post<any[]>(
        this.baseUrl + "new/leads/filter/by/criteria",
        jsonObject,
        httpOptions
      );
    } else {
      return this.http.post<any[]>(
        this.baseUrl + "new/leads/filter/by/criteria/employee",
        jsonObject,
        httpOptions
      );
    }
  }

  meetingFilter(jsonObject) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    return this.http.post<any[]>(
      this.baseUrl + "meeting/filter/by/criteria",
      jsonObject,
      httpOptions
    );
  }

  filterLeadsReportForExcelExport(jsonObject) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    if (localStorage.getItem("role") === "ADMIN") {
      return this.http.post<any[]>(
        this.baseUrl + "filter/leads/report/export",
        jsonObject,
        httpOptions
      );
    } else {
      return this.http.post<any[]>(
        this.baseUrl + "employee/filter/leads/report/export",
        jsonObject,
        httpOptions
      );
    }
  }

  exportUserAttendance(jsonObject) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    if (localStorage.getItem("role") === "ADMIN") {
      return this.http.post<any[]>(
        this.baseUrl + "export/user/attendance",
        jsonObject,
        httpOptions
      );
    }
  }

  filterTotalLeads(jsonObject) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    return this.http.post<any[]>(
      this.baseUrl + "total/leads/filter/by/criteria",
      jsonObject,
      httpOptions
    );
  }

  getUserAttendanceList(user_id, start_date, end_date) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(
      this.baseUrl +
        "user/attendance/" +
        user_id +
        "/" +
        start_date +
        "/" +
        end_date,
      httpOptions
    );
  }

  getUserAttendanceCount(user_id) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any>(
      this.baseUrl + "get/user/attendance/count/" + user_id,
      httpOptions
    );
  }

  getUserMonthAttendanceCount(user_id, startOfMonth, endOfMonth) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any>(
      this.baseUrl +
        "get/user/month/attendance/" +
        user_id +
        "/" +
        startOfMonth +
        "/" +
        endOfMonth,
      httpOptions
    );
  }

  getTodayAttendanceList() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any[]>(
      this.baseUrl + "get/today/all/attendance/list",
      httpOptions
    );
  }

  getTodayAttendance(userId) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any>(
      this.baseUrl + "get/today/attendance/" + userId,
      httpOptions
    );
  }

  getMeetingCount(user_id) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    if (localStorage.getItem("role") === "ADMIN") {
      return this.http.get<any>(
        this.baseUrl + "get/total/meeting/count",     // get/meeting/details/count : replacement
        httpOptions
      );
    } else {
      return this.http.get<any>(
        this.baseUrl + "get/agent/meeting/details/counts/" + user_id,
        httpOptions
      );
    }
  }

  findUserById(id) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };

    return this.http.get<any>(this.baseUrl + "find/user/by/" + id, httpOptions);
  }

  saveExcelData(jsonObject, str: string) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    if (str === "calling") {
      return this.http.post<any[]>(
        this.baseUrl + "save/excel/calling/data",
        jsonObject,
        httpOptions
      );
    } else {
      return this.http.post<any[]>(
        this.baseUrl + "save/excel/data",
        jsonObject,
        httpOptions
      );
    }
  }

  getFeedbackForExport(leadsId, userId) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };

    return this.http.get<any[]>(
      this.baseUrl + "feedback/for/export/" + leadsId + "/" + userId,
      httpOptions
    );
  }

  saveLeadsFeedback(leadsId, feedback) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    return this.http.post<any>(
      this.baseUrl + "webapp/feedback/save/" + leadsId,
      feedback,
      httpOptions
    );
  }

  saveAndAssignLeads(leads: any, userId: any) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    return this.http.post<any>(
      this.baseUrl + "save/and/assign/leads/to/user/" + userId,
      leads,
      httpOptions
    );
  }

  updateCallLeadsAndReport(leads: any) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    return this.http.post<any>(
      this.baseUrl + "update/call/leads/and/report",
      leads,
      httpOptions
    );
  }

  filterCallLeadsByPage(jsonObject, page, size) {
    page = page - 1;
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    if (localStorage.getItem("role") === "ADMIN") {
      return this.http.post<any[]>(
        this.baseUrl +
          "call/leads/filter/by/criteria?page=" +
          page +
          "&size=" +
          size,
        jsonObject,
        httpOptions
      );
    } else {
      return this.http.post<any[]>(
        this.baseUrl +
          "call/leads/filter/by/criteria/of/employee?page=" +
          page +
          "&size=" +
          size,
        jsonObject,
        httpOptions
      );
    }
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  EXCEL_EXTENSION = ".xlsx";
  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: this.EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + new Date().toLocaleString() + this.EXCEL_EXTENSION
    );
  }

  startLoader() {
    this.ngxService.start();
  }

  stopLoader() {
    this.ngxService.stop();
  }

  saveIPAddress(loginData) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    return this.http.post<any>(
      this.baseUrl + "save/login/record",
      loginData,
      httpOptions
    );
  }

  checkUserPhoneAndEmail(object) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.post<any>(
      this.baseUrl + "check/user/phone/and/email",
      object,
      httpOptions
    );
  }

  resetPassword(object) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.post<any>(
      this.baseUrl + "reset/password",
      object,
      httpOptions
    );
  }

  checkPasswordRestLink(object) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.post<any>(
      this.baseUrl + "validate/link",
      object,
      httpOptions
    );
  }

  datePipe = new DatePipe("en-US");

  toTimestamp(date) {
    if (date != null && date != '') {
      date = this.datePipe.transform(date, "yyyy-MM-dd");
      var myDate = new Date(date);
      return myDate.getTime();
    } else {
      return 0;
    }
  }

  toDateAndTimeTimestamp(date) {
    if (date != null && date != '') {
      date = this.datePipe.transform(date, "yyyy-MM-dd hh:mm a");
      var myDate = new Date(date);
      return myDate.getTime();
    } else {
      return 0;
    }
  }

  toString(timestamp) {
    if (timestamp > 0) {
      var date = new Date(timestamp);
      return this.datePipe.transform(date, "dd-MM-yyyy hh:mm a");
    } else {
      return "";
    }
  }

  toTime(timestamp) {
    if (timestamp > 0) {
      var date = new Date(timestamp);
      return this.datePipe.transform(date, "hh:mm a");
    } else {
      return "";
    }
  }

  /*
  By Abhishek Srivastav
  */

  getProfile(mobile)
  {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };

    return this.http.get<any>(
      this.baseUrl+"get/user/by/mobile/" + mobile,
      httpOptions
    );

  // let url = 'http://148.66.133.154:7070/investmango-0.0.1-SNAPSHOT/get/user/by/mobile/'+mobile;
  //   return this.http.get(url);
  }


  uPassword(id,arrobj){
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "cache-control": "no-cache",
        "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.put<any>(
      this.baseUrl+"update/password/"+id,arrobj,
      httpOptions
      );
  }



  searchClientById(leads_id){
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    return this.http.get<any[]>(
      this.baseUrl+"search/client/by/id/"+leads_id,
      httpOptions
    );
  }

  getFollowupleads(){
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    return this.http.get<any[]>(
      this.baseUrl+"get/followup/leads/",
      httpOptions
    );
  }

  
  filterLeads1(jsonObject) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    if (localStorage.getItem("role") === "ADMIN") {
      return this.http.post<any[]>(
        this.baseUrl + "new/leads/filter/by/source/for/call",
        jsonObject,
        httpOptions
      );
    } else {
      return this.http.post<any[]>(
        this.baseUrl + "new/leads/filter/by/criteria/employee",
        jsonObject,
        httpOptions
      );
    }
  }

  getTotalCallLeadsCount() {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any>(
      this.baseUrl + "get/all/new/call/leads/count",
      httpOptions
    );
  }
  

  performance(userId) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any>(
      this.baseUrl + "get/user/leads/data/by/user/"+ userId,
      httpOptions
    );
  }
  pendingReview(userId) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any>(
      this.baseUrl + "get/agent/pending/task/by/"+ userId,
      httpOptions
    );
  }
  opendeclinedata(userId) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any>(
      this.baseUrl + "get/total/open/to/decline/leads/by/"+ userId,
      httpOptions
    );
  }
  
  totalopendeclinedata(lstatus,userId) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
        observable: "response",
      }),
    };
    return this.http.get<any>(
      this.baseUrl + "get/open/to/decline/leads/by/"+lstatus+"/"+userId ,
      httpOptions
    );
  }
  
  updateAttendance(user_id) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    return this.http.put<any>(
      this.baseUrl + "update/user/attendance/by/system/" + user_id,
      null,
      httpOptions
    );
  }
  

  markAttendance(user_id) {
    var auth = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "x-auth-token": auth,
        "Access-Control-Allow-Origin": "*",
      }),
    };
    return this.http.post<any>(this.baseUrl + "save/user/attendance/by/system/"+user_id,
    null,
    httpOptions
  );
  }

  getexcelAttendance() {
    const httpOptions = {
      responseType: 'blob' as 'json', 
      headers: new HttpHeaders(),
    };
    return this.http.get(this.baseUrl + 'get/today/attendance/in/excel', httpOptions);
  }
  

  getExcelAttendanceByDate(date: string) {
  const httpOptions = {
    responseType: 'blob' as 'json', 
    headers: new HttpHeaders(),
  };
  return this.http.get(this.baseUrl + 'get/date/attendance/in/excel/by/date/select/' + date, httpOptions);
}

}
