<div class="content-wrapper" style="min-height: 906px">
  <section class="Leads_manage">
    <button
      class="btn btn-primary toggle__btn"
      type="button"
      data-toggle="collapse"
      data-target="#collapsefilters"
      aria-expanded="false"
      aria-controls="collapseExample"
    >
      &#x25BC;
    </button>
    <div class="container-fluid">
      <div class="row">
        <div class="collapse" id="collapsefilters">
          <div class="filter-leads">
            <form (submit)="onsubmitFilter($event)">
              <div class="col-xs-12 col-sm-12 col-lg-3">
                <div class="form-group">
                  <label for="usr">Date From</label>
                  <input type="date" class="form-control" id="datefrom" />
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-lg-3">
                <div class="form-group">
                  <label for="usr">Date To:</label>
                  <input type="date" class="form-control" id="dateto" />
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-lg-3">
                <div class="form-group">
                  <label for="sel1">Agents</label>
                  <!-- <select class="form-control" (change)=onchangeAgent($event.target.value)> -->
                  <select class="form-control" id="agent">
                    <option value="">Select Agent</option>
                    <option *ngFor="let user of userList" value="{{ user.id }}">
                      {{ user.firstName + " " + user.lastName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-lg-3" *ngIf="ROLE">
                <div class="form-group">
                  <label for="sel1">Source:</label>
                  <!-- <select class="form-control" (change)="onchangeSource($event.target.value)"> -->
                  <select class="form-control" id="source">
                    <option value="">Select Source</option>
                    <option
                      *ngFor="let string of sourceString"
                      value="{{ string }}"
                    >
                      {{ string }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-lg-3">
                <div class="form-group">
                  <label for="sel1">Call Status</label>
                  <!-- <select class="form-control" (change)=onchangeCallStatus($event.target.value)> -->
                  <select class="form-control" id="callStatus">
                    <option value="">Select Status</option>
                    <option value="FOLLOWUP">Followup</option>
                    <option value="INTERESTED">Interested</option>
                    <option value="CALLBACK">Callback</option>
                    <option value="NOT PICKED">Not Picked</option>
                    <option value="VISIT PLANNED">Visit Planned</option>
                    <option value="MEETING PLANNED">Meeting Planned</option>
                  </select>
                </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-lg-3">
                <div class="form-group">
                  <label for="usr">Project Name:</label>
                  <select class="form-control" id="projectName">
                    <option value="">Select Project Name</option>
                    <option
                      *ngFor="let string of projectNameString"
                      value="{{ string }}"
                    >
                      {{ string }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-lg-2">
                <div class="form-group">
                  <label for="sel1">Visited leads</label>
                  <!-- <select class="form-control" (change)=onchangeStatus($event.target.value)> -->
                  <select class="form-control" id="visitStatus">
                    <option value="">All leads</option>
                    <option value="YES">Yes</option>
                    <option value="NO">No</option>
                  </select>
                </div>
              </div>

              <div class="col-xs-12 col-sm-12 col-lg-3">
                <div class="button_ss">
                  <button type="submit" class="btn btn-primary btn-block">
                    Filter
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="col-xs-12">
          <div class="table-responsive table-fixed table-bg">
            <table
              summary=""
              class="table gridtable table-bordered table-hover table-striped text-center"
            >
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Lead Id</th>
                  <th>Client Name</th>
                  <th *ngIf="isValid">Clien Mobile</th>
                  <th style="background-color: #ffd75f21">Email</th>
                  <th *ngIf="ROLE">Source</th>
                  <th>Project Name</th>
                  <th>Query Info</th>
                  <th style="background-color: #b17fad26">Status</th>
                  <th>Call Status</th>
                  <th>Recieved On</th>
                  <th *ngIf="ROLE">Assigned Date</th>
                  <th>Last Update</th>
                  <th>Callback Date</th>
                  <th>Followup Date</th>
                  <th>Visit Status</th>
                  <th>Virtual Meeting Status</th>
                  <th>Visit Date</th>
                  <th>Meeting Date</th>
                  <th>Virtual Meeting Date</th>
                  <th>Remark</th>
                  <th *ngIf="!ROLE">Update Feedback</th>
                  <th>Assigned To</th>
                </tr>
              </thead>
              <tbody class="serialNumber">
                <tr
                  class="content"
                  *ngFor="let lead of openLeads | paginate: config"
                >
                  <td></td>
                  <td
                    class="pointer"
                    data-toggle="modal"
                    data-target="#leadsfeedback"
                    (click)="leadFeedback(lead.leads.id, lead.user.id)"
                  >
                    {{ lead.leads.id }}
                  </td>
                  <td>{{ lead.leads.name }}</td>
                  <td *ngIf="isValid">{{ lead.leads.phone }}</td>
                  <td style="background-color: #ffd75f21">
                    {{ lead.leads.email }}
                  </td>
                  <td *ngIf="ROLE">{{ lead.leads.source }}</td>
                  <td>{{ lead.leads.cmpctLabl }}</td>
                  <td>{{ lead.leads.queryInfo }}</td>
                  <td style="background-color: #b17fad26">{{ lead.status }}</td>
                  <td>{{ lead.callStatus }}</td>
                  <td *ngIf="ROLE">{{ lead.leads.receivedOn }}</td>
                  <td>{{ lead.assignLeadsDate }}</td>
                  <td>{{ lead.updateDate }}</td>
                  <td>{{ lead.callbackDate }}</td>
                  <td>{{ lead.followupDate }}</td>
                  <td *ngIf="lead.visitStatus" style="color: green">Done</td>
                  <td *ngIf="!lead.visitStatus" style="color: red">Not Done</td>
                  <td *ngIf="lead.virtualMeetingStatus" style="color: green">
                    Done
                  </td>
                  <td *ngIf="!lead.virtualMeetingStatus" style="color: red">
                    Not Done
                  </td>
                  <td>{{ lead.visitDate }}</td>
                  <td>{{ lead.meetingDate }}</td>
                  <td>{{ lead.virtualMeetingDate }}</td>
                  <td>{{ lead.message }}</td>
                  <td *ngIf="!ROLE">
                    <button
                      class="btn btn-primary"
                      value="Feedback"
                      title="Feedback"
                      data-toggle="modal"
                      (click)="leadStatusUpdate(lead.leads, lead.leads.user)"
                      data-target="#feedbackModal"
                    >
                      Feedback
                    </button>
                  </td>
                  <td id="tt">
                    <select
                      (change)="
                        assignLeadsToOtherUser(
                          $event.target.value,
                          lead.leads.id
                        )
                      "
                    >
                      <option *ngIf="lead.leads.user != null">
                        {{
                          lead.leads.user.firstName +
                            " " +
                            lead.leads.user.lastName
                        }}
                      </option>
                      >
                      <option
                        *ngFor="let user of activeUsers"
                        value="{{ user.id }}"
                      >
                        {{ user.firstName + " " + user.lastName }}
                      </option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--end of .table-responsive-->
        </div>
      </div>
      <pagination-controls
        (pageChange)="pageChanged($event)"
      ></pagination-controls>
    </div>
  </section>
  <!-- partial -->
</div>
<div class="leadfeedback">
  <!-- Modal -->
  <div
    class="modal fade"
    id="leadsfeedback"
    role="dialog"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-lg">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">×</button>
          <h4 class="modal-title">Leads Feedback</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xs-12 table_view">
              <div class="table-responsive table-fixed table-bg">
                <table
                  summary=""
                  class="table table-bordered table-hover table-striped text-center"
                >
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Lead Id</th>
                      <th style="background-color: #b17fad26">Status</th>
                      <th>Call Status</th>
                      <th>Time of Feedback</th>
                      <th>Visit Date</th>
                      <th>Message</th>
                      <th>Assigned To</th>
                    </tr>
                  </thead>
                  <tbody class="serialNumber">
                    <tr
                      class="content"
                      *ngFor="let feedback of feedbackList"
                      data-toggle="modal"
                      data-target="#leadsReportmodal"
                    >
                      <td></td>
                      <td>{{ feedback.leads.id }}</td>
                      <td style="background-color: #b17fad26">
                        {{ feedback.status }}
                      </td>
                      <td>{{ feedback.callStatus }}</td>
                      <td>{{ feedback.timeOfFeedback }}</td>
                      <td>{{ feedback.visitDate }}</td>
                      <td>{{ feedback.message }}</td>
                      <td id="tt">
                        {{
                          feedback.leads.user.firstName +
                            " " +
                            feedback.leads.user.lastName
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--end of .table-responsive-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="feedbackModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal_heading text-center">
        <h3 class="text-primary">Update Feedback</h3>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-12">
            <div class="form-group">
              <label for="usr">Status</label>
              <select
                name="users"
                class="form-control"
                (change)="feedbackStatus($event.target.value)"
                style="width: 100%"
                id="my_select"
              >
                <option value="" selected="selected">Select Satus</option>
                <option value="open">Accept</option>
                <option value="decline">Decline</option>
                <option value="close">Close</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-xs-12 col-sm-12 col-lg-12"
            *ngIf="callStatusFieldCheck"
          >
            <div class="form-group">
              <label for="usr">Call Status</label>
              <select
                id="mySelect"
                name="users"
                class="form-control"
                (change)="feedbackCallStatus($event.target.value)"
                style="width: 100%"
              >
                <option value="">Select Call Satus</option>
                <option *ngFor="let str of callStatusArray" value="{{ str }}">
                  {{ str }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="callStatusBoolean">
          <div class="col-xs-12 col-sm-12 col-lg-12">
            <form name="feedbackForm" (submit)="saveRemark($event)">
              <div class="form-group" style="width: 100%">
                <label for="">Remark</label>
                <textarea
                  placeholder="Remark please"
                  class="form-control"
                  id="remarkArea"
                  name="remark"
                ></textarea>
              </div>
              <div class="form-group" style="width: 100%" *ngIf="statusCheck">
                <label for="">Choose Date</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  id="chooseDate"
                  placeholder="choose date"
                  required
                />
              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-primary pull-right">
                  Save Feedback
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
