import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RouteGuardService } from '../auth/route-guard.service';
import { FibSystemComponent } from '../fib-system/fib-system.component';
import { AdminComponent } from './admin/admin.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { CallingDataComponent } from './calling-data/calling-data.component';
import { CloseLeadComponent } from './close-lead/close-lead.component';
import { CommonComponent } from './common/common.component';
import { DeclineLeadComponent } from './decline-lead/decline-lead.component';
import { EmployeeAttendanceHistoryComponent } from './employee-attendance-history/employee-attendance-history.component';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';
import { JunkLeadsComponent } from './junk-leads/junk-leads.component';
import { LeadReportComponent } from './lead-report/lead-report.component';
import { LeadTransferComponent } from './lead-transfer/lead-transfer.component';
import { LeadsComponent } from './leads/leads.component';
import { MeetingDetailsComponent } from './meeting-details/meeting-details.component';
import { OpenleadComponent } from './openlead/openlead.component';
import { SheetComponent } from './sheet/sheet.component';
import { TotalLeadsComponent } from './total-leads/total-leads.component';
import { UserGoogleMapComponent } from './user-google-map/user-google-map.component';
import { CallLeadsComponent } from './call-leads/call-leads.component';
import { ShowReviewComponent } from './show-review/show-review.component';
import { ReviewPendingComponent } from './review-pending/review-pending.component';
import { PerformanceComponent } from './performance/performance.component';
import { ShowAllLeadsResponceComponent } from './show-all-leads-responce/show-all-leads-responce.component';

const routes: Routes = [
  {
    path: 'admin', component: CommonComponent,
    children: [
      { path: '', component: AdminComponent },
      { path: 'leadReport', component: LeadReportComponent, canActivate : [RouteGuardService] },
      { path: 'leads', component: LeadsComponent, canActivate: [RouteGuardService] },
      { path: 'openLead', component: OpenleadComponent, canActivate: [RouteGuardService] },
      { path: 'declineLead', component: DeclineLeadComponent, canActivate: [RouteGuardService] },
      { path: 'closeLead', component: CloseLeadComponent, canActivate: [RouteGuardService] },
      { path: 'call-Lead', component: CallLeadsComponent, canActivate: [RouteGuardService] },
      { path: 'attendance', component: AttendanceComponent, canActivate: [RouteGuardService] },
      { path: 'userGoogleMap/:id', component: UserGoogleMapComponent, canActivate: [RouteGuardService] },
      { path: 'userGoogleMap/:id/:str', component: UserGoogleMapComponent, canActivate: [RouteGuardService] },
      { path: 'employeeAttendanceHistory', component: EmployeeAttendanceHistoryComponent, canActivate: [RouteGuardService] },
      { path: 'employeeDetail', component: EmployeeDetailsComponent, canActivate: [RouteGuardService] },
      { path: 'callingData', component: CallingDataComponent, canActivate: [RouteGuardService] },
      { path: 'junkLead', component: JunkLeadsComponent, canActivate: [RouteGuardService] },
      { path: 'meetingDetails', component: MeetingDetailsComponent, canActivate: [RouteGuardService] },
      { path: 'leadTransfer', component: LeadTransferComponent, canActivate: [RouteGuardService] },
      { path: 'totalLeads', component: TotalLeadsComponent, canActivate: [RouteGuardService] },
      { path: 'importLeads/:str', component: SheetComponent, canActivate: [RouteGuardService] },
      { path: 'importLeads', component: SheetComponent, canActivate: [RouteGuardService] },
      { path: 'fibSystem', component: FibSystemComponent, canActivate: [RouteGuardService] },
      { path: 'show-review', component: ShowReviewComponent, canActivate: [RouteGuardService] },
      { path: 'review-pending', component: ReviewPendingComponent, canActivate: [RouteGuardService] },
      { path: 'performance', component: PerformanceComponent, canActivate: [RouteGuardService] },
      { path: 'show-all-leads-responce', component:ShowAllLeadsResponceComponent, canActivate: [RouteGuardService] },
      { path: 'admin', redirectTo: 'admin', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AdministrationRoutingModule { }
