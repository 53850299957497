<!-- Left side column. contains the logo and sidebar -->
<aside class="main-sidebar">
    <!-- sidebar: style can be found in sidebar.less -->
    <section class="sidebar">
        <!-- Sidebar user panel -->
        <div class="user-panel">
            <div class="pull-left image">
                <img src="./../../assets/dist/img/user.png" class="img-circle" alt="User Image">
            </div>
            <div class="pull-left info">
                <p>{{name}}</p>
                <p>{{mobile}}</p>
            </div>
        </div>
        <!-- /.search form -->
        <!-- sidebar menu: : style can be found in sidebar.less -->
        <ul class="sidebar-menu" data-widget="tree">
            <!-- <li class="header">MAIN NAVIGATION</li> -->
            <li>
                <a href="admin">
                    <i class="fa fa-dashboard"></i> <span>DASHBOARD</span>
                    <span class="pull-right-container">
                        <!-- <small class="label pull-right bg-green">new</small> -->
                    </span>
                </a>
            </li>
            <li>
                <a href="/admin/openLead">
                    <i class="fa fa-folder-open-o" aria-hidden="true"></i> <span>OPEN LEAD</span>
                    <span class="pull-right-container">
                        <!-- <small class="label pull-right bg-green">New</small> -->
                    </span>
                </a>
            </li>
            <li>
                <a href="/admin/lead">
                    <i class="fa fa-calculator" aria-hidden="true"></i> <span>NEW LEADS</span>
                    <span class="pull-right-container">
                        <!-- <small class="label pull-right bg-aqua">new</small> -->
                    </span>
                </a>
            </li>
            <li *ngIf="ROLE">
                <a href="/admin/employeeDetails">
                    <i class="fa fa-users" aria-hidden="true"></i> <span>EMPLOYEE DETAILS</span>
                    <span class="pull-right-container">
                        <!-- <small class="label pull-right bg-green">New</small> -->
                    </span>
                </a>
            </li>
            <li>
                <a href="/admin/leadReport">
                    <i class="fa fa-bar-chart" aria-hidden="true"></i> <span>LEAD REPORT</span>
                    <span class="pull-right-container">
                        <!-- <small class="label pull-right bg-yellow">New</small> -->
                    </span>
                </a>
            </li>
            <li *ngIf="ROLE">
                <a href="admin/declineLead">
                    <i class="fa fa-ban" aria-hidden="true"></i> <span>DECLINE LEAD</span>
                    <span class="pull-right-container">
                        <!-- <small class="label pull-right bg-red">New</small> -->
                    </span>
                </a>
            </li>
            <li *ngIf="ROLE">
                <a href="admin/closeLead">
                    <i class="fa fa-power-off" aria-hidden="true"></i> <span>CLOSE LEAD</span>
                    <span class="pull-right-container">
                        <!-- <small class="label pull-right bg-black">New</small> -->
                    </span>
                </a>
            </li>
            <li *ngIf="ROLE">
                <a href="admin/totalLead">
                    <i class="fa fa-calculator" aria-hidden="true"></i> <span>TOTAL LEAD</span>
                    <span class="pull-right-container">
                        <!-- <small class="label pull-right bg-black">New</small> -->
                    </span>
                </a>
            </li>

            <li>
                <a href="admin/attendance">
                    <i class="fa fa-bell" aria-hidden="true"></i> <span>ATTENDANCE</span>
                    <span class="pull-right-container">
                        <!-- <small class="label pull-right bg-black">New</small> -->
                    </span>
                </a>
            </li>

            <li>
                <a href="admin/meetingDetails">
                    <i class="fa fa-folder-open-o" aria-hidden="true"></i> <span>MEETING</span>
                    <span class="pull-right-container">
                        <!-- <small class="label pull-right bg-green">New</small> -->
                    </span>
                </a>
            </li>

            <li *ngIf="ROLE">
                <a href="admin/junkLead">
                    <i class="fa fa-trash" aria-hidden="true"></i> <span>TRASH</span>
                    <span class="pull-right-container">
                        <!-- <small class="label pull-right bg-green">New</small> -->
                    </span>
                </a>
            </li>
            <li>
                <a href="admin/callingData">
                    <i class="fa fa-phone" aria-hidden="true"></i> <span>CALLING DATA</span>
                    <span class="pull-right-container">
                    </span>
                </a>
            </li>
        </ul>
    </section>
    <!-- /.sidebar -->
</aside>