import { Component, OnInit, NgModule } from '@angular/core';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterTestingModule } from '@angular/router/testing';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Users } from 'src/app/model/users.model';
import { AuthService } from 'src/app/services/auth.service';

declare function resetDropdown(): any;
declare function closeLeadFeedbackModel(): any;
declare function enableStatus(): any;
declare function disableStatus(): any;

@Component({
  selector: 'app-openlead',
  templateUrl: './openlead.component.html',
  styleUrls: ['./openlead.component.css']
})

@NgModule({

  imports: [
    NgxUiLoaderModule,
    HttpClientModule,
    RouterTestingModule,
    HttpClient
  ],
  providers: [
    DatePipe
  ]
})
export class OpenleadComponent implements OnInit {

  userList: Users[];
  openLeads: any[];
  feedbackList: any[];

  onchangeCallStatusString: any;
  onchangeAgentString: any;
  onchangeSourceString: any;
  onchangeVisitStatus: any;

  inputDateFromString: any;
  inputDateToString: any;
  inputProjectNameString: any;
  sourceString: any[];
  projectNameString: any[];

  public config = {
    itemsPerPage: 20,
    currentPage: 0,
    totalItems: 0
  };
  private page: number = 1;
  private pages: Array<number>;
  private leadsReports: Array<any>;
  private filterStr: any;
  private exportStr: any;

  ROLE: boolean;
  userRole: string;

  lead: any = {};

  acceptCallStatus: string[] = new Array("Followup", "Meeting Planned", "Visit Planned", "Virtual Meeting", "Not Picked", "Callback", "Visited", "Virtual Meeting Done");
  // rejectCallStatus: string[] = new Array("Not Interested", "Invalid Number", "Already Purchased");
  leadsStatus: string = "";
  callStatus: string = "";
  statusCheck: boolean = false;
  callStatusArray: string[];
  callStatusBoolean: boolean = false;
  feedbackUser: any = {};

  MOBILE: boolean;
  mobile: string = localStorage.getItem('mobile');

  private json_arr = {
    'startDate': null,
    'endDate': null,
    'status': "OPEN",
    'transferred': 'No',
    'callStatus': null,
    'userId': null,
    'projectName': null,
    'source': null,
    'visitStatus': null
  };
  activeUsers: any[];
  callStatusFieldCheck: boolean = false;
  isValid: boolean = false;

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {
    this.userRole = localStorage.getItem("role");
    if (this.userRole === 'ADMIN') {
      this.ROLE = true;
    } else {
      this.ROLE = false;
    }

    this.isValid = localStorage.getItem('isValid') == 'YES';

    if (this.mobile === "8076042671") {
      this.MOBILE = true;
    } else {
      this.MOBILE = false;
    }

    this.auth.startLoader();
    this.getFilteredLeadsReportByPage(this.json_arr);

    this.auth.getUsers().subscribe(
      data => {
        this.userList = data;
      }
    )

    this.auth.getSalesAndActiveUsers().subscribe(
      data => {
        this.activeUsers = data;
      }
    )

    if (localStorage.getItem('role') === 'ADMIN') {
      this.auth.getSourceString().subscribe(
        data => {
          this.sourceString = data;
        }
      )
    }

    this.auth.getProjectNameString().subscribe(
      data => {
        this.projectNameString = data;
      }
    )
  }

  getLeadsReportByPage() {
    this.filterStr = "normal";
    this.auth.getOpenLeadsByPage(this.page).subscribe(
      data => {
        this.openLeads = data['content'];
        this.config.totalItems = data['totalElements'];
        // this.pages = new Array(data['totalPages']);
        this.auth.stopLoader();
      },
      (error) => {
        this.auth.stopLoader();
      }
    );
  }

  pageChanged(event) {
    this.auth.startLoader();
    this.page = event;
    this.config.currentPage = event;
    if (this.filterStr === "normal") {
      this.getLeadsReportByPage();
    } else if (this.filterStr === "filter") {
      this.getFilteredLeadsReportByPage(this.json_arr)
    }
  }

  setPage(i, $event: any) {

    this.auth.startLoader();
    event.preventDefault();
    this.page = i;
    this.getLeadsReportByPage();
  }

  assignLeadsToOtherUser(userId, leadId) {
    this.auth.startLoader();
    let leadsArray = [
      { "leadsId": leadId },
    ];
    this.auth.startLoader();
    this.auth.assignLeads(userId, leadsArray).subscribe(
      data => {
        this.auth.stopLoader();
        alert(data['message'])
      },
      error => {
        this.auth.stopLoader();
        alert(error['message']);
      }
    );
  }

  leadFeedback(leadsId, userId) {
    this.auth.startLoader();
    this.auth.getFeedbackOfLeads1(leadsId).subscribe(
      data => {
        this.feedbackList = data;
        this.auth.stopLoader();
      }
    )
  }

  onsubmitFilter(event) {
    this.page = 1;
    this.filterFieldsData(event)
    this.getFilteredLeadsReportByPage(this.json_arr)
    // this.auth.filterLeadsReport(this.json_arr).subscribe(
    //   data => {
    //     this.openLeads = data;
    //     this.auth.stopLoader();
    //     this.router.navigate['open/leads'];
    //   }
    // )
  }

  filterFieldsData(event: any) {
    this.auth.startLoader();
    let target = event.target;

    if (target.querySelector('#agent').value != null) {
      this.onchangeAgentString = target.querySelector('#agent').value;
    } else {
      this.onchangeAgentString = null;
    }

    if (this.ROLE) {
      if (target.querySelector('#source').value != null && this.ROLE) {
        this.onchangeSourceString = target.querySelector('#source').value;
      } else {
        this.onchangeSourceString = null;
      }
    }
    if (target.querySelector('#visitStatus').value != null) {
      this.onchangeVisitStatus = target.querySelector('#visitStatus').value;
    }
    else {
      this.onchangeVisitStatus = null;
    }
    if (target.querySelector('#callStatus').value != null) {
      this.onchangeCallStatusString = target.querySelector('#callStatus').value;
    } else {
      this.onchangeCallStatusString = null;
    }

    if (target.querySelector('#datefrom').value != null) {
      this.inputDateFromString = target.querySelector('#datefrom').value;
      var datePipe = new DatePipe("en-US");
      this.inputDateFromString = datePipe.transform(this.inputDateFromString, 'dd-MM-yyyy');
    } else {
      this.inputDateFromString = null;
    }
    if (target.querySelector('#dateto').value != null) {
      this.inputDateToString = target.querySelector('#dateto').value;
      var datePipe = new DatePipe("en-US");
      this.inputDateToString = datePipe.transform(this.inputDateToString, 'dd-MM-yyyy');
    } else {
      this.inputDateToString = null;
    }
    if (target.querySelector('#projectName').value != null) {
      this.inputProjectNameString = target.querySelector('#projectName').value;
    } else {
      this.inputProjectNameString = null;
    }

    this.json_arr = {
      'startDate': this.inputDateFromString,
      'endDate': this.inputDateToString,
      'status': "OPEN",
      'transferred': 'No',
      'callStatus': this.onchangeCallStatusString,
      'userId': this.onchangeAgentString,
      'projectName': this.inputProjectNameString,
      'source': this.onchangeSourceString,
      'visitStatus': this.onchangeVisitStatus
    };
  }

  getFilteredLeadsReportByPage(jsonObj) {
    this.filterStr = "filter";
    this.auth.filterLeadsReportByPage(jsonObj, this.page, 20).subscribe(
      data => {
        this.openLeads = data['content'];
        this.config.totalItems = data['totalElements'];
        this.auth.stopLoader();
      },
      (error) => {
        this.auth.stopLoader();
      }
    );
  }

  feedbackStatus(str) {
    this.leadsStatus = str;
    if (str === 'open') {
      this.callStatusFieldCheck = true;
      this.callStatusBoolean = false;
      this.statusCheck = true;
      this.callStatusArray = this.acceptCallStatus;
      // enableStatus();
    } else if (str === 'decline' || str === 'close') {
      this.callStatusFieldCheck = false;
      this.callStatusBoolean = true;
      this.statusCheck = false;
      this.callStatusArray = [];
      // this.callStatusArray = this.rejectCallStatus;
      // disableStatus();
    } else {
      this.callStatusArray = [];
      this.leadsStatus = null;
      this.callStatusBoolean = false;
      this.callStatusFieldCheck = true;
      // disableStatus();
      alert('Please select status');
    }
  }

  leadStatusUpdate(obj, userObj) {
    this.lead = obj;
    this.feedbackUser = userObj;
    this.leadsStatus = "";
    this.callStatus = "";
    this.statusCheck = false;
    this.callStatusBoolean = false;
    this.callStatusFieldCheck = false;
    // disableStatus();
    resetDropdown();
  }

  feedbackCallStatus(str) {
    this.callStatus = str;
    if (this.leadsStatus === "open") {
      if (this.callStatus === "Visited" || this.callStatus === "Virtual Meeting Done") {

        this.callStatusBoolean = true;
        this.statusCheck = false;

      } else {
        this.statusCheck = true;
        if (this.callStatus != "") {
          this.callStatusBoolean = true;
        } else {
          this.callStatusBoolean = false;
          this.callStatus = null;
          alert('Please select call status');
        }
      }
    } else {
      if (this.callStatus != "") {
        this.callStatusBoolean = true;
      } else {
        this.callStatusBoolean = false;
        this.callStatus = null;
        alert('Please select call status');
      }
    }
  }


  saveRemark(event) {
    var date: string = "";
    var remark: string = "";
    let target = event.target;

    var feedbackObj: any = {}

    if (target.querySelector('#remarkArea').value != null) {
      remark = target.querySelector('#remarkArea').value;
    }
    else {
      remark = "";
    }

    feedbackObj['message'] = remark;
    feedbackObj['status'] = this.leadsStatus;
    feedbackObj['callStatus'] = this.callStatus;
    feedbackObj['user'] = this.feedbackUser;

    if (this.leadsStatus == 'open') {

      if (this.callStatus === "Visited" || this.callStatus === "Virtual Meeting Done") {
        if (this.callStatus === "Visited") {
          feedbackObj['visitStatus'] = true;
        } else if (this.callStatus === "Virtual Meeting Done") {
          feedbackObj['virtualMeetingStatus'] = true;
        }
      } else {
        if (target.querySelector('#chooseDate').value != null) {
          date = target.querySelector('#chooseDate').value;
          var datePipe = new DatePipe("en-US");
          date = datePipe.transform(date, 'dd-MM-yyyy hh:mm a');
        }
        else {
          date = "";
        }

        if (this.callStatus == "Visit Planned") {
          feedbackObj['visitDate'] = date;
        } else if (this.callStatus == "Callback") {
          feedbackObj['callbackDate'] = date;
        } else if (this.callStatus == "Meeting Planned") {
          feedbackObj['meetingDate'] = date;
        } else if (this.callStatus == "Not Picked") {
          feedbackObj['callbackDate'] = date;
        } else if (this.callStatus == "Followup") {
          feedbackObj['followupDate'] = date;
        } else if (this.callStatus == "Virtual Meeting") {
          feedbackObj['virtualMeetingDate'] = date;
        }
      }
    }

    if (remark.trim().length > 0) {
      this.auth.startLoader();
      this.auth.saveLeadsFeedback(this.lead['id'], feedbackObj).subscribe(
        data => {
          closeLeadFeedbackModel();
          this.auth.stopLoader();
          alert(data['output']);
        },
        err => {
          this.auth.stopLoader();
          console.log(err);
          alert("Something wrong try again.");
        }
      )
    } else {
      alert("Remark should not be empty.");
    }
  }


  // saveRemark(event) {
  //   this.auth.startLoader();
  //   var date: string = "";
  //   var remark: string = "";
  //   let target = event.target;

  //   var feedbackObj: any = {}

  //   if (target.querySelector('#remarkArea').value != null) {
  //     remark = target.querySelector('#remarkArea').value;
  //   }
  //   else {
  //     remark = "";
  //   }

  //   feedbackObj['message'] = remark;
  //   feedbackObj['status'] = this.leadsStatus;
  //   feedbackObj['callStatus'] = this.callStatus
  //   feedbackObj['user'] = this.feedbackUser;

  //   if (this.leadsStatus == 'open') {
  //     if (this.callStatus != "Visited") {
  //       if (target.querySelector('#chooseDate').value != null) {
  //         date = target.querySelector('#chooseDate').value;
  //         var datePipe = new DatePipe("en-US");
  //         date = datePipe.transform(date, 'dd-MM-yyyy hh:mm a');
  //       }
  //       else {
  //         date = "";
  //       }

  //       if (this.callStatus == "Visit Planned") {
  //         feedbackObj['visitDate'] = date;
  //       } else if (this.callStatus == "Callback") {
  //         feedbackObj['callbackDate'] = date;
  //       } else if (this.callStatus == "Meeting Planned") {
  //         feedbackObj['meetingDate'] = date;
  //       } else if (this.callStatus == "Not Picked") {
  //         feedbackObj['callbackDate'] = date;
  //       } else if (this.callStatus == "Followup") {
  //         feedbackObj['followupDate'] = date;
  //       }
  //     } else {
  //       feedbackObj['visitStatus'] = true;
  //     }
  //   }

  //   if (remark.trim().length > 0) {
  //     this.auth.saveLeadsFeedback(this.lead['id'], feedbackObj).subscribe(
  //       data => {
  //         closeLeadFeedbackModel();
  //         this.auth.stopLoader();
  //         alert(data['output']);
  //       },
  //       err => {
  //         this.auth.stopLoader();
  //         alert("Something wrong try again.");
  //       }
  //     )
  //   } else {
  //     alert("Remark should not be empty.");
  //   }
  // }

}