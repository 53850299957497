import { Component, OnInit, NgModule } from "@angular/core";
import {
  HttpResponse,
  HttpClientModule,
  HttpClient,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { RouterTestingModule } from "@angular/router/testing";
import { AuthService } from "../services/auth.service";

declare function closeModal(modalId: string);
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
@NgModule({
  imports: [HttpClientModule, RouterTestingModule],
})
export class LoginComponent implements OnInit {
  isLoginError: boolean = false;

  userRolesArray: Array<any[]>;
  ipAddress: any;

  constructor(
    private auth: AuthService,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit() {
    if (localStorage.getItem("token") != null) {
      this.authenticateUser(localStorage.getItem("role"));
    } else {
      localStorage.clear();
      this.router.navigate(["/"]);
    }
  }

  loginForm(event) {
    const target = event.target;
    const mobile = target.querySelector("#username").value;
    const password = target.querySelector("#password").value;
    console.log(mobile, password);
  }

  submitForm(event) {
    const target = event.target;
    const mobile = target.querySelector("#username").value;
    const password = target.querySelector("#password").value;
    if (!mobile && !password) {
      alert("Mobile and password can not be null");
      return;
    }
    this.auth.userLogin(mobile, password).subscribe(
      (data: HttpResponse<any>) => {
        console.log(data);
        this.saveIPAddress(data["id"]);
        this.userRolesArray = data["roles"];
        for (let role of this.userRolesArray) {
          localStorage.setItem("role", role["role"]);
        }
        localStorage.setItem("id", data["id"]);
        localStorage.setItem("token", data["x-auth-token"]);
        localStorage.setItem(
          "firstName",
          data["firstName"] ? data["firstName"] : ""
        );
        localStorage.setItem(
          "lastName",
          data["lastName"] ? data["lastName"] : ""
        );
        localStorage.setItem("address", data["address"]);
        localStorage.setItem("mobile", data["mobile"]);
        localStorage.setItem("isValid", data["isValid"]);
        this.auth.setLoggedIn(true);
        this.authenticateUser(localStorage.getItem("role"));
      },
      (err: HttpResponse<any>) => {
        const msg = err["message"];
        this.isLoginError = true;
        alert("Mobile or Password is invalid, please check your credential.");
      }
    );
  }

  authenticateUser(role) {
    if (role == "ADMIN") {
      this.router.navigate(["admin"]);
      return true;
    } else if (role == "HR") {
      this.router.navigate(["hr"]);
      return true;
    } else if (role == "CRM" || role == "SALES") {
      this.router.navigate(["crm"]);
      return true;
    }
    // else if (role == "LEAD_HEAD") {
    //   this.router.navigate(["leadHead"]);
    //   return true;
    // }
    // else if (role == "EMPLOYEE") {
    //   alert("Login not allowed for this role");
    //   return false;
    // }
    else {
      alert(
        "Login not allowed for this role \n Login with application, download app from play store"
      );
      localStorage.clear();
      this.router.navigate([""]);
    }
    return false;
  }

  saveIPAddress(userId) {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.ipAddress = res.ip;
      var loginData: any = {};
      loginData["userId"] = userId;
      loginData["ipAddress"] = this.ipAddress;
      this.auth.saveIPAddress(loginData).subscribe(
        (data) => {
          console.log(data);
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }

  sendPasswordResetLinkOnEmail(event) {
    var phone: string = event.target.querySelector("#phone").value;
    var email: string = event.target.querySelector("#email").value;

    if (phone.trim() == "" || email.trim() == "") {
      alert("Please fill the details");
      return;
    } else {
      this.auth.startLoader();
      var obj: any = {};
      obj["phone"] = phone.trim();
      obj["email"] = email.trim();
      this.auth.checkUserPhoneAndEmail(obj).subscribe(
        (data) => {
          this.auth.stopLoader();
          alert(data["message"]);
          var str: string = data["message"];
          if (str.match("Success")) {
            console.log(true);
            closeModal("#forgotPassword");
          }
        },
        (err) => {
          this.auth.stopLoader();
        }
      );
    }
  }
}
