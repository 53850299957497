<div class="content-wrapper" style="background-color: #fff !important;">
    <section class="Leads_manage leads_redesign">
        <a class="backbtn" href="crm/profile">
            <button class="btn btn-warning"style="    margin-top: 22px;"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
            </a>
            <div class="homebtn">
            <a href="/crm">
                <button class="btn btn-success" style="margin-top: -8px;">Home <i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                </a>
            </div>
    <div class="update">
    <div class="modal-body ">
            <form name="addLeadForm" (submit)="updateUser($event)">
            <div class="box-body">
            <div class="row">
                <div class="col-xs-12 col-lg-6">
                <div class="form-group">
                    <label for="">First Name</label>
                    <input type="text" class="form-control" value="{{user.firstName}}" id="fName"
                    placeholder="Enter First Name">
                </div>
                </div>
                <div class="col-xs-12 col-lg-6">
                <div class="form-group">
                    <label for="">Last Name</label>
                    <input type="text" class="form-control" value="{{user.lastName}}" id="lName"
                    placeholder="Enter Last Name">
                </div>
                </div>
                <div class="col-xs-12 col-lg-6">
                <div class="form-group">
                    <label for="">Email</label>
                    <input type="email" class="form-control" value="{{user.email}}" id="email"
                    placeholder="Enter Email">
                </div>
                </div>
                <div class="col-xs-12 col-lg-6">
                <div class="form-group">
                    <label for="">Mobile Number</label>
                    <input type="text" class="form-control" value="{{user.mobile}}" id="mobile"
                    placeholder="Enter Number">
                </div>
                </div>
                <div class="col-xs-12 col-lg-6">
                    <div class="form-group">
                        <label for="">DOB</label>
                        <input type="text" class="form-control" value="{{user.dob}}" id="dob"
                        placeholder="Enter DOB">
                    </div>
                    </div>
            </div>

            <div class="form-group">
                <label for="">Address</label>
                <input type="text" class="form-control" value="{{user.address}}" id="address"
                placeholder="Enter Address" required>
            </div>
            </div>
            <!-- /.box-body -->
            <div class="box-footer">
            <button type="submit" class="btn btn-primary btn-block">Update User</button>
            </div>
        </form>
    </div>
        </div>
    </section>
</div>