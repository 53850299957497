import { Component, OnInit, Input } from "@angular/core";
import { DatePipe } from "@angular/common";
import { Users } from "src/app/model/users.model";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-attendance",
  templateUrl: "./attendance.component.html",
  styleUrls: ["./attendance.component.css"],
})
export class AttendanceComponent implements OnInit {
  users: Users[];
  userAttendanceList: any[];

  lat: any;
  long: any;

  inputDateFromString: number = 0;
  inputDateToString: number = 0;
  currDate: string;
  constructor(private datePipe: DatePipe, private auth: AuthService) {}

  ngOnInit() {
    this.userAttendance();
  }

  userAttendance() {
    var myDate = new Date().getTime();
    this.auth.startLoader();
    this.auth
      .getUserAttendanceList(localStorage.getItem("id"), 0, myDate)
      .subscribe(
        (data) => {
          this.auth.stopLoader();
          this.userAttendanceList = data;
        },
        (error) => {
          this.auth.stopLoader();
        }
      );
  }

  userAttendanceFilter(event) {
    this.auth.startLoader();
    let form = event.value;

    this.inputDateFromString = this.auth.toTimestamp(form.dFrom);
    this.inputDateToString = this.auth.toTimestamp(form.dTo);

    if (this.inputDateFromString > 0 && this.inputDateToString > 0) {
      this.auth
        .getUserAttendanceList(
          localStorage.getItem("id"),
          this.inputDateFromString,
          this.inputDateToString
        )
        .subscribe(
          (data) => {
            this.userAttendanceList = data;
            this.auth.stopLoader();
          },
          (error) => {
            this.auth.stopLoader();
          }
        );
    } else {
      this.userAttendance();
    }
  }
}
