import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Users } from 'src/app/model/users.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})
export class AttendanceComponent implements OnInit {

  users: Users[];
  userAttendanceList: any[]

  lat: any;
  long: any;

  inputDateFromString: any;
  inputDateToString: any;

  ROLE: boolean;
  ACTIVE: boolean = false;

  userId: any;

  myDate = new Date();
  currDate: string;
  constructor(private datePipe: DatePipe, private auth: AuthService) {
    this.currDate = this.datePipe.transform(this.myDate, 'dd-MM-yyyy');
  }

  ngOnInit() {
    this.ROLE = localStorage.getItem('role') === 'ADMIN';
    this.userId = 0;
    this.auth.startLoader();
    if (this.ROLE) {
      this.userAttendance();
      this.auth.getAllActiveUser().subscribe(
        data => {
          this.users = data;
          this.auth.stopLoader();
        }
      );
    }

    if (!this.ROLE) {
      this.ACTIVE = true;
      this.auth.getUserAttendanceList(localStorage.getItem('id'), null, null).subscribe(
        data => {
          this.auth.stopLoader();
          this.userAttendanceList = data;
        },
        error => {
          this.auth.stopLoader();
        }
      )
    }
  }

  userAttendance() {
    this.auth.getTodayAttendanceList().subscribe(
      data => {
        this.auth.stopLoader();
        this.userAttendanceList = data;
      },
      error => {
        console.error(error);
        this.auth.stopLoader();
      }
    )
  }

  liveLocation(id) {
    window.open('/user/google/map/' + id)
  }

  userLiveLocation() {
    if (this.userId <= 0) {
      alert("Please select agent.")
    }
    else {
      this.liveLocation(this.userId);
    }
  }

  agentIdChange(id) {
    this.userId = id;
  }

  isClickedOnAttendance = false;

  userAttendanceFilter(event) {
    this.auth.startLoader();
    let target = event.target;
    if (localStorage.getItem('role') === 'ADMIN') {
      if (target.querySelector('#agent').value != null) {
        this.userId = target.querySelector('#agent').value;
      } else {
        this.userId = null;
      }
    }

    if (target.querySelector('#datefrom').value != null) {
      this.inputDateFromString = target.querySelector('#datefrom').value;
      var datePipe = new DatePipe("en-US");
      this.inputDateFromString = datePipe.transform(this.inputDateFromString, 'dd-MM-yyyy');
    }
    else {
      this.inputDateFromString = null;
    }
    if (target.querySelector('#dateto').value != null) {
      this.inputDateToString = target.querySelector('#dateto').value;
      var datePipe = new DatePipe("en-US");
      this.inputDateToString = datePipe.transform(this.inputDateToString, 'dd-MM-yyyy');
    }
    else {
      this.inputDateToString = null;
    }

    if (this.ROLE) {
      if (this.userId <= 0) {
        this.ACTIVE = false
        this.userAttendance();
        return;
      }
    }
    if (this.userId != null || this.userId == "") {
      this.isClickedOnAttendance = true;
    } else {
      this.isClickedOnAttendance = false;
    }
    this.auth.getUserAttendanceList(this.userId, this.inputDateFromString, this.inputDateToString).subscribe(
      data => {
        this.auth.stopLoader();
        this.ACTIVE = true;
        this.userAttendanceList = data;
      },
      error => {
        this.auth.stopLoader();
      }
    )
  }

  exportAttendance() {
    if (this.userId <= 0) {
      alert("Please select the user");
      return;
    } else {
      this.auth.getUserAttendanceList(this.userId, this.inputDateFromString, this.inputDateToString).subscribe(
        data => {
          this.auth.stopLoader();
          this.exportAttendanceInExcel(data)
        },
        error => {
          this.auth.stopLoader();
        }
      )
    }
  }

  private exportAttendanceInExcel(list) {
    var exportList = [];
    var userName;
    for (let obj of list) {
      userName = obj.user.firstName + " " + obj.user.lastName;
      var json_obj = {
        "Date": obj.date,
        "In Time": obj.inTime,
        "Out Time": obj.outTime,
        "Working Hour": obj.workingHour
      };
      exportList.push(json_obj);
    }
    this.auth.exportAsExcelFile(exportList, userName + " Attendance");
  }

}
