<div class="content-wrapper" style="background-color: #fff !important;">
  <section class="Leads_manage leads_redesign">
    
    
    <!--Start filter form section -->

    <div class="bord">
      <div class="col-xs-12 col-sm-3 col-lg-12 ">
        <div class="form-group">
          <div class="date_filter_tab toggle__btn">
            <p class="" for="usr" value="assigned">Filter :</p>
            <button class="fib_system_btn">
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </button>
          </div>
          <div class="collapse" id="collapsefilters">
            <form #filterForm="ngForm" (submit)="onsubmitFilter(filterForm)">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-xs-12 col-sm-3 col-lg-12 ">
                    <div class="form-group">
                      <div class="date_filter_tab date_filter_btn">
                        <p class="" for="usr" value="assigned">Date Filter :</p>
                        <button class="fib_system_btn" type="button" data-toggle="collapse"
                          data-target="#collapseassigned" aria-expanded="false" aria-controls="collapseExample">
                          <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </button>
                      </div>

                      <div class="form-group collapse label-group " id="collapseassigned">
                        <div class="row">
                          <div class="col-xs-12 col-sm-3 col-lg-4 ">
                            <div class="form-group">
                              <label class="label-text" for="usr" value="receivedon">Recieved Date:</label>

                              <div>
                                <label for="usr">From:</label>
                                <input ngModel name="rdFrom" #rdFrom="ngModel" type="date" class="form-control"
                                  id="rFrom" />
                                <label for="usr">To:</label>
                                <input ngModel name="rdTo" #rdTo="ngModel" type="date" class="form-control" id="rTo" />
                              </div>
                            </div>
                          </div>

                          <div class="col-xs-12 col-sm-3 col-lg-4">
                            <div class="form-group">
                              <label class="label-text" for="usr" value="assigned">Assigned Date:</label>

                              <div>
                                <label for="usr">From:</label>
                                <input ngModel name="adFrom" #adFrom="ngModel" type="date" class="form-control"
                                  id="aFrom" />
                                <label for="usr">To:</label>
                                <input ngModel name="adTo" #adTo="ngModel" type="date" class="form-control" id="aTo" />
                              </div>
                            </div>
                          </div>


                          <div class="col-xs-12 col-sm-3 col-lg-4 ">
                            <div class="form-group">
                              <label class="label-text" for="usr" value="callstatus">Call Status Date:</label>

                              <div>
                                <label for="usr">From:</label>
                                <input ngModel name="csdFrom" #csdFrom="ngModel" type="date" class="form-control"
                                  id="csFrom" />
                                <label for="usr">To:</label>
                                <input ngModel name="csdTo" #csdTo="ngModel" type="date" class="form-control"
                                  id="csTo" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-3">
                    <div class="form-group">
                      <label for="usr">Call Status</label>
                      <ng-multiselect-dropdown ngModel name="lcStatus" #lcStatus="ngModel" class="im_multi"
                        [placeholder]="'Call Status'" [data]="callStatusList" [settings]="dropdownSettings">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-3">
                    <div class="form-group">
                      <label for="usr">Leads Assigned To</label>
                      <ng-multiselect-dropdown ngModel name="lAssignedTo" #lAssignedTo="ngModel" class="im_multi"
                        [placeholder]="'Leads Assigned To'" [data]="userList" [settings]="dropdownSettings">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-3">
                    <div class="form-group">
                      <label for="usr">Project Name:</label>
                      <ng-multiselect-dropdown ngModel name="lProject" #lProject="ngModel" class="im_multi"
                        [placeholder]="'Projects Name'" [data]="projectNameList" [settings]="dropdownSettings">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-3">
                    <div class="form-group">
                      <label for="sel1">Order By</label>
                      <select ngModel name="orderBy" #orderBy="ngModel" class="form-control">
                        <option value="" selected>Select Order By</option>
                        <option value="ASC">Ascending Order</option>
                        <option value="DESC">Descending Order</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-2">
                    <div class="form-group">
                      <label for="sel1">Sort By</label>
                      <select ngModel name="sortBy" #sortBy="ngModel" class="form-control">
                        <option value="" selected>Select Sort By</option>
                        <option value="receivedOn">Received Date</option>
                        <option value="leadId">Lead Id</option>
                        <option value="name">Name</option>
                        <option *ngIf="isValid" value="phone">Phone</option>
                        <option value="projectName">Project Name</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-2">
                    <div class="form-group">
                      <label for="sel1">Total Item founds :</label>
                      <input class="form-control" type="text" value=" {{itemCount}}" disabled />
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-lg-2 d-flex ">
                    <div class="form-group ">
                      <label> &nbsp;</label>
                      <button type="submit" class="fib_system_btn  mr-3">
                        <i class="fa fa-filter" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!--End filter form section -->

    <div class="col-xs-12">
      <div class="table-responsive table-fixed leads_table_redesign table-bg">
        <table summary="" class="
                table
                gridtable
                table-bordered table-hover table-striped
                text-center borderStyle
              ">
          <thead>
            <tr>
              <th></th>
              <th>SN</th>
              <th>Lead Id</th>
              <th>Client Name</th>
              <th >Client Mobile</th>
              <th style="background-color: #ffd75f21">Email</th>
              <th *ngIf="ROLE">Source</th>
              <th>Project Name</th>
              <th>Query Info</th>
              <th style="background-color: #b17fad26">Status</th>
              <th>Call Status</th>
              <th>Recieved On</th>
              <th>Last Update</th>
              <th>Call Status Date</th>
              <th>Visit Status</th>
              <th>Virtual Meeting Status</th>
              <th>Remark</th>
              <th *ngIf="!ROLE">Update Feedback</th>
              <th>Assigned To</th>
            </tr>
          </thead>
          <tbody class="serialNumber">
            <tr class="content" *ngFor="let lead of openLeads | paginate: config">
              <th></th>
              <td></td>
              <td class="pointer" data-toggle="modal" data-target="#leadsfeedback"
                (click)="leadFeedback(lead.leads.id)">
                {{ lead.leads.id }}
              </td>
              <td>{{ lead.leads.name }}</td>
              <td>{{ lead.leads.phone }}</td>
              <td style="background-color: #ffd75f21">
                {{ lead.leads.email }}
              </td>
              <td *ngIf="ROLE">{{ lead.leads.source }}</td>
              <td>{{ lead.leads.projectName }}</td>
              <td>{{ lead.leads.queryInfo }}</td>
              <td style="background-color: #b17fad26">{{ lead.status }}</td>
              <td>{{ lead.callStatus }}</td>
              <td *ngIf="lead.assignLeadsDate == 0"></td>
              <td *ngIf="lead.assignLeadsDate != 0">
                {{ lead.assignLeadsDate | date: "dd-MM-yyyy hh:mm a" }}
              </td>
              <td *ngIf="lead.updateDate == 0"></td>
              <td *ngIf="lead.updateDate != 0">
                {{ lead.updateDate | date: "dd-MM-yyyy hh:mm a" }}
              </td>
              <td *ngIf="lead.callStatusDate == 0"></td>
              <td *ngIf="lead.callStatusDate != 0">
                {{ lead.callStatusDate | date: "dd-MM-yyyy hh:mm a" }}
              </td>
              <td *ngIf="lead.visitStatus" style="color: green">Done</td>
              <td *ngIf="!lead.visitStatus" style="color: red">Not Done</td>
              <td *ngIf="lead.virtualMeetingStatus" style="color: green">
                Done
              </td>
              <td *ngIf="!lead.virtualMeetingStatus" style="color: red">
                Not Done
              </td>
              <td>{{ lead.message }}</td>
              <td *ngIf="!ROLE">
                <button class="fib_system_btn_auto_w" value="Feedback" title="Feedback" data-toggle="modal"
                  (click)="leadStatusUpdate(lead.leads, lead.leads.user)" data-target="#feedbackModal">
                  <i class="fa fa-comments-o" aria-hidden="true"></i>
                </button>
              </td>
              <td id="tt">
                <select (change)="
                        assignLeadsToOtherUser(
                          $event.target.value,
                          lead.leads.id
                        )
                      ">
                  <option *ngIf="lead.leads.user != null">
                    {{
                    lead.leads.user.firstName +
                    " " +
                    lead.leads.user.lastName
                    }}
                  </option>
                  >
                  <option *ngFor="let user of activeUsers" value="{{ user.id }}">
                    {{ user.firstName + " " + user.lastName }}
                  </option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--end of .table-responsive-->
    </div>

    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>

  </section>
  <!-- partial -->
</div>
<div class="leadfeedback">
  <!-- Modal -->
  <div class="modal fade" id="leadsfeedback" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-lg">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">×</button>
          <h4 class="modal-title">Leads Feedback</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xs-12 table_view">
              <div class="table-responsive table-fixed table-bg">
                <table summary="" class="
                    table table-bordered table-hover table-striped
                    text-center
                  ">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Lead Id</th>
                      <th style="background-color: #b17fad26">Status</th>
                      <th>Call Status</th>
                      <th>Time of Feedback</th>
                      <th>Visit Date</th>
                      <th>Message</th>
                      <th>Assigned To</th>
                    </tr>
                  </thead>
                  <tbody class="serialNumber">
                    <tr class="content" *ngFor="let feedback of feedbackList" data-toggle="modal"
                      data-target="#leadsReportmodal">
                      <td></td>
                      <td>{{ feedback.leads.id }}</td>
                      <td style="background-color: #b17fad26">
                        {{ feedback.status }}
                      </td>
                      <td>{{ feedback.callStatus }}</td>
                      <td>{{ feedback.timeOfFeedback > 0 ? (feedback.timeOfFeedback | date: "dd-MM-yyyy hh:mm a"):"" }}
                      </td>
                      <td>{{ feedback.visitDate }}</td>
                      <td>{{ feedback.message }}</td>
                      <td id="tt">
                        {{
                        feedback.leads.user.firstName +
                        " " +
                        feedback.leads.user.lastName
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--end of .table-responsive-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="feedbackModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal_heading text-center">
        <h3 class="text-primary">Update Feedback</h3>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-12">
            <div class="form-group">
              <label for="usr">Status</label>
              <select name="users" class="form-control" (change)="feedbackStatus($event.target.value)"
                style="width: 100%" id="my_select">
                <option value="" selected="selected">Select Satus</option>
                <option value="open">Accept</option>
                <option value="decline">Decline</option>
                <option value="close">Close</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-12" *ngIf="callStatusFieldCheck">
            <div class="form-group">
              <label for="usr">Call Status</label>
              <select id="mySelect" name="users" class="form-control" (change)="feedbackCallStatus($event.target.value)"
                style="width: 100%">
                <option value="">Select Call Satus</option>
                <option *ngFor="let str of callStatusArray" value="{{ str }}">
                  {{ str }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="callStatusBoolean">
          <div class="col-xs-12 col-sm-12 col-lg-12">
            <form name="feedbackForm" (submit)="saveRemark($event)">
              <div class="form-group" style="width: 100%">
                <label for="">Remark</label>
                <textarea placeholder="Remark please" class="form-control" id="remarkArea" name="remark"></textarea>
              </div>
              <div class="form-group" style="width: 100%" *ngIf="statusCheck">
                <label for="">Choose Date</label>
                <input type="datetime-local" class="form-control" id="chooseDate" placeholder="choose date" required />
              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-primary pull-right">
                  Save Feedback
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>